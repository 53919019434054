/**
 * View header <3 block without schema.
 * @module components/manage/Blocks/Header3/View
 * copied from omelette/src/components/manage/Blocks/Text/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import './View.css';

/**
 * View header3 block class.
 * @class View
 * @extends Component
 */
const View = ({ data, properties }) => {
  return (
    <h3 id={data.key} className="Header3">
      {data.header3}
    </h3>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
