/**
 * @module components/theme/Unauthorized/Unauthorized
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { BodyClass } from '@plone/volto/helpers';
import { HorizontalRule } from '../../../../components/theme/View/Common';
import { messagesNotFound } from '../../../../components/theme/I18n/I18n';

/**
 * unauthorized function.
 * @function Unauthorized
 * @returns {string} Markup of the unauthorized page.
 */
// const Unauthorized = () => {
const Unauthorized = (props) => {
  return (
    <div className="view-wrapper">
      <BodyClass className="section-error" />
      <div className="error-header">
        <HorizontalRule className="hr" />
        {/* <h2>{props.intl.formatMessage(messagesNotFound.title)}</h2> */}
        <h2>お探しのページは見つかりませんでした</h2>
        <h2>Sorry, this page isn’t available..</h2>
      </div>

      <div className="message">
        <p className="description">
          大阪大学の研究専用ポータルサイト“ResOU”をご覧いただきありがとうございます。ご不便をおかけして申し訳ございません。
          {/* {props.intl.formatMessage(messagesNotFound.message1)} */}
        </p>
        {/* <p>{props.intl.formatMessage(messagesNotFound.message2)}</p> */}
        <p>
          再度アドレスをご確認いただき、お間違いがなければ、ご指定のページは削除、変更されたか、現在利用できない可能性があります。お手数ですが、TOPページまたはサイト内検索よりお探しください。
        </p>
        <p>&nbsp;</p>
        <p className="description">
          The URL you requested may have been mistyped or the page may have been
          moved.
        </p>
        <p>
          You can return to the top page or try searching via the search page.
        </p>
      </div>
    </div>

    // <div className="view-wrapper">
    //   <BodyClass className="section-error" />
    //   <div className="error-header">
    //     <HorizontalRule className="hr" />
    //     <h2>
    //       <FormattedMessage
    //         id="This page does not seem to exist…"
    //         defaultMessage="This page does not seem to exist…"
    //       />
    //     </h2>
    //   </div>

    //   <div className="message">
    //     <p className="description">
    //       <FormattedMessage
    //         id="We apologize for the inconvenience, but the page you were trying to access is not at this address. You can use the links below to help you find what you are looking for."
    //         defaultMessage="We apologize for the inconvenience, but the page you were trying to access is not at this address. You can use the links below to help you find what you are looking for."
    //       />
    //     </p>
    //     <p>
    //       <FormattedMessage
    //         id="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
    //         defaultMessage="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
    //         values={{
    //           site_admin: (
    //             <Link to="/contact-form">
    //               <FormattedMessage
    //                 id="Site Administration"
    //                 defaultMessage="Site Administration"
    //               />
    //             </Link>
    //           ),
    //         }}
    //       />
    //     </p>
    //     <p>
    //       <FormattedMessage id="Thank you." defaultMessage="Thank you." />
    //     </p>
    //   </div>
    // </div>
  );
};

export default injectIntl(Unauthorized);
