/**
 * Form ID form widget for the part of Edit component of TextLine, TextArea block for MailForm.
 * @module components/manage/Blocks/Mailform/EditorWidgets/FormIDWidget
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Input, Label } from 'semantic-ui-react';
import { mailFormFormIds } from '../../../../../actions/';

/**
 * Edit form ID widget class.
 * @class Edit
 * @extends Component
 */
class FormID extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    block: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    isAddContent: PropTypes.bool,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      formId: props.data.formId || '',
    };
  }

  /**
   * Component will receive props
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.formId) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        formId: '',
      });
    }
    this.props.storeFormIds(
      this.props.isAddContent ? 'add' : this.props.uid,
      this.props.block,
      this.props.data.formId,
    );
  }

  /**
   * Validate duplicated form ID method.
   * @function validateDuplicatedId
   * @returns {boolean}
   */
  validateDuplicatedId = () => {
    if (
      !this.props.formIds ||
      !this.props.formIds[this.props.isAddContent ? 'add' : this.props.uid]
    ) {
      return false;
    } else {
      const formIds = this.props.isAddContent
        ? Object.values(this.props.formIds['add'])
        : Object.values(this.props.formIds[this.props.uid]);
      const duplicatedId = formIds.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x);
      });
      return duplicatedId.some((item) => item === this.state.formId);
    }
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Form.Field>
        <Label htmlFor="form-id">
          <FormattedMessage id="FormID" defaultMessage="Form ID" />
        </Label>
        {this.validateDuplicatedId() ? (
          <Label basic color="red" pointing="below">
            <FormattedMessage
              id="formIdDuplicated"
              defaultMessage="This ID is already used in this form. Please change it."
            />
          </Label>
        ) : null}
        <Input
          name="form-id"
          value={this.state.formId}
          error
          onChange={(e, target) => {
            this.props.onChangeBlock(this.props.block, {
              ...this.props.data,
              formId: target.value,
            });
            this.setState({ formId: target.value });
            this.props.storeFormIds(
              this.props.isAddContent ? 'add' : this.props.uid,
              this.props.block,
              target.value,
            );
          }}
        />
      </Form.Field>
    );
  }
}

/**
 * Container method component to connect the redux state and FormID component.
 * @function FormIDContainer
 * @param {function connect(params)} connect connect function.
 * @returns {undefined}
 */
const FormIDContainer = connect(
  (state, props) => {
    return {
      uid: state?.content?.data?.UID,
      formIds: state?.mailFormFormIds?.mailFormFormId,
      locale: state?.content?.data?.language?.token ?? 'ja',
      isAddContent: state?.router?.location?.pathname
        ? state.router.location.pathname.endsWith('/add')
        : false,
    };
  },
  (dispatch, props) => {
    return {
      storeFormIds: (uid, block, formId) => {
        dispatch(mailFormFormIds(uid, block, formId));
      },
    };
  },
)(FormID);

export default injectIntl(FormIDContainer);
