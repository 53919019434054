/**
 * View SDGs block.
 * @module components/manage/Blocks/SDGs/View
 */

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import './View.css';

/**
 * View SDGs block class.
 * @class View
 * @extends Component
 */
const View = ({ data, properties }) => {
  const [locale] = useSelector((state) => {
    return [state?.content?.data?.language?.token ?? 'ja'];
  });
  const items = properties?.sdgs ?? [];
  return (
    items.length > 0 && (
      <div className="SDGs">
        {/*<h3 id={data.key} className="Header3">
        {data.header3}
      </h3>*/}
        <ul className="sdgsList">
          {items.map((item, index) => {
            return (
              <li className="item" key={index}>
                <img
                  src={flattenToAppURL(
                    `/assets/images/sdgs/${item.token}_${locale}.png`,
                  )}
                  alt={item.title}
                />
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
