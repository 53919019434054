/**
 * View unordered List block for the research point.
 * @module components/manage/Blocks/ResearchPoint/View
 * copied from src/components/manage/Blocks/SubTitle/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import redraft from 'redraft';
import { List } from 'semantic-ui-react';
import { settings } from '~/config';
import './View.css';

/**
 * View List block function component.
 * @function View
 * @param {Object} data component property data.
 * @returns {stirng} Markup of the component.
 */
const View = ({ data }) => (
  <div className="ListBlock">
    {data && data.research_point && (
      <List
        as={data.research_point.ordered ? 'ol' : 'ul'}
        ordered={data.research_point.ordered}
        className="List"
      >
        {map(data.research_point.list_items, (item) => (
          <List.Item as="li" key={item.key} className="ListItem">
            {item.value && item.value.blocks && item.value.blocks[0].text
              ? redraft(
                  item.value,
                  settings.ToHTMLRenderers,
                  settings.ToHTMLOptions,
                )
              : '\u00A0'}
          </List.Item>
        ))}
      </List>
    )}
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
