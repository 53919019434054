/**
 * Story type view component.
 * @module components/theme/View/StoryView
 */

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Tags } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { DefaultBody, HelmetTitle, HorizontalRule, SNSShare } from './Common';
import { PublisherBox } from '../../index';
import { ArticleInquiryButton } from '../../../components/index';
import {
  BrowsingHistorySaver,
  ItemListRelatedtoDepartment,
  ItemListRelatedtoTeacher,
} from '../../../components/index';
import { MediaQueryConfig } from '../../../helpers/GlobalStyle/GlobalStyle';
import './StoryView.css';

const getCoverYearMonth = (locale, dt) => {
  if (dt !== null) {
    const event = new Date(dt);
    if (locale === 'ja') {
      return (
        '（' +
        event.toLocaleDateString('ja-JP', { year: 'numeric', month: 'long' }) +
        '取材）'
      );
    } else {
      return (
        'Interview held in ' +
        event.toLocaleDateString('en-US', { year: 'numeric', month: 'long' })
      );
    }
  }
};

const LabelStoryZ = ({ locale }) => {
  return (
    <Link to={flattenToAppURL(`/${locale}/story`)}>
      <div className="labelStory">
        <FormattedMessage
          id="Label StoryZ"
          defaultMessage="StoryZ of Research"
        />
      </div>
    </Link>
  );
};

const HeaderSet = ({ content }) => {
  return (
    <>
      <h2 className="title">{content.title}</h2>
      {content.subtitle && <h3 className="subtitle">{content.subtitle}</h3>}
      {content.position && <p className="position">{content.position}</p>}
      {content.description && (
        <p className="description">{content.description}</p>
      )}
    </>
  );
};

/**
 * Component to display the default view.
 * @function StoryView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const StoryView = ({ content, locale }) => {
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });

  const mainImage = (content) => {
    return isSP
      ? flattenToAppURL(
          content?.title_image_sp
            ? content?.title_image_sp?.scales?.large?.download ?? ''
            : '/assets/images/nowprinting_580_280.jpg',
        )
      : flattenToAppURL(
          content?.title_image_pc
            ? content?.title_image_pc?.scales?.large?.download ?? ''
            : '/assets/images/nowprinting_1160_560.jpg',
        );
  };

  // ArticleInquiryButtonに渡すProperties
  const url = content?.['@id'] || '';
  const researcher = content?.position || '';
  let affiliationList = [];
  content?.departments && content.departments.length > 1
    ? _.forEach(content.departments, (item) => affiliationList.push(item.title))
    : [];
  const affiliation = affiliationList.join(' ');

  return (
    <div className="StoryView">
      <HelmetTitle pageTitle={content.title} />
      <div className="storyViewInner">
        <LabelStoryZ locale={locale} />
        <div className="storyHeader">
          <div className="storyHeaderInner">
            <HeaderSet content={content} />
          </div>
        </div>
        <img
          alt={content.title}
          className="mainImage"
          src={mainImage(content)}
        />
        <div className="storyBody">
          <div className="storyBodyInner">
            <HorizontalRule className="hr" />
            <DefaultBody content={content} />
            {content?.interview_date && (
              <p className="documentInterviewDate">
                {getCoverYearMonth(locale, content?.interview_date)}
              </p>
            )}
            {content?.subjects && content?.subjects?.length > 0 && (
              <Tags tags={content?.subjects} />
            )}
            <ArticleInquiryButton
              url={url}
              researcher={researcher}
              affiliation={affiliation}
            />
            <SNSShare content={content} />
            <PublisherBox />
          </div>
        </div>
      </div>
      <ItemListRelatedtoTeacher />
      <ItemListRelatedtoDepartment />
      <BrowsingHistorySaver />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
StoryView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

const StoryViewContainer = connect((state, props) => {
  return {
    locale: state?.content?.data?.language?.token ?? 'ja',
  };
})(StoryView);

export default injectIntl(StoryViewContainer);
