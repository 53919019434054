import { GET_POPULAR_ARTICLE_LIST } from '../../constants/ActionTypes';

export function popularArticleList(apiPath, bStart = 0) {
  const safeApiPath = apiPath || 'ja';
  return {
    type: GET_POPULAR_ARTICLE_LIST,
    request: {
      op: 'get',
      path: `/${safeApiPath}/@popular-article-list?b_start=${bStart}`,
    },
    bStart: bStart,
  };
}
