/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

/**
 * 昔ながらの Redux の方法で定義する場合
 * APIアクセスの場合はこちらを使う
 */

// export const RESOU_SAMPLE_ACTION = 'RESOU_SAMPLE_ACTION';

/**
 * サイト全体の設定情報
 */
export const GET_SITE_MAIN_INFO = 'GET_SITE_MAIN_INFO';

/**
 * SotryZの一覧 (トップページ用)
 */
export const GET_STORYZ_LIST = 'GET_STORYZ_LIST';

/**
 * SotryZの一覧 (この先生の他の研究記事)
 */
export const GET_STORYZ_LIST_RELATEDTO_TEACHER =
  'GET_STORYZ_LIST_RELATEDTO_TEACHER';

/**
 * SiteImageの表示非表示
 */
export const SITE_IMAGE_DISPLAY = 'SITE_IMAGE_DISPLAY';

/**
 * 検索基本API
 */
export const GET_SEARCH_BASIC = 'GET_SEARCH_BASIC';

/**
 * 現在のTerm content
 */
export const NOW_TERM_CONTENT = 'NOW_TERM_CONTENT';

/**
 * 現在のTerm content
 */
export const DISPLAY_TERM_CONTENT = 'DISPLAY_TERM_CONTENT';

/**
 * Term contentを更新
 */
export const MANAGE_TERM_CONTENT = 'MANAGE_TERM_CONTENT';

/**
 * Dimmerの表示状態の更新
 */
export const CHANGE_CUSTOM_DIMMER = 'CHANGE_CUSTOM_DIMMER';

/**
 * 特集まとめ一覧 (より濃厚に研究を味わいたい方へ)
 */
export const GET_FEATURE_LIST_ON_TOP_PAGE = 'GET_FEATURE_LIST_ON_TOP_PAGE';

/**
 * よく読まれている記事一覧
 */
export const GET_POPULAR_ARTICLE_LIST = 'GET_POPULAR_ARTICLE_LIST';

/**
 * Research/StoryZの一覧 (この先生の他の研究記事)
 */
export const GET_ITEM_LIST_RELATEDTO_TEACHER =
  'GET_ITEM_LIST_RELATEDTO_TEACHER';

/**
 * Research/StoryZの一覧 (この組織の他の研究記事)
 */
export const GET_ITEM_LIST_RELATEDTO_DEPARTMENT =
  'GET_ITEM_LIST_RELATEDTO_DEPARTMENT';
/* 上記一覧をクリア */
export const GET_ITEM_LIST_RELATEDTO_DEPARTMENT_CLEAR =
  'GET_ITEM_LIST_RELATEDTO_DEPARTMENT_CLEAR';

/**
 * 特集記事の一覧 (過去の特集記事)
 */
export const GET_PAST_FEATURE_LIST = 'GET_PAST_FEATURE_LIST';

/**
 * 閲覧履歴の一覧
 */
export const GET_BROWSING_HISTORY_LIST = 'GET_BROWSING_HISTORY_LIST';

/**
 * 閲覧履歴を更新
 */
export const UPDATE_BROWSING_HISTORY = 'UPDATE_BROWSING_HISTORY';

/**
 * 特集まとめ記事の一覧TOP
 */
export const GET_FEATURE_LIST_FOR_TOP = 'GET_FEATURE_LIST_FOR_TOP';

/**
 * 同じ特集まとめ内の記事（特集）
 */
export const GET_FEATURE_LIST = 'GET_FEATURE_LIST';

/**
 * グラフ
 */
export const GET_RESOU_GRAPH_DATA = 'GET_RESOU_GRAPH_DATA';

/**
 * 検索結果取得リセット
 */
export const RESET_SEARCH_RESULT_CONTENT = 'RESET_SEARCH_RESULT_CONTENT';

/**
 * 検索結果取得
 */
export const SEARCH_RESULT_CONTENT = 'SEARCH_RESULT_CONTENT';

/**
 * メールフォーム送信
 */
export const POST_MAIL_FORM_CONTENT = 'POST_MAIL_FORM_CONTENT';

/**
 * タグ一覧
 */
export const GET_TAG_LIST = 'GET_TAG_LIST';

/**
 * メールフォーム送信状態クリア
 */
export const POST_MAIL_FORM_CONTENT_CLEAR = 'POST_MAIL_FORM_CONTENT_CLEAR';

/**
 * メールフォームのformId重複チェック用
 */
export const MAIL_FORM_FORM_IDS = 'MAIL_FORM_FORM_IDS';

/**
 * 言語トップページの研究一覧取得用
 */
export const GET_RESEARCH_LIST_ON_TOP = 'GET_RESEARCH_LIST_ON_TOP';

/**
 * マルチリンガル対応ボキャブラリ取得用
 */
export const GET_MULTILINGUAL_VOCABULARY = 'GET_MULTILINGUALVOCABULARY';

/**
 * 研究情報ページの詳細表示
 */
export const CHANGE_VISIBLE_RESEARCH_CONTENT_FULL =
  'CHANGE_VISIBLE_RESEARCH_CONTENT_FULL';

/**
 * タグ検索API取得用
 */
export const GET_SEARCH_TAG = 'GET_SEARCH_TAG';
export const RESET_SEARCH_TAG = 'RESET_SEARCH_TAG';

/**
 * 今時の方法は Action Type を別途定義しない
 */
