import { GET_FEATURE_LIST } from '../../constants/ActionTypes';

export function featureList(locale, feature_folder_id) {
  const safeFeature_folder_id = feature_folder_id ?? '';
  return {
    type: GET_FEATURE_LIST,
    request: {
      op: 'get',
      path: `/${locale || 'ja'}/feature/${safeFeature_folder_id}/@feature-list`,
    },
  };
}
