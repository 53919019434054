import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Icon } from '../../../';
import { StyleConfig } from '../../../../helpers/GlobalStyle/GlobalStyle';
import './TermButton.css';

const messages = defineMessages({
  glossary: {
    id: 'Glossary',
    defaultMessage: 'Glossary',
  },
});

function scrollToTerm(target) {
  if (typeof window !== 'undefined') {
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth',
    // });
    if (document.querySelector(target)) {
      document.querySelector(target).scrollIntoView({ behavior: 'smooth' });
    }
  }
}

const TermButton = (props) => {
  return (
    <button
      className={`TermButton`}
      onClick={(e) => {
        scrollToTerm(props.termTo);
      }}
    >
      <Icon
        icon="book"
        size={StyleConfig.menuIconSizeSmall}
        color={StyleConfig.iconColorLightUniversity}
      ></Icon>{' '}
      {props.intl.formatMessage(messages.glossary)}
    </button>
  );
};

TermButton.prototype = {
  termTo: PropTypes.string,
};

export default injectIntl(TermButton, {});
