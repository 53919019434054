import { GET_FEATURE_LIST_FOR_TOP } from '../../constants/ActionTypes';

export function featureListForTop(locale, b_start) {
  const safeLocale = locale || 'ja';
  const b_start_filterd = b_start ?? 0;

  return {
    type: GET_FEATURE_LIST_FOR_TOP,
    request: {
      op: 'get',
      path: `/${safeLocale}/feature/@feature-folder-list-on-folder?b_start=${b_start_filterd}`,
    },
  };
}
