/**
 * Search widget component.
 * @module components/theme/SearchWidget/SearchWidgetForSearch
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input, Dropdown } from 'semantic-ui-react';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { Icon, SearchWidgetWordList } from '../../../../components/index';
import { StyleConfig } from '../../../../helpers/GlobalStyle/GlobalStyle';
import { connect } from 'react-redux';
import { getSearchBasic } from '../../../../actions';
import './SearchWidgetForSearch.css';
import _ from 'lodash';
import qs from 'query-string';
import { searchContent, resetSearchContent } from '../../../../actions';
import { toSearchOptions } from '../../../../customizations/components/theme/Search/Search';
import { isSpAndTablet } from '../../../../helpers/GlobalStyle/GlobalStyle';
import { Locale } from '../../../../helpers';
import { siteImageDisplay } from '../../../../actions';
import { CookieSetting } from 'constants/Setting';

// import { Icon } from '@plone/volto/components';
// import zoomSVG from '@plone/volto/icons/zoom.svg';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
  relevance: {
    id: 'Sort Relevance',
    defaultMessage: 'Relevance',
  },
  latest: {
    id: 'Sort Latest',
    defaultMessage: 'Latest',
  },
});

/**
 * SearchWidget component class.
 * @class SearchWidget
 * @extends Component
 */
class SearchWidgetForSearch extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onChangeText = this.onChangeText.bind(this);
    this.onChangeSection = this.onChangeSection.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      text: '',
      section: false,
      locale: '',
      isSP: isSpAndTablet(),
      sort: 'latest',
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    // console.log({ isSP: this.state.isSP });
    this.setState({ isSP: isSpAndTablet() });
  }

  componentDidMount() {
    this.setState({
      text: qs.parse(this.props.location.search).SearchableText,
      // locale: this.props.locale,
    });
    this.props.getSearchBasic(this.props.locale);
    window.addEventListener('resize', this.updateDimensions);
    // console.log({ SearchWidgetForSearch_componentDidMount: this.props.locale });
  }

  componentWillReceiveProps() {
    if (this.props.locale !== this.state.locale) {
      // console.log(
      //   'SearchWidgetForSearch_componentWillReceiveProps_getSearchBasic',
      // );
      this.setState({
        locale: this.props.locale,
      });
      this.props.getSearchBasic(this.props.locale);
    }
    // console.log({
    //   SearchWidgetForSearch_componentWillReceiveProps: this.props.locale,
    //   props: this.props.locale,
    //   state: this.state.locale,
    // });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  /**
   * On change text
   * @method onChangeText
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onChangeText(event, { value }) {
    this.setState({
      text: value,
    });
  }

  /**
   * On change section
   * @method onChangeSection
   * @param {object} event Event object.
   * @param {bool} checked Section checked.
   * @returns {undefined}
   */
  onChangeSection(event, { checked }) {
    this.setState({
      section: checked,
    });
  }

  /**
   * Submit handler
   * @method onSubmit
   * @param {event} event Event object.
   * @returns {undefined}
   */
  onSubmit(event) {
    const section = this.state.section ? `&path=${this.props.pathname}` : '';
    this.props.resetSearchContent();
    this.doSearch(
      this.state.text ?? '',
      this.props.subject,
      this.props.path,
      section,
      this.props.locale,
      this.state.sort,
    );
    event.preventDefault();
  }

  onSubmit2() {
    const section = this.state.section ? `&path=${this.props.pathname}` : '';
    this.props.resetSearchContent();
    this.doSearch(
      this.state.text ?? '',
      this.props.subject,
      this.props.path,
      section,
      this.props.locale,
      this.state.sort,
    );
  }

  /**
   * Search based on the given searchableText, subject and path.
   * @method doSearch
   * @param {string} searchableText The searchable text string
   * @param {string} subject The subject (tag)
   * @param {string} path The path to restrict the search to
   * @returns {undefined}
   */
  doSearch(searchableText, subject, path, section, locale, sort) {
    // console.log({
    //   searchableText,
    //   subject,
    //   path,
    // });
    this.props.history.push(
      `/search?SearchableText=${searchableText ?? ''}${section ?? ''}${
        locale ? '&lang=' + locale : ''
      }${sort ? '&sort=' + sort : ''}`,
    );
    this.props.siteImageDisplay(CookieSetting.SITE_IMAGE_VISIBLE.off);
    this.props.searchContent(
      '',
      toSearchOptions(searchableText, subject, path, null, locale, sort),
    );
  }

  createButtonElement() {
    //const latest = this.props.intl.formatMessage(messages.latest);
    //const relevance = this.props.intl.formatMessage(messages.relevance);
    const locale = this.props.locale;
    const countryOptions = [
      {
        key: 'latest',
        value: 'latest',
        text: locale === 'en' ? 'Latest' : '最新順',
      },
      {
        key: 'relevance',
        value: 'relevance',
        text: locale === 'en' ? 'Relevance' : '関連度順',
      },
    ];
    return (
      <div className="SearchSort">
        <Dropdown
          placeholder="Select your country"
          search
          selection
          defaultValue={this.state.sort}
          options={countryOptions}
          onChange={(event, data) => {
            this.setState(
              () => {
                return { sort: data?.value }; // 即時
              },
              () => {
                this.onSubmit2();
              },
            );
          }}
        />
        <Icon icon="angle down" size="1.5rem" />
      </div>
    );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div className="SearchWidgetForSearch">
        {this.state.isSP ? (
          <div>
            <h2>
              {this.props.searchBasic?.title_sp1 || ''}
              <br />
              {this.props.searchBasic?.title_sp2 || ''}
            </h2>
          </div>
        ) : (
          <h2>{this.props.searchBasic?.title || ''}</h2>
        )}

        <p>{}</p>
        <div className="search-form">
          <Form action="/search" onSubmit={this.onSubmit}>
            <Form.Field className="searchbox">
              <Input
                aria-label={this.props.intl.formatMessage(messages.search)}
                onChange={this.onChangeText}
                name="SearchableText"
                value={this.state.text}
                transparent
                placeholder={this.props.searchBasic?.searchBoxPlaceholder || ''}
                title={this.props.intl.formatMessage(messages.search)}
                className="search"
              />
              <button
                aria-label={this.props.intl.formatMessage(messages.search)}
              >
                <Icon
                  icon="search"
                  size={StyleConfig.menuIconSize}
                  color={StyleConfig.iconColorLight}
                ></Icon>
              </button>
            </Form.Field>
          </Form>
        </div>

        <SearchWidgetWordList
          title={this.props.searchBasic?.searchWordsTitle || ''}
          data={this.props.searchBasic?.popularSearchWords}
          doSearch={(text) => {
            this.doSearch(
              text ?? '',
              this.props.subject,
              this.props.path,
              null,
              this.props.locale,
            );
            this.props.resetSearchContent();
            this.setState({
              text: text ?? '',
            });
          }}
        ></SearchWidgetWordList>
        <div className="searchSortWrapper">{this.createButtonElement()}</div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state, props) => {
      const locale = Locale.getLocale(state?.content?.data?.language?.token);
      return {
        searchBasic: state?.searchBasic?.result?.data?.attributes,
        searchableText: qs.parse(props.location.search).SearchableText,
        subject: qs.parse(props.location.search).Subject,
        path: qs.parse(props.location.search).path,
        locale,
      };
    },
    (dispatch, props) => {
      return {
        getSearchBasic: (locale) => dispatch(getSearchBasic(locale)),
        searchContent: (url, options) => {
          dispatch(searchContent(url, options));
        },
        resetSearchContent: () => {
          dispatch(resetSearchContent());
          if (props?.loadingRef) {
            props.loadingRef.resetNextBStart();
          }
        },
        siteImageDisplay: (visible) => dispatch(siteImageDisplay(visible)),
      };
    },
  ),
)(SearchWidgetForSearch);
