/**
 * Routes.
 * see https://github.com/plone/volto/blob/master/src/routes.js
 * @module routes
 */

import React from 'react';
import { App } from '@plone/volto/components';
import { defaultRoutes } from '@plone/volto/routes';
import { withRouter } from 'react-router-dom';
import { Login, ContactForm } from '@plone/volto/components';
import { TagListViewContainer } from './components/theme/View/TagListView';
import { SearchTagViewContainer } from './components/theme/View/SearchTagView';
import { LabelFeature } from './components/theme/View/Common';

/**
 * お問い合わせ用サンプルコンポーネント
 */
// export const Sample = withRouter(() => <h1>サンプル</h1>);
export const Sample = () => <h1>サンプル</h1>;

export const TagList = () => <h1>タグ一覧</h1>;

/**
 * カスタムルーティング
 * http://localhost:3000/contact
 */
const customRoutes = {
  path: '/sample',
  component: Sample,
  exact: true,
};

const customTagList = {
  path: '/*/tag-list',
  component: TagListViewContainer,
  exact: true,
};

const customSearchTag = {
  path: '/search-tag',
  component: SearchTagViewContainer,
  exact: true,
};

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      // Add your routes here
      customRoutes,
      customTagList,
      customSearchTag,
      ...defaultRoutes,
    ],
  },
];

export default routes;
