/**
 * View HeaderText block (pair of <h3> and <p>).
 * @module components/manage/Blocks/HeaderText/View
 * based on @module components/manage/Blocks/Hero/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import './View.css';

/**
 * View HeaderText block function component.
 * @function View
 * @param {Object} data Object data.
 * @returns {string} Markup of the component.
 */
const View = ({ data }) => {
  return (
    <div
      inverted={data.inverted}
      id={data.key}
      className={`block header-text HeaderText${
        data?.inverted ? 'Blue' : 'White'
      } ${data?.center ? 'HeaderTextCenter' : ''}`}
    >
      {data.title && <h3>{data.title}</h3>}
      {data.text && <p>{data.text}</p>}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
