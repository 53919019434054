/**
 * Select display item by dropdown for RestrictedListing view block.
 * @module components/manage/Blocks/RestrictedListing/SelectDisplayWidgetjsx
 */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Icon } from '../../../index';
import './SelectDisplayWidget.css';

const getSelectorAttrsByLang = (setting, locale) => {
  let settingByLang = [];
  for (let i = 0; i < setting.length; i++) {
    let key = setting[i].key;
    let label =
      locale === 'en'
        ? setting[i].selector.labelEn
        : setting[i].selector.labelJa;
    let description =
      locale === 'en'
        ? setting[i].selector.descriptionEn
        : setting[i].selector.descriptionJa;
    settingByLang.push({
      key: key,
      selector: {
        value: key,
        label: label,
        description: description,
      },
      query: {
        add: {
          research_highlight: [key],
          // {
          //   i: 'research_highlight',
          //   o: 'plone.app.querystring.operation.selection.any',
          //   v: [key],
          // },
          metadata_fields: [
            'announcement_date',
            'research_highlight',
            'Subject',
            'physical_path',
            'title_image_sp_size',
            'link',
          ],
        },
        update: {
          sort_on: 'announcement_date',
          sort_order: 'reverse',
        },
      },
    });
  }
  return settingByLang;
};

/**
 * getIndexFromKey キーのsetting内での位置を返す
 * @param {*} setting
 * @param {*} key
 */
const getIndexFromKey = (setting, key) => {
  const keyMap = setting.map((item) => item.key);
  const index = keyMap ? keyMap.findIndex((item) => item === key) : -1;
  return index !== -1 ? index : 0;
};

/**
 * SelectDisplayWidget component
 * @param {*} props
 */
const SelectDisplayWidget = (props) => {
  const initSelectedOption = props.initQueryKey; // TODO: 第一初期値をcookieから取得する
  const initIsExtended = false;
  const [selectedOption, setSelectedOption] = useState(initSelectedOption);
  const [isExtended, setIsExtended] = useState(initIsExtended);
  const dropDownRef = useRef();

  const removeHandleClickOutsideListener = () => {
    document.removeEventListener('click', handleClickOutside);
  };

  const handleClickOutside = (e) => {
    if (dropDownRef.current?.contains(e.target)) return;
    setIsExtended(false);
    removeHandleClickOutsideListener();
  };

  useEffect(() => {
    if (!props.setting[0]) {
      return;
    }
    const elementByCategory = props.setting[0];
    const query = elementByCategory.query;
    props.updator(query);
  }, []);

  const handleClick = (e, key, isSelected) => {
    if (!isSelected) {
      const elementByCategory = props.setting.find((item) => item.key === key);
      const query = elementByCategory.query;
      props.updator(query);
      setSelectedOption(key);
      setIsExtended(false);
      removeHandleClickOutsideListener();
    } else if (!isExtended) {
      setIsExtended(true);
      document.addEventListener('click', (e) => {
        handleClickOutside(e);
      });
    } else {
      setIsExtended(false);
      removeHandleClickOutsideListener();
    }
  };

  const attrs = getSelectorAttrsByLang(props.setting, props.locale);
  return (
    <div className="SelectDisplayWidgetWrapper" data-extended={isExtended}>
      <div
        className="SelectDisplayWidget"
        data-extended={isExtended}
        ref={dropDownRef}
        style={{
          top: -4.6 * getIndexFromKey(props.setting, selectedOption) + 'rem',
        }}
      >
        {/* TODO: lintエラー解消 */}
        {attrs.map((attr) => (
          <div
            key={attr.key}
            className={`item ${!attr.selector.description && 'noDescription'}`}
            role="button"
            data-selected={attr.key === selectedOption ? 'selected' : null}
            onClick={(e) =>
              handleClick(e, attr.key, attr.key === selectedOption)
            }
          >
            <span className="label">{attr.selector.label}</span>
            {attr.selector.description && (
              <span className="description">{attr.selector.description}</span>
            )}
            {attr.key === selectedOption && (
              <span className="selectedIcon">
                <Icon icon="angle down" size="1.5rem" />
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

// TODO:
SelectDisplayWidget.propTypes = {
  category: PropTypes.string.isRequired,
  updator: PropTypes.func.isRequired,
  initQueryKey: PropTypes.string.isRequired,
  setting: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default injectIntl(SelectDisplayWidget);
