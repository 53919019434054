/**
 * Site Image component.
 * 1921 * 550 (1:0.286)
 * 画像の大きさ
 * @module components/theme/SiteImage
 */

import React, { useState, useEffect } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  MediaQueryConfig,
  StyleConfig,
} from '../../../../helpers/GlobalStyle/GlobalStyle';
import { useMediaQuery } from 'react-responsive';
import { Icon } from '../../../../components/index';
import cookie from 'react-cookie';
import { CookieSetting, SiteImageSetting } from '../../../../constants/Setting';
import { stringToBoolean } from '../../../../helpers/Cookie/Cookie';
import { Page } from '../../../../helpers/';
import { siteImageDisplay } from '../../../../actions';
import { Link } from 'react-router-dom';
import './SiteImage.css';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({});

/**
 * SiteImage コンポーネント
 * @param {*} props
 */
const SiteImage = (props) => {
  const [height, setHeight] = useState(0);

  const updateDimensions = () => {
    setHeight(window.innerWidth * SiteImageSetting.ASPECT_FOR_HEIGHT);
  };

  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });

  useEffect(() => {
    // like componentDidMount
    if (window) {
      updateDimensions();
    }
    window.addEventListener('resize', updateDimensions);

    const visible = cookie.load(CookieSetting.SITE_IMAGE_VISIBLE.name);
    props.siteImageDisplay(visible);

    // like componentWillUnmount
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [props]);

  function isVisible(visible, pathname) {
    if (Page.isTopByLocation(pathname)) {
      return true;
    }
    return stringToBoolean(visible);
  }

  return (
    <div className="SiteImage">
      {isVisible(props?.visible, props?.pathname) && (
        <>
          <div className="logo">
            <a href={props.opening_img_sp.home_url} target="blank">
              <img src="/assets/images/ou_logo_blue.svg" width="33" alt="" />
            </a>
          </div>
          <Link
            to={
              flattenToAppURL(
                isSP ? props?.opening_img_sp?.url : props?.opening_img_pc?.url,
              ) || ''
            }
          >
            <img
              className="openingImage"
              src={
                flattenToAppURL(
                  isSP
                    ? props?.opening_img_sp?.src
                    : props?.opening_img_pc?.src,
                ) || ''
              }
              alt={
                (isSP
                  ? props?.opening_img_sp?.alt
                  : props?.opening_img_pc?.src) || ''
              }
              height={height}
            />
          </Link>
        </>
      )}
    </div>
  );
};

SiteImage.propTypes = {
  /** PC版 */
  opening_img_pc: PropTypes.shape({
    alt: PropTypes.string,
    description: PropTypes.string,
    src: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    home_url: PropTypes.string,
  }),
  /** SP版 */
  opening_img_sp: PropTypes.shape({
    alt: PropTypes.string,
    description: PropTypes.string,
    src: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    home_url: PropTypes.string,
  }),
  siteImageDisplay: PropTypes.func,
  visible: PropTypes.string,
  contentType: PropTypes.string,
  pathname: PropTypes.string,
};

/**
 * SiteImage コンテナ
 */
const SiteImageContainer = connect(
  (state, props) => {
    const locale = state?.content?.data?.language?.token ?? 'ja';
    return {
      contentType: state?.content?.data?.['@type'],
      opening_img_pc:
        state.siteMainInfo?.result?.data?.attributes?.locale[locale]?.header
          ?.opening_img_pc,
      opening_img_sp:
        state.siteMainInfo?.result?.data?.attributes?.locale[locale]?.header
          ?.opening_img_sp,
      visible: state.siteImage?.visible,
    };
  },
  (dispatch, props) => {
    return {
      siteImageDisplay: (visible) => dispatch(siteImageDisplay(visible)),
    };
  },
)(SiteImage);

export default injectIntl(SiteImageContainer, {});
