/**
 * Listing eature folder action for top page
 * トップページの特集表示スライダーに表示するコンテンツを取得するaction
 * @module actions/featureFolderList/featuerFolderList
 */
import { GET_FEATURE_LIST_ON_TOP_PAGE } from '../../constants/ActionTypes';

/**
 * Feature folder list function
 * @param {string} locale 取得対象のロケール（ja/en）
 */
export function featureFolderList(locale) {
  return {
    type: GET_FEATURE_LIST_ON_TOP_PAGE,
    request: {
      op: 'get',
      path: `/${locale || 'ja'}/feature/@feature-folder-list-on-top`,
    },
  };
}
