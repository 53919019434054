import { SITE_IMAGE_DISPLAY } from '../../constants/ActionTypes';

/**
 * SiteImage の表示非表示アクション
 * @param {number} visible
 */
export function siteImageDisplay(visible) {
  return {
    type: SITE_IMAGE_DISPLAY,
    visible: visible,
  };
}
