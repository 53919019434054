/**
 * Edit inquiry source block.
 * @nk24:CMScom 2022/01/21
 * @module components/manage/Blocks/InquirySource/Edit
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import View from './View';

/**
 * Edit inquiry source block functional component.
 * @function Edit
 */
const Edit = ({ properties, data }) => (
  <FormattedMessage
    id="mailform_inquiry_source"
    defaultMessage="Inquiry Source"
  />
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

export default injectIntl(Edit);

// const messages = defineMessages({
//   title: {
//     id: 'Type the title…',
//     defaultMessage: 'Type the title…',
//   },
// });

// const blockRenderMap = Map({
//   unstyled: {
//     element: 'h3',
//   },
// });

// const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

// /**
//  * Edit InquirySource block class.
//  * @class Edit
//  * @extends Component
//  */
// class Edit extends Component {
//   /**
//    * Property types.
//    * @property {Object} propTypes Property types.
//    * @static
//    */
//   static propTypes = {
//     properties: PropTypes.objectOf(PropTypes.any).isRequired,
//     selected: PropTypes.bool.isRequired,
//     index: PropTypes.number.isRequired,
//     onChangeField: PropTypes.func.isRequired,
//     onSelectBlock: PropTypes.func.isRequired,
//     onDeleteBlock: PropTypes.func.isRequired,
//     onAddBlock: PropTypes.func.isRequired,
//     onFocusPreviousBlock: PropTypes.func.isRequired,
//     onFocusNextBlock: PropTypes.func.isRequired,
//     block: PropTypes.string.isRequired,
//     editable: PropTypes.bool,
//   };

//   /**
//    * Default properties
//    * @property {Object} defaultProps Default properties.
//    * @static
//    */
//   static defaultProps = {
//     editable: true,
//   };

//   /**
//    * Constructor
//    * @method constructor
//    * @param {Object} props Component properties
//    * @constructs WysiwygEditor
//    */
//   constructor(props) {
//     super(props);

//     if (!__SERVER__) {
//       let editorState;
//       if (props.properties && props.properties.inquirySource) {
//         const contentState = stateFromHTML(props.properties.inquirySource);
//         editorState = EditorState.createWithContent(contentState);
//       } else {
//         editorState = EditorState.createEmpty();
//       }
//       this.state = { editorState, focus: true };
//     }

//     this.onChange = this.onChange.bind(this);
//   }

//   /**
//    * Component did mount lifecycle method
//    * @method componentDidMount
//    * @returns {undefined}
//    */
//   componentDidMount() {
//     if (this.node) {
//       this.node.focus();
//       this.node._onBlur = () => this.setState({ focus: false });
//       this.node._onFocus = () => this.setState({ focus: true });
//     }
//   }

//   /**
//    * @param {*} nextProps
//    * @returns {boolean}
//    * @memberof Edit
//    */
//   shouldComponentUpdate(nextProps) {
//     return this.props.selected || !isEqual(this.props.data, nextProps.data);
//   }
//   /**
//    * Component will receive props
//    * @method componentWillReceiveProps
//    * @param {Object} nextProps Next properties
//    * @returns {undefined}
//    */
//   UNSAFE_componentWillReceiveProps(nextProps) {
//     if (
//       nextProps.properties.inquirySource &&
//       this.props.properties.inquirySource !==
//         nextProps.properties.inquirySource &&
//       !this.state.focus
//     ) {
//       const contentState = stateFromHTML(nextProps.properties.inquirySource);
//       this.setState({
//         editorState: nextProps.properties.inquirySource
//           ? EditorState.createWithContent(contentState)
//           : EditorState.createEmpty(),
//       });
//     }

//     if (!this.props.selected && nextProps.selected) {
//       this.node.focus();
//       this.setState({ focus: true });
//     }
//   }

//   /**
//    * Change handler
//    * @method onChange
//    * @param {object} editorState Editor state.
//    * @returns {undefined}
//    */
//   onChange(editorState) {
//     this.setState({ editorState }, () => {
//       this.props.onChangeField(
//         'inquirySource',
//         editorState.getCurrentContent().getPlainText(),
//       );
//     });
//   }

//   /**
//    * Render method.
//    * @method render
//    * @returns {string} Markup for the component.
//    */
//   render() {
//     if (__SERVER__) {
//       return <div />;
//     }

//     const placeholder =
//       this.props.data.placeholder ||
//       this.props.intl.formatMessage(messages.title);

//     return (
//       <Editor
//         readOnly={!this.props.editable}
//         onChange={this.onChange}
//         editorState={this.state.editorState}
//         blockRenderMap={extendedBlockRenderMap}
//         handleReturn={() => {
//           if (this.props.data.disableNewBlocks) {
//             return 'handled';
//           }
//           //   this.props.onSelectBlock(
//           //     this.props.onAddBlock(
//           //       config.settings.defaultBlockType,
//           //       this.props.index + 1,
//           //     ),
//           //   );
//           return 'handled';
//         }}
//         placeholder={placeholder}
//         blockStyleFn={() => 'documentFirstHeading'}
//         onUpArrow={() => {
//           const selectionState = this.state.editorState.getSelection();
//           const { editorState } = this.state;
//           if (
//             editorState.getCurrentContent().getBlockMap().first().getKey() ===
//             selectionState.getFocusKey()
//           ) {
//             this.props.onFocusPreviousBlock(this.props.block, this.node);
//           }
//         }}
//         onDownArrow={() => {
//           const selectionState = this.state.editorState.getSelection();
//           const { editorState } = this.state;
//           if (
//             editorState.getCurrentContent().getBlockMap().last().getKey() ===
//             selectionState.getFocusKey()
//           ) {
//             this.props.onFocusNextBlock(this.props.block, this.node);
//           }
//         }}
//         ref={(node) => {
//           this.node = node;
//         }}
//       />
//     );
//   }
// }

// export default injectIntl(Edit);
