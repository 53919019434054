/**
 * Holizontal rule block (Basically for mailform. <hr> tag.).
 * Commonly used for view and edit
 * @module components/manage/Blocks/HeaderText/Edit
 * based on @module components/manage/Blocks/Image/Edit
 */

import React from 'react';
import './HolizontalRule.css';

/**
 * HolizontalRule block function component.
 * @returns {string} Markup of the component.
 */
export default () => {
  return (
    <div class="HolizontalRule">
      <hr />
    </div>
  );
};
