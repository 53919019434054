import { GET_SEARCH_BASIC } from '../../constants/ActionTypes';

/**
 * SiteImage の表示非表示アクション
 */
export function getSearchBasic(localeSource) {
  const locale = localeSource || 'ja';
  return {
    type: GET_SEARCH_BASIC,
    request: {
      op: 'get',
      path: `/@search-basic?lang=${locale}`,
    },
  };
}
