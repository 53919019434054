import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { searchContent } from '@plone/volto/actions';
import ResearchContainer from './ResearchContainer';
import StoryContainer from './StoryContainer';
import Masonry from 'react-masonry-css';
import { useMediaQuery } from 'react-responsive';
import { Loader } from '../../../../helpers';
import {
  MediaQueryConfig,
  MediaQueryWidth,
} from '../../../../helpers/GlobalStyle/GlobalStyle';

import SelectDisplayWidget from './SelectDisplayWidget';
import { researchSettings, storySettings } from './settings';
import './ContentBody.css';

const messages = defineMessages({
  result_number: {
    id: 'Showing {number} articles.',
    defaultMessage: 'Showing {number} articles.',
  },
});

/**
 * apiToSetting APIで取得したデータ形式をsettingsオブジェクトに変形
 * @param {*} research_highlight
 */
const apiToSetting = (researchHighlight) => {
  let researchSettings = [];
  for (let key in researchHighlight) {
    researchSettings.push({
      key: key,
      selector: {
        value: key,
        labelJa: researchHighlight[key].ja,
        labelEn: researchHighlight[key].en,
        descriptionEn: '',
        descriptionJa: '',
      },
      query: {
        add: {
          research_highlight: [key],
          // {
          //   i: 'research_highlight',
          //   o: 'plone.app.querystring.operation.selection.any',
          //   v: [key],
          // },
          metadata_fields: [
            'announcement_date',
            'research_highlight',
            'Subject',
            'physical_path',
            'title_image_sp_size',
            'link',
          ],
        },
        update: {
          sort_on: 'announcement_date',
          sort_order: 'reverse',
        },
      },
    });
  }
  return researchSettings;
};

/**
 * getSettingsFromType contentTypeに応じたsettingsオブジェクトを取得
 * @param {string} contentType
 * get settings by contenttype
 */
const getSettingsFromType = (contentType, research_highlight) => {
  if (contentType === 'ResOUResearch') {
    return [...researchSettings, ...apiToSetting(research_highlight)];
  } else if (contentType === 'ResOUStory') {
    return storySettings;
  } else {
    return [];
  }
};

const TabletWidth = MediaQueryWidth.TABLET;

const ListingContainerWrapper = ({ data, listingItems, type, isEditMode }) => {
  const breakpointColumns = {
    default: 4,
    1200: 3,
    1000: 2,
    [TabletWidth]: 1,
  };
  if (type === 'ResOUStory') {
    return (
      <ul className="ContentBodyStory">
        {listingItems.map((item) => (
          <li key={item['@id']}>
            <StoryContainer content={item} isEditMode={isEditMode} />
          </li>
        ))}
      </ul>
    );
  } else if (type === 'ResOUResearch') {
    return (
      <div className="ContentBodyResearch">
        <Masonry
          breakpointCols={breakpointColumns}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {listingItems.map((item) => (
            <div key={item['@id']}>
              <ResearchContainer content={item} isEditMode={isEditMode} />
            </div>
          ))}
        </Masonry>
      </div>
    );
  } else {
    return <div></div>;
  }
};

/**
 * ContentBody Component
 * メインコンポーネント
 * @param {*} param
 */

const ContentBody = ({
  data,
  properties,
  intl,
  isEditMode,
  research_highlight,
  locale,
}) => {
  const [loading, setLoading] = useState(true);
  const [batchingNext, setBatchingNext] = useState();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [queryFromSelector, setQueryFromSelector] = useState();
  const [contentArray, setContentArray] = useState([]);
  const [isLast, setIsLast] = useState(false);
  const [mergedQuery, setMergedQuery] = useState();

  const querystringResults = useSelector((state) => state.search.subrequests);
  const dispatch = useDispatch();
  const containerType =
    data?.query?.portal_type &&
    data.query.portal_type.length &&
    data.query.portal_type[0];
  const path = data.selected?.path || '';

  /**
   * used by SelectDisplayWidget
   * set selected query to queryFromSelector state
   * @param {*} query
   */
  const updateQuery = (query) => {
    setQueryFromSelector(query);
    setPage(0);
  };

  /**
   * struct query from data
   * data から query を作成する
   */
  const settingsByType = getSettingsFromType(containerType, research_highlight); // TODO: null時の処理
  const initQueryKey = null || settingsByType[0]?.key || ''; // TODO: Cookie処理
  const initQuery = null ||
    settingsByType[0]?.query || { add: [{}], update: {} };
  const defaultBSize = 16;

  const buildQuery = (data, filter) => {
    let query;
    if (data.query?.portal_type && data.query.portal_type.length > 0) {
      query = {
        portal_type: data.query.portal_type,
        path: data.query.path,
        b_size: data.b_size || defaultBSize,
        b_start: (data.b_size || defaultBSize) * page,
      };
    } else return data;
    if (filter?.add) {
      query = {
        ...query,
        ...filter.add,
      };
    } else {
      query = {
        ...data.query,
        ...initQuery.add,
      }; /* TODO */
    }
    if (filter?.update) {
      query = { ...query, ...filter.update };
    } else {
      query = { ...query, ...initQuery.update }; /* TODO */
    }
    return query;
  };

  /* 無限スクロール */
  const handleWaypointEnter = (props) => {
    // console.log('handleWaypointEnter');
    if (page === 0) {
      /* 初回コンポーネント読み込み時／DropDown操作時は反応させない */
      /* または全てのコンテンツを表示後は反応させない */
      return;
    }
    if (loading) {
      return;
    }
    // console.log('handleWaypointEnter Search called');
    dispatch(
      searchContent(
        path,
        { ...buildQuery(data, queryFromSelector) },
        data.block,
      ),
    );
    setLoading(true);
  };

  /* ドロップダウン選択時 */
  useEffect(() => {
    if (
      queryFromSelector &&
      data?.query?.portal_type &&
      data.query.portal_type.length > 0
    ) {
      // console.log('useEffect Search Called');
      // console.log(data, queryFromSelector);
      // console.log(buildQuery(data, queryFromSelector));
      setMergedQuery({
        ...buildQuery(data, queryFromSelector),
        fullobjects: 1,
      });
      dispatch(
        searchContent(
          path,
          { ...buildQuery(data, queryFromSelector) },
          data.block,
        ),
      );
      setBatchingNext();
      setLoading(true);
      setContentArray([]);
      setTotal(0);
      setIsLast(false);
    }
  }, [queryFromSelector]); // 反応すべき関数または変数を記述する

  /* データが戻ってきた時 */
  useEffect(() => {
    async function getResult() {
      const result = await querystringResults[data.block];
      return result;
    }
    getResult().then((result) => {
      setLoading(false);
      if (isLast || !result || !result?.loaded) {
        return;
      }
      /* データ更新 */
      setBatchingNext(result.batching.next);
      setLoading(false);
      setContentArray([...contentArray, ...result.items]);
      setPage(page + 1);
      setTotal(result.total);
      if (
        (result?.batching &&
          result.batching instanceof Object &&
          !(result.batching instanceof Array) &&
          Object.keys(result?.batching).length === 0) ||
        (result?.batching && !result.batching.next)
      ) {
        setIsLast(true);
      }
    });
  }, [querystringResults]);

  return (
    <div className="ContentBody">
      <div>
        <div className="controlWidgetWrapper">
          <div className="resultNumber">
            {intl.formatMessage(messages.result_number, {
              number: total,
            })}
          </div>
          <SelectDisplayWidget
            category={containerType}
            updator={updateQuery}
            initQueryKey={initQueryKey}
            setting={settingsByType}
            locale={locale}
          />
        </div>
        {contentArray.length > 0 && (
          <div>
            <ListingContainerWrapper
              data={data}
              listingItems={contentArray || []}
              type={containerType}
              isEditMode={isEditMode}
            />
          </div>
        )}
        <div className="loader">
          <Loader visible={loading && !isLast}></Loader>
        </div>
        {!loading && !contentArray.length && (
          <div className="listing message">
            <FormattedMessage
              id="No results found."
              defaultMessage="No results found."
            />
          </div>
        )}
      </div>
      {!isLast && (
        <Waypoint
          onEnter={(props) => {
            handleWaypointEnter(props);
          }}
          scrollableAncestor="window"
        ></Waypoint>
      )}
    </div>
  );
};

ContentBody.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  properties: PropTypes.any,
  intl: PropTypes.any,
  path: PropTypes.string,
  isEditMode: PropTypes.bool,
  research_highlight: PropTypes.any,
  locale: PropTypes.string,
};

const ContentBodyContainer = connect((state) => {
  return {
    locale: state?.content?.data?.language?.token ?? 'ja',
    research_highlight:
      state.siteMainInfo?.result?.data?.attributes?.common
        ?.research_highlight ?? {},
  };
})(ContentBody);

export default injectIntl(ContentBodyContainer);
