/**
 * settings by type. 絞り込みドロップダウンの表示とquery設定
 * filter options and query were defined by content type
 */
import moment from 'moment';

const dt_1m = moment().subtract(1, 'months').format('YYYY-MM-DDT00:00:00');

const researchSettings = [
  {
    key: 'latest',
    selector: {
      value: 'latest',
      labelJa: '新作順',
      descriptionJa: '',
      labelEn: 'Recent Articles',
      descriptionEn: '',
    },
    query: {
      add: {
        // {
        //   i: 'announcement_date',
        //   o: 'plone.app.querystring.operation.date.largerThanRelativeDate',
        //   v: 30,
        // },
        metadata_fields: [
          'announcement_date',
          'research_highlight',
          'Subject',
          'physical_path',
          'title_image_sp_size',
          'link',
        ],
      },
      update: {
        sort_on: 'announcement_date',
        sort_order: 'reverse',
      },
    },
  },
  {
    key: 'special-1m',
    selector: {
      value: 'latest',
      labelJa: '特選 ~ 一ヶ月 ~',
      descriptionJa: '直近一ヶ月間でよく読まれている記事順',
      labelEn: 'Hot Articles (Past Month)',
      descriptionEn: 'Most viewed articles in the past month',
    },
    query: {
      add: {
        // {
        //   i: 'short_term_page_view',
        //   o: 'plone.app.querystring.operation.date.largerThanRelativeDate',
        //   v: 30,
        // },
        metadata_fields: [
          'announcement_date',
          'research_highlight',
          'Subject',
          'physical_path',
          'title_image_sp_size',
          'link',
        ],
      },
      update: {
        sort_on: 'short_term_page_view',
        sort_order: 'reverse',
      },
    },
  },
  {
    key: 'special-1y',
    selector: {
      value: 'latest',
      labelJa: '特選 ~ 一年 ~',
      descriptionJa: '直近一年間でよく読まれている記事順',
      labelEn: 'Hot Articles (Past Year)',
      descriptionEn: 'Most viewed articles in the past year',
    },
    query: {
      add: {
        // {
        //   i: 'middle_term_page_view',
        //   o: 'plone.app.querystring.operation.date.largerThanRelativeDate',
        //   v: 365,
        // },
        metadata_fields: [
          'announcement_date',
          'research_highlight',
          'Subject',
          'physical_path',
          'title_image_sp_size',
          'link',
        ],
      },
      update: {
        sort_on: 'middle_term_page_view',
        sort_order: 'reverse',
      },
    },
  },
  // {
  //   key: 'life_sciences_medicine',
  //   selector: {
  //     value: 'life_sciences_medicine',
  //     labelJa: '生命科学・医療系',
  //     labelEn: 'Life Sciences & Medicine',
  //     descriptionEn: '',
  //     descriptionJa: '',
  //   },
  //   query: {
  //     add: [
  //       {
  //         i: 'research_highlight',
  //         o: 'plone.app.querystring.operation.selection.any',
  //         v: ['life_sciences_medicine'],
  //       },
  //     ],
  //     update: {
  //       sort_on: 'announcement_date',
  //       sort_order: true,
  //     },
  //   },
  // },
];
// researchSettings[0].query.add['announcement_date.query'] = dt_1m;
// researchSettings[0].query.add['announcement_date.range'] = 'min';
export { researchSettings };

export const storySettings = [
         {
           key: 'latest',
           selector: {
             value: 'latest',
             labelJa: '公開順',
             descriptionJa: '',
             labelEn: 'Latest Story',
             descriptionEn: '',
           },
           query: {
             add: {
               // {
               //   i: 'announcement_date',
               //   o: 'plone.app.querystring.operation.date.largerThanRelativeDate',
               //   v: 30,
               // },
               metadata_fields: [
                 'announcement_date',
                 'research_highlight',
                 'Subject',
                 'physical_path',
                 'title_image_sp_size',
                 'position',
                 'link',
               ],
             },
             update: {
               // sort_on: 'announcement_date',
               sort_on: 'announcement_modified_date',
               sort_order: 'reverse',
             },
           },
         },
         {
           key: 'special-1y',
           selector: {
             value: 'latest',
             labelJa: '特選  ~ 一年 ~',
             descriptionJa: '直近一年間でよく読まれている記事順',
             labelEn: 'Hot Articles (Past Year)',
             descriptionEn: 'Most viewed articles in the past year',
           },
           query: {
             add: {
               // {
               //   i: 'middle_term_page_view',
               //   o: 'plone.app.querystring.operation.date.largerThanRelativeDate',
               //   v: 365,
               // },
               metadata_fields: [
                 'announcement_date',
                 'research_highlight',
                 'Subject',
                 'physical_path',
                 'title_image_sp_size',
                 'position',
                 'link',
               ],
             },
             update: {
               sort_on: 'middle_term_page_view',
               sort_order: 'reverse',
             },
           },
         },
         {
           key: 'special-3y',
           selector: {
             value: 'special-3y',
             labelJa: '特選  ~ 三年 ~',
             descriptionJa: '直近三年間でよく読まれている記事順',
             labelEn: 'Hot Articles (Past 3 Years)',
             descriptionEn: 'Most viewed articles in the past 3 years',
           },
           query: {
             add: {
               // {
               //   i: 'long_term_page_view',
               //   o: 'plone.app.querystring.operation.date.largerThanRelativeDate',
               //   v: 1095,
               // },
               metadata_fields: [
                 'announcement_date',
                 'research_highlight',
                 'Subject',
                 'physical_path',
                 'title_image_sp_size',
                 'position',
                 'link',
               ],
             },
             update: {
               sort_on: 'long_term_page_view',
               sort_order: 'reverse',
             },
           },
         },
       ];
