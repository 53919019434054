/**
 * Select validation type form widget for the part of Edit component of TextLine, TextArea block for MailForm.
 * @module components/manage/Blocks/Mailform/EditorWidgets/ValidationWIdget
 */

import React, { useEffect, useState } from 'react';
import { get, map } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import Select from 'react-select';
import { Form, Label } from 'semantic-ui-react';

const messages = defineMessages({
  nothing: {
    id: 'Nothing',
    defaultMessage: 'Nothing',
  },
  email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
});

// const option_base = (intl) => [
//   {
//     value: 'nothing',
//     label: intl.formatMessage(messages.nothing),
//   },
// ];
// const initValue = 'nothing';
const getLabel = (value, intl) => {
  return get(
    {
      email: intl.formatMessage(messages.email),
    },
    value,
    intl.formatMessage(messages.nothing),
  );
};

/**
 * Edit validation widget class.
 * @class Edit
 * @extends Component
 */
const Validation = ({ data, onChangeBlock, block, options, intl }) => {
  // const validate_options = option_base(intl).concat(options);
  // const [value, setValue] = useState(
  //   data?.validation || { value: '', label: '' },
  // );

  // useEffect(() => {
  //   const blockValue = data.validation.value;
  //   if (value !== blockValue) {
  //     // TODO: 比較がない場合stateを何かして無限ループ
  //     onChangeBlock(block, {
  //       ...data,
  //       validation: value,
  //     });
  //   }
  // });

  const storedValue = () => {
    // valueのみ使用
    if (data?.validation?.value === '') {
      return '';
    } else if (data?.validation?.value) {
      return data.validation.value;
    } else if (data?.validation) {
      return data.validation;
    } else {
      return '';
    }
  };
  const [value, setValue] = useState(storedValue());

  const getValue = (value, intl) => ({
    label: getLabel(value, intl),
    value: value,
  });
  const getOptions = (options, intl) =>
    map(options, (value) => ({
      label: getLabel(value, intl),
      value: value,
    }));
  const setSelectedValue = (inputValue, e) => {
    if (inputValue.value !== value) {
      setValue(inputValue.value);
      onChangeBlock(block, { ...data, validation: inputValue.value });
    }
  };

  return (
    <Form.Field>
      <Label htmlFor="validation">
        <FormattedMessage id="Validation" defaultMessage="Validation" />
      </Label>
      <Select
        name="validation"
        // options={validate_options}
        // value={value || block.validation || initValue}
        // onChange={(e) => setValue(e)}
        options={getOptions(options, intl)}
        defaultValue={getValue(value, intl)}
        onChange={setSelectedValue}
      />
    </Form.Field>
  );
};

Validation.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  options: PropTypes.any,
};

export default injectIntl(Validation);
