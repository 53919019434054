/**
 * View component for definition list block.
 * @module components/manage/Blocks/DefinitionList/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import redraft from 'redraft';
import { settings } from '@plone/volto/config';
import './View.css';

/**
 * Component to display the list block.
 * @function View
 * @param {Object} data Data object.
 * @returns {string} Markup of the component.
 */
const View = ({ data }) => (
  <div className="DefinitionList">
    {data && data.definition_list && (
      <dl>
        {map(data.definition_list.items, (item) => (
          <React.Fragment key={item.key}>
            <dt>
              {item.elements[0].value &&
              item.elements[0].value.blocks &&
              item.elements[0].value.blocks[0].text
                ? redraft(
                    item.elements[0].value,
                    settings.ToHTMLRenderers,
                    settings.ToHTMLOptions,
                  )
                : '\u00A0'}
            </dt>
            <dd>
              {item.elements[1].value &&
              item.elements[1].value.blocks &&
              item.elements[1].value.blocks[0].text
                ? redraft(
                    item.elements[1].value,
                    settings.ToHTMLRenderers,
                    settings.ToHTMLOptions,
                  )
                : '\u00A0'}
            </dd>
          </React.Fragment>
        ))}
      </dl>
    )}
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
