/**
 * SeeResearchMoreButton Component
 * LanguageRootのResearch一覧用"もっと見る"ボタン
 * @module components/theme/Element/SeeMoreButton/SeeResearchMoreButton
 */

import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import '../SeeMoreButton/SeeMoreButton.css';

const messages = defineMessages({
  readMore: {
    id: 'See More',
    defaultMessage: 'See More',
  },
});

/**
 * SeeResearchMoreButton component
 * @function SeeMoreButton
 * @returns {string} Markup of the component.
 */
const SeeResearchMoreButton = ({
  intl,
  setQueryString,
  locale,
  queryString,
  subRequestKey,
  page,
  items,
}) => {
  return (
    <div className="SeeMoreButtonWrapper">
      <button
        className={page ? 'SeeMoreButton' : 'SeeMoreButton hidden'}
        type="button"
        onClick={() => {
          setQueryString(locale, queryString, subRequestKey, page, items);
        }}
      >
        {intl.formatMessage(messages.readMore)}
      </button>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SeeResearchMoreButton.propTypes = {
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
  setQueryString: PropTypes.func.isRequired,
  locale: PropTypes.string,
  queryString: PropTypes.objectOf(PropTypes.any),
  subRequestKey: PropTypes.string,
  page: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(SeeResearchMoreButton, {});
