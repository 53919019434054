import { map } from 'lodash';
import React, { createRef, useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import redraft from 'redraft';
import PropTypes from 'prop-types';
import { Button, Checkbox, Container, Form, Modal } from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Forbidden } from '@plone/volto/components';
import {
  flattenToAppURL,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';
import { blocks } from '~/config';
import { MediaQueryConfig } from '~/helpers/GlobalStyle/GlobalStyle';
import { validateFormValue } from '~/helpers/Validator/Validator';
import { clearSendMailFormStatus, sendMailForm } from '~/actions';
import { settings } from '~/config';
import { HelmetTitle, HorizontalRule } from './Common';

import './MailFormView.css';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  requiredItem: {
    id: 'requiredItem',
    defaultMessage: 'Required',
  },
  acceptPrivacyPolicy: {
    id: 'Accept Privacy Policy',
    defaultMessage: 'I accept the terms of the Privacy Policy.',
  },
  inquirySent: {
    id: 'Inquiry Sent',
    defaultMessage: 'Your inquiry has been sent.',
  },
  backToPreviousPage: {
    id: 'Back to Previous Page',
    defaultMessage: 'Back to the previous page',
  },
  submitMailForm: {
    id: 'Submit Mail Form',
    defaultMessage: 'Send',
  },
  articleTitle: {
    id: 'Article',
    defaultMessage: 'Article',
  },
  articleUrl: {
    id: 'URL',
    defaultMessage: 'URL',
  },
});

/**
 * formDataObj Object
 * MailFormView - 各Block間で情報をやりとりするためのオブジェクト
 * @param {*} id
 * @param {*} data
 * @param {*} value
 */
const formDataObj = (id, data, value = '') => ({
  id: id,
  formId: data.formId,
  label: data.label,
  placeholder: data.placeholder,
  value: value,
  required: data.requiredBlock,
  validation: data.validation || '',
  options: data.options || [],
  errorMsg: '',
});
const articleInquiryFormDataObj = (id, data) => ({
  id: id,
});

/**
 * Mailform content view component class.
 * @function ResearchFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const MailFormView = ({
  content,
  intl,
  clearMessage,
  sendMessage,
  error,
  loading,
  loaded,
  history,
  recaptchaSiteKey,
  form_url,
}) => {
  const recaptchaRef = createRef();
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const isSP = useMediaQuery({
    query: MediaQueryConfig.DEFAULT_SP,
  });
  /* ステートフック */
  const [formData, setFormData] = useState({}); // フォーム入力値の一時ストア
  const [modalOpen, setModalOpen] = useState(true); // 送信ずみmessageモーダルの開閉状態をセット
  const [agreeIps, setAgreeIps] = useState(false); // 個人情報チェックボタンのチェック状態
  const [rechaptchaStatus, setRecaptchaStatus] = useState(false); // reCAPTCHAのチェック状態
  const [isValidQuery, setIsValidQuery] = useState(null); // 2021/01/26追加 InquirySourceBlockが存在する場合にクエリを検証しtrue(正常),false(不正なクエリ)が入る
  const [redirectPath, setRedirectPath] = useState(''); // 2021/01/26追加 送信後リダイレクトのpathを保存（現在inquirySource blockのみ使用）
  const agree_IPS_sentence = content.agree_IPS_sentence?.data?.text ? (
    redraft(
      content.agree_IPS_sentence.data.text,
      settings.ToHTMLRenderers,
      settings.ToHTMLOptions,
    )
  ) : (
    <br />
  );
  useEffect(() => {
    const initFormData = () => {
      let formDataObjs = {};
      const blocksAttr = content[blocksFieldname];
      Object.keys(blocksAttr).forEach(function (key) {
        const data = blocksAttr[key];
        if (
          [
            'mailform_textline',
            'mailform_textarea',
            'mailform_select',
          ].includes(data['@type'])
        ) {
          const formData = formDataObj(key, data);
          formDataObjs = {
            ...formDataObjs,
            [key]: formData,
          };
        }
      });
      return formDataObjs;
    };
    setFormData((prevFormData) => {
      return { ...prevFormData, ...initFormData() };
    });
  }, [blocksFieldname, content]);

  /**
   * handleInputData
   * フォーム入力値をstateに保存する（データ入力ごとにonChange様にコールされる）
   * @param {*} data
   */
  const handleInputData = (data) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [data.id]: { ...prevFormData[data.id], value: data.value },
      };
    });
  };

  /**
   * handleInitData
   * 上記handleInputDataのformData初期化版（現在のところinquirySourceBlockのみで使用）
   * BlockよりinitFormDataの生成が可能
   * @param {*} data
   */
  const handleInitFormData = (data) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [data.id]: data,
      };
    });
  };

  /**
   * handleIsValidQuery
   * @param {boolean} isValid
   * 2021/01/26追加 InquirySourceBlockのクエリ検証結果をセットするメソッド
   */
  const handleIsValidQuery = (isValid) => {
    if (isValid !== isValidQuery) {
      setIsValidQuery(isValid);
    }
  };

  /**
   * handleRedirectPath
   * @param {string} path
   * 2021/01/26追加 送信後リダイレクトするpathをセットする(現在InquirySourceBlockのみ使用)
   */
  const handleRedirectPath = (path) => {
    if (path !== redirectPath) {
      setRedirectPath(path);
    }
  };

  const handleBlur = (data) => {
    setFormData((formData) => ({
      ...formData,
      [data.id]: {
        ...formData[data.id],
        errorMsg:
          validateFormValue(
            data.value,
            data.label,
            data.required,
            data.validation,
            intl.locale,
          ) || '',
      },
    }));
  };

  const handleChecked = (event, data) => {
    setFormData({
      ...formData,
      [data.id]: {
        label: data.label,
        value: data.checked,
        readableValue: data.checked ? 'Yes' : 'No',
      },
    });
    setAgreeIps(data.checked);
  };

  /**
   * handleCompleteCaptcha
   * reCAPTCHAをpassした時コールされる関数
   * @param {*} value pass時に返される値
   */
  function handleCompleteCaptcha(value) {
    setFormData({ ...formData, captchaValue: value });
    setRecaptchaStatus(true);
  }

  const handleSend = (e) => {
    let hasError = false;
    let checkedFormData = {};
    for (let key in formData) {
      if (key !== 'captchaValue') {
        const data = formData[key];
        const errorMsg =
          validateFormValue(
            data.value,
            data.label,
            data.required,
            data.validation,
            intl.locale,
          ) || '';
        if (errorMsg) {
          hasError = true;
        }
        checkedFormData = {
          ...checkedFormData,
          [key]: { ...data, errorMsg: errorMsg },
        };
      } else {
        checkedFormData = {
          ...checkedFormData,
          [key]: formData[key],
        };
      }
    }
    setFormData(checkedFormData);
    if (!hasError) {
      sendMessage({ url: form_url, ...formData });
    } else {
      e.preventDefault();
    }
  };

  const closeModal = () => {
    recaptchaRef.current.props.grecaptcha.reset();
    setModalOpen(false);
    // 入力フォームクリア
    let clearedFormData = {};
    for (let key in formData) {
      const data = formData[key];
      clearedFormData = {
        ...clearedFormData,
        [key]: { ...data, value: '' },
      };
    }
    clearMessage();
    setFormData(clearedFormData);
    setAgreeIps(false);
    setRecaptchaStatus(false);
    if (isValidQuery) {
      history.push(redirectPath);
    } else if (content.redirect_url) {
      history.push(content.redirect_url);
    } else {
      history.push(flattenToAppURL(content['@id']));
    }
  };

  return isValidQuery === false ? ( // 不正なクエリの場合
    <Forbidden />
  ) : (
    <div className="MailFormView">
      <HelmetTitle pageTitle={content.title} />
      <Container className="view-wrapper">
        <div className="mailFormHeader">
          <HorizontalRule className="HorizontalRule" />
          <h2 className="title">{content.title}</h2>
        </div>
        <div className="mailFormBody">
          <Form method="post" onSubmit={handleSend}>
            <div className="RequiredGuide">
              <FormattedMessage
                id="Required Guide"
                defaultMessage="* Required"
              />
            </div>

            {map(content[blocksLayoutFieldname].items, (block) => {
              const blockType =
                blocks.blocksConfig[
                  content[blocksFieldname]?.[block]?.['@type']
                ];
              const isTitle = blockType?.['id'] === 'title';
              if (isTitle) {
                return <></>;
              }
              const Block = blockType?.['view'] || null;
              return Block !== null ? (
                <Block
                  key={block}
                  id={block}
                  data={content[blocksFieldname][block]}
                  formData={formData[block]}
                  handleInputData={handleInputData}
                  handleBlur={handleBlur}
                  handleInitFormData={handleInitFormData}
                  handleIsValidQuery={handleIsValidQuery}
                  handleRedirectPath={handleRedirectPath}
                />
              ) : (
                <div key={block}>
                  {intl.formatMessage(messages.unknownBlock, {
                    block: content[blocksFieldname]?.[block]?.['@type'],
                  })}
                </div>
              );
            })}
            <div className="separatorForSP">
              <hr></hr>
            </div>
            <div
              className="agreeIpsSentence"
              dangerouslySetInnerHTML={{
                __html: content?.agree_IPS_sentence?.data,
              }}
            />
            <Checkbox
              id="agreeIps"
              checked={agreeIps}
              className="agreeIps"
              label={intl.formatMessage(messages.acceptPrivacyPolicy)}
              onChange={handleChecked}
            />
            <div className="rcpc">
              <div className="rcpcView">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={recaptchaSiteKey || ''}
                  onChange={handleCompleteCaptcha}
                  hl={intl.locale === 'ja' ? 'ja' : 'en'}
                />
              </div>
            </div>
            <div className="ButtonContainer">
              <Button
                className="PrimaryButton"
                disabled={!(agreeIps && rechaptchaStatus)}
              >
                <FormattedMessage id="Send" defaultMessage="Send" />
              </Button>
            </div>
          </Form>
        </div>
      </Container>
      <Modal
        className="MailSentInfoModal"
        open={loaded}
        onClose={closeModal}
        centered={false}
        closeIcon
      >
        <div className="modalContent">
          <h2>{intl.formatMessage(messages.inquirySent)}</h2>
          <button onClick={closeModal}>
            {intl.formatMessage(messages.backToPreviousPage)}
          </button>
        </div>
      </Modal>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
MailFormView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    agree_IPS_sentence: PropTypes.object,
  }).isRequired,
};

const MailFormViewContainer = connect(
  (state, props) => {
    return {
      recaptchaSiteKey:
        state?.siteMainInfo?.result?.data?.attributes?.common?.recapture
          ?.site_key,
      error: state.sendMailForm?.error,
      loading: state.sendMailForm?.loading,
      loaded: state.sendMailForm?.loaded,
      form_url: props?.content?.['@id']
        ? props.content['@id'].replace('/api', '')
        : '',
    };
  },
  (dispatch, props) => ({
    sendMessage: (formData) => {
      const path = props?.location?.pathname;
      dispatch(sendMailForm(path, formData));
    },
    clearMessage: () => {
      dispatch(clearSendMailFormStatus());
    },
  }),
)(MailFormView);

export default injectIntl(MailFormViewContainer);
