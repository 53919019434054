/**
 * PublisherBox component.
 * @module components/theme/Site/PublisherBox/PublisherBox
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import './PublisherBox.css';

/**
 * Component to display the PublisherBox
 * @function PublisherBox
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const PublisherBox = () => {
  const [src, alt, publisher, desc] = useSelector((state) => {
    const locale = state?.content?.data?.language?.token ?? 'ja';
    const publisherBox =
      state.siteMainInfo?.result?.data?.attributes?.locale[locale]
        ?.publisher_box;
    return [
      publisherBox?.src || '',
      publisherBox?.alt || '',
      publisherBox?.publisher || '',
      publisherBox?.desc || '',
    ];
  });
  return (
    (src || publisher || desc) && (
      <aside className="PublisherBox">
        <hr />
        <div className="publisherBoxInner">
          {src && (
            <div className="leftSide">
              <img src={flattenToAppURL(src)} alt={alt} />
            </div>
          )}
          {(publisher || desc) && (
            <div className="rightSide">
              {publisher && <div className="publisher">{publisher}</div>}
              {desc && <div className="desc">{desc}</div>}
            </div>
          )}
        </div>
      </aside>
    )
  );
};

export default injectIntl(PublisherBox);
