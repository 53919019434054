/**
 * TagListView view component.
 * @module components/theme/View/OuFolderView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { tagList } from '../../../actions/tagList/tagList';
import _ from 'lodash';
import { HelmetTitle, HorizontalRule } from './Common';
import './TagListView.css';

/**
 * OuFolderView view component class.
 * @function TagListView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const TagListView = ({ content, tagList, loadContent, locale }) => {
  /* ContainerのloadContent呼び出す */
  React.useEffect(() => {
    loadContent(locale);
  }, []);

  return (
    <div className="TagListView">
      <HelmetTitle pageTitle="Tags" />
      <div className="tagListHeader">
        <HorizontalRule className="hr" />
        <h2 className="title">
          <FormattedMessage id="Tags" defaultMessage="Tags" />
        </h2>
      </div>
      <div className="tagListBody">
        <div className="tagListBodyInner">
          <div>
            <span>2019.11.06〜2019.12.06</span>&nbsp;
            <FormattedMessage
              id="Sorted By Popularity"
              defaultMessage="Sorted by popularity"
            />
          </div>
          <div className="tagList">
            {_.map(tagList, (item) => {
              return (
                <Link to={`/search-tag?Subject=${item?.title}`}>
                  <div className="tag" key={item?.title}>
                    <span className="tagName">{item?.title}</span>
                    <span className="tagCount">{item?.count}</span>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
TagListView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        review_state: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }).isRequired,
};

//export default TagListView;

const TagListViewContainer = connect(
  (state, props) => {
    return {
      tagList: state?.tagList?.result?.data?.attributes?.data,
      locale: state?.content?.data?.language?.token ?? 'ja',
    };
  },
  (dispatch, props) => ({
    loadContent: (locale) => {
      dispatch(tagList(locale));
    },
  }),
)(TagListView);

export { TagListViewContainer };

export default injectIntl(TagListViewContainer);
