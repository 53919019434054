/**
 * RestrictedListing View component
 * @module component/manageBlocks/RestictedListing/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import ContentBody from './ContentBody';

/**
 * View functional component
 * @function View
 * @param {Object} data property data.
 * @param {Object} properties component properties.
 * @returns {string} Markup of the component.
 */
const View = ({ data, properties }) => {
  return (
    <div className="block listing">
      <ContentBody data={data} properties={properties} isEditMode={false} />
    </div>
  );
};

View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
