import React, { Component } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import {
  MediaQueryConfig,
  StyleConfig,
} from '../../../../helpers/GlobalStyle/GlobalStyle';
import { connect } from 'react-redux';
import { Icon } from '../../../index';
import { Link } from 'react-router-dom';
import './SearchWidgetWordList.css';

// ------------------------------- Carousel

/**
 * International 用
 */
const messages = defineMessages({});

/**
 * スライダー : 次矢印
 */
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className="arrowWrapperNext">
      <button className="arrowButton" onClick={onClick}>
        <Icon icon="angle right" size={StyleConfig.carouselIconSize} />
      </button>
    </div>
  );
};

/**
 * スライダー : 前矢印
 * @param {*} props
 */
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className="arrowWrapperPrev">
      <button className="arrowButton" onClick={onClick}>
        <Icon icon="angle left" size={StyleConfig.carouselIconSize} />
      </button>
    </div>
  );
};

/**
 * スライダーアイテム
 * @param {}} props
 */
const CarouselItem = (props) => {
  return (
    <button
      onClick={(event) => {
        props.doSearch(props.item);
        event.preventDefault();
      }}
      className="carouselItemWrapper"
    >
      <span className="title">{props.item}</span>
    </button>
  );
};

/**
 * 標準のスライダー
 */
class SearchWidgetWordList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: '',
    };
  }

  static propTypes = {
    /** 表示用データ
     * TODO: あとで正確な型を決めてもいい
     */
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.string),
    token: PropTypes.string,
  };

  static defaultProps = {
    title: '',
    data: [],
    token: null,
  };

  componentDidMount() {}

  componentDidUpdate() {
    if (this.props.locale !== this.state.locale) {
      this.setState({ locale: this.props.locale });
    }
  }

  render() {
    const settings = {
      accessibility: true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      variableWidth: true,
      centerMode: false,
      arrow: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      className: this.props.token
        ? 'customSlider customSliderLogined'
        : 'customSlider',
    };
    // サイズが1のときにおかしな挙動になるため
    const data =
      _.size(this.props.data) === 1
        ? [this.props.data[0], this.props.data[0]]
        : this.props.data;
    return (
      <div className="SearchWidgetWordList">
        <h3
          className={`${
            this.props.token ? 'sliderTitle loggedin' : 'sliderTitle'
          }`}
        >
          {this.props.title}
        </h3>
        <Slider {...settings}>
          {_.map(data, (item) => {
            return (
              <CarouselItem
                item={item}
                key={item}
                history={this.props.history}
                doSearch={this.props.doSearch}
              />
            );
          })}
        </Slider>
      </div>
    );
  }
}

const SearchWidgetWordListContainer = connect(
  (state, props) => ({
    token: state.userSession?.token,
    locale: state?.content?.data?.language?.token ?? 'ja',
  }),
  (dispatch, props) => ({}),
)(SearchWidgetWordList);

export default injectIntl(SearchWidgetWordListContainer, {});
