import { GET_ITEM_LIST_RELATEDTO_TEACHER } from '../../constants/ActionTypes';

export function itemListRelatedtoTeacher(apiPath) {
  const safeApiPath = apiPath || 'ja';
  return {
    type: GET_ITEM_LIST_RELATEDTO_TEACHER,
    request: {
      op: 'get',
      path: `/${safeApiPath}/@item-list-related-teacher`,
    },
  };
}
