/**
 * View link button block component.
 * @module components/manage/Blocks/MailForm/LinkButtonBlock/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './View.css';

/**
 * Link button sub component
 * @param {Object} data properties data.
 * @returns {string} Markup of the component.
 */
const LinkButton = (props) => {
  return (
    <button style={{ background: props.data.buttonColor }}>
      {props.data.label}
    </button>
  );
};

/**
 * View link button block functional component.
 * @function View
 * @param {Object} data properties data.
 * @param {Object} properties block properties.
 * @returns {string} Markup of the component.
 */
const View = ({ data, properties }) => {
  return (
    <div className="LinkButtonBlock">
      {data.link.startsWith('http://') || data.link.startsWith('https://') ? (
        <a href={data.link}>
          <LinkButton data={data} />
        </a>
      ) : (
        <Link to={data.link}>
          <LinkButton data={data} />
        </Link>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
