import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { SidebarPortal } from '@plone/volto/components';
import ContentBody from './ContentBody';
import ContentSidebar from './ContentSidebar';
import { getBaseUrl } from '@plone/volto/helpers';

const Edit = ({
  data,
  onChangeBlock,
  block,
  selected,
  properties,
  pathname,
}) => {
  return (
    <>
      {data?.query?.length === 0 && (
        <FormattedMessage id="Contained items" defaultMessage="Contained items">
          {(message) => <p className="items-preview">{message}</p>}
        </FormattedMessage>
      )}
      {data?.query?.length > 0 && (
        <FormattedMessage id="Results preview" defaultMessage="Results preview">
          {(message) => <p className="items-preview">{message}</p>}
        </FormattedMessage>
      )}
      <ContentBody
        data={data}
        properties={properties}
        block={block}
        path={getBaseUrl(pathname)}
        isEditMode={true}
      />
      <SidebarPortal selected={selected}>
        <ContentSidebar
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </>
  );
};

Edit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  block: PropTypes.string.isRequired,
  onSelectBlock: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.any),
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
  pathname: PropTypes.string.isRequired,
};

export default injectIntl(Edit);
