/**
 * Slider component showing Feature articles
 * 最新の特集を一覧表示するスライダー
 * @module components/theme/Site/Slider/FeatureList
 */

import React, { Component } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import {
  MediaQueryConfig,
  StyleConfig,
} from '../../../../helpers/GlobalStyle/GlobalStyle';
import { connect } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import { featureFolderList } from '../../../../actions/featureFolderList/featureFolderList';
import { ExternalLink, Icon } from '../../../index';
import { Link } from 'react-router-dom';
import './FeatureList.css';
import { LabelFeature } from '../../View/Common';

/**
 * スライダー : 次矢印
 */
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className="arrowWrapperNext">
      <button className="arrowButton" onClick={onClick}>
        <Icon icon="angle right" size={StyleConfig.carouselIconSize} />
      </button>
    </div>
  );
};

/**
 * スライダー : 前矢印
 * @param {*} props
 */
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className="arrowWrapperPrev">
      <button className="arrowButton" onClick={onClick}>
        <Icon icon="angle left" size={StyleConfig.carouselIconSize} />
      </button>
    </div>
  );
};

/**
 * セクション区切り
 * @param {*} props
 */
const SliderDivider = (props) => {
  return (
    <div className="sliderDivider">
      <hr />
    </div>
  );
};

/**
 * 各スライダーアイテムのタイトル
 * @param {*} props
 */
const Title = (props) => {
  return (
    <h3 className="title">
      <span className="labelWrapper">
        <LabelFeature />
      </span>
      <span className="titleWrapper">{props.title}</span>
    </h3>
  );
};

/**
 * スライダーアイテム
 * @param {}} props
 */
const CarouselItem = (props) => {
  const isPC = useMediaQuery({
    query: MediaQueryConfig.PC,
  });
  const redirect = props.item.redirect;
  const url = props.item.url;
  return (
    <Link
      className="carouselItemWrapper"
      to={{ pathname: flattenToAppURL(redirect ? redirect : url) }}
      target={redirect ? '_blank' : ''}
    >
      <div className="inner">
        {!isPC && (
          <div className="words">
            <Title title={props.item.title} />
          </div>
        )}
        <img
          alt=""
          className="img"
          src={flattenToAppURL(
            !isPC ? props.item?.image_sp ?? '' : props.item?.image_pc ?? '',
          )}
        />
        <div className="words">
          {isPC && <Title title={props.item.title} />}
          <h4 className="subTitle">{props.item.subtitle}</h4>
          <p>{props.item.lead_sentence}</p>
        </div>
        <ExternalLink link={url} redirect={redirect} />
      </div>
    </Link>
  );
};

/**
 * SectionTitle component 特集スライダーエリアのタイトル
 * @function components/theme/Site/Slider/FeatureList
 * @param prop
 */
const SectionTitle = (props) => {
  const isPC = useMediaQuery({
    query: MediaQueryConfig.PC,
  });
  return isPC ? (
    <h2 className="sliderTitle">{props.pcTitle}</h2>
  ) : (
    <h2 className="sliderTitle">
      {props.spTitle1}
      <br />
      {props.spTitle2}
    </h2>
  );
};

/**
 * SP/PC了対応スライダー
 * @param {*} props
 */
const SectionSlider = (props) => {
  const isPC = useMediaQuery({
    query: MediaQueryConfig.PC,
  });

  /**
   * PC用スライダー設定
   */
  const settingsPc = {
    accessibility: true,
    adaptiveHeight: true,
    dots: false,
    infinite: false,
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 769,
        settings: {
          centerMode: false,
        },
      },
    ],
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    variableWidth: true,
    centerMode: true,
    // centerPadding: '20px',
    arrow: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: props.token
      ? 'customSlider customSliderLogined'
      : 'customSlider',
  };

  /**
   * SmartPhone用スライダー設定
   */
  const settingsSp = {
    accessibility: true,
    adaptiveHeight: true,
    dots: false,
    infinite: false,
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 769,
        settings: {
          centerMode: false,
        },
      },
    ],
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    variableWidth: false,
    centerMode: false,
    // centerPadding: '20px',
    arrow: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: props.token
      ? 'customSlider customSliderLogined'
      : 'customSlider',
  };

  return isPC ? (
    <Slider {...settingsPc}>
      {_.map(props.data, (item) => {
        return <CarouselItem item={item} key={item.title} />;
      })}
    </Slider>
  ) : (
    <Slider {...settingsSp}>
      {_.map(props.data, (item) => {
        return <CarouselItem item={item} key={item.title} />;
      })}
    </Slider>
  );
};

/**
 * 標準のスライダー
 */
class DefaultSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: '',
    };
  }

  static propTypes = {
    /** 表示用データ
     * TODO: あとで正確な型を決めてもいい
     */
    title: PropTypes.string,
    description: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    token: PropTypes.string,
  };

  static defaultProps = {
    title: '',
    description: '',
    data: [],
    token: null,
  };

  componentDidMount() {
    this.props.loadContent(this.props.locale);
  }

  componentDidUpdate() {
    if (this.props.locale !== this.state.locale) {
      this.setState({ locale: this.props.locale });
      this.props.loadContent(this.props.locale);
    }
  }

  render() {
    // サイズが1のときにおかしな挙動になるため
    const data = this.props.data;
    return (
      <>
        {data.length ? (
          <div className="FeacherListSlider DefaultSlider">
            <SliderDivider></SliderDivider>
            <SectionTitle
              pcTitle={this.props.pcTitle}
              spTitle1={this.props.spTitle1}
              spTitle2={this.props.spTitle2}
            ></SectionTitle>
            <SectionSlider data={data} token={this.props.token}></SectionSlider>
          </div>
        ) : null}
      </>
    );
  }
}

/**
 * 特集記事の一覧を出力するコンポーネント
 */
const FeatureListContainer = connect(
  (state, props) => ({
    pcTitle: state.featureFolderList?.result?.data?.attributes?.title,
    spTitle1: state.featureFolderList?.result?.data?.attributes?.title_sp1,
    spTitle2: state.featureFolderList?.result?.data?.attributes?.title_sp2,
    description: state.featureFolderList?.result?.data?.attributes?.description,
    data: state.featureFolderList?.result?.data?.attributes?.data,
    token: state.userSession?.token,
    locale: state?.content?.data?.language?.token ?? 'ja',
  }),
  (dispatch, props) => ({
    loadContent: (locale) => {
      dispatch(featureFolderList(locale));
    },
  }),
)(DefaultSlider);

export default injectIntl(FeatureListContainer, {});
