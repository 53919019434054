import { GET_RESOU_GRAPH_DATA } from '../../constants/ActionTypes';

/**
 * グラフデータの取得
 */
export function getResouGraphData(locale) {
  return {
    type: GET_RESOU_GRAPH_DATA,
    request: {
      op: 'get',
      path: `/${locale || 'ja'}/@resou-graph-data`,
    },
  };
}
