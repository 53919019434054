/**
 * Feature view component.
 * @module components/theme/View/FeatureView
 */

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Masonry from 'react-masonry-css';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  DefaultBody,
  HelmetTitle,
  HorizontalRule,
  LabelFeature,
} from './Common';
import PastFeatureCollection from '../Site/Collection/PastFeatureCollection';
import { ExternalLink, PublisherBox } from '../../index';
import { featureList } from '../../../actions/';
import { BrowsingHistorySaver } from '../../../components/index';
import { MediaQueryConfig } from '../../../helpers/GlobalStyle/GlobalStyle';
import './FeatureView.css';

const getCoverYearMonth = (locale, dt) => {
  if (dt !== null) {
    const event = new Date(dt);
    if (locale === 'ja') {
      return event.toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        //weekday: 'long',
      });
    } else {
      return event.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        //weekday: 'long',
      });
    }
  }
};

/**
 * Component to display the default view.
 * @function DefaultView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const FeatureView = ({ content, loadContent, locale, featureList }) => {
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });

  const breakpointSubItems = {
    default: 4,
    1200: 3,
    1000: 2,
  };

  const headerImage = (content) => {
    return isSP
      ? flattenToAppURL(
          content?.title_image_sp
            ? content?.title_image_sp?.scales?.large?.download ?? ''
            : '/assets/images/nowprinting_580_280.jpg',
        )
      : flattenToAppURL(
          content?.title_image_pc
            ? content?.title_image_pc?.scales?.large?.download ?? ''
            : '/assets/images/nowprinting_1160_560.jpg',
        );
  };

  useEffect(() => {
    loadContent(locale, _.last(content.parent['@id'].split('/')));
  }, []);

  return (
    <div className="FeatureView">
      <HelmetTitle pageTitle={content.title} />
      {/* Header */}
      <header className="featureHeader">
        <img
          alt={content.title}
          className="headerImage"
          src={headerImage(content)}
        />
      </header>
      {/* Body */}
      <section className="featureBody">
        <div className="label">
          <LabelFeature />
          <Link
            className="parentTitle"
            to={flattenToAppURL(content?.parent['@id'])}
          >
            {content?.parent?.title}
          </Link>
        </div>
        <div className="featureBodyInner">
          <h2 className="title">{content.title}</h2>
          <HorizontalRule className="hr" />
          <DefaultBody content={content} />
          <div className="announcement_date">
            {getCoverYearMonth(locale, content?.announcement_date)}
          </div>
          {featureList?.length > 0 && (
            <Masonry
              breakpointCols={breakpointSubItems}
              className="sub-items"
              columnClassName=""
            >
              {featureList.map((item, index) => {
                const url = item?.url;
                const redirect = item?.redirect;
                return (
                  <div className="sub-item" key={index}>
                    <Link
                      className="sub-item-link"
                      to={{
                        pathname: redirect ? redirect : flattenToAppURL(url),
                      }}
                      target={redirect ? '_blank' : ''}
                    >
                      <div className="sub-item-image">
                        <img src={item?.image} alt="" />
                      </div>
                      <h3>
                        {item?.title}
                        <ExternalLink link={url} redirect={redirect} />
                      </h3>
                    </Link>
                  </div>
                );
              })}
            </Masonry>
          )}
          <PublisherBox />
        </div>
      </section>
      <PastFeatureCollection />
      <BrowsingHistorySaver />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
FeatureView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

const FeatureViewContainer = connect(
  (state, props) => {
    return {
      featureList: state?.featureList?.result?.data?.attributes?.data,
      locale: state?.content?.data?.language?.token ?? 'ja',
    };
  },
  (dispatch, props) => {
    return {
      loadContent: (locale, cid) => {
        dispatch(featureList(locale, cid));
      },
    };
  },
)(FeatureView);

export default injectIntl(FeatureViewContainer);
