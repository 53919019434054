import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import reducers from '~/reducers';
import { api, crashReporter } from '@plone/volto/middleware';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

if (__CLIENT__) {
  ReactGA.initialize('UA-9055195-2', {
    debug: false,
    gaOptions: {
      anonymizeIp: true,
    },
  });
  // GA4 設定 https://www.npmjs.com/package/react-ga4
  ReactGA4.initialize('G-F819M6XDZC');
}

const gaTrackingMiddleware = (store) => (next) => (action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const pathName = window.location.pathname;
    trackPage(pathName);
  }
  return next(action);
};

const trackPage = (pathName) => {
  // ReactGA.ga('send', 'pageview', pathName);
  ReactGA.pageview(pathName);
  // GA4 対応
  ReactGA4.send('pageview');
};

const configureStore = (initialState, history, apiHelper) => {
  const middlewares = composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      crashReporter,
      thunk,
      api(apiHelper),
      gaTrackingMiddleware,
    ),
  );
  const store = createStore(
    combineReducers({
      router: connectRouter(history),
      ...reducers,
    }),
    initialState,
    middlewares,
  );

  return store;
};

export default configureStore;
