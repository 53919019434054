import _ from 'lodash';
import { CHANGE_VISIBLE_RESEARCH_CONTENT_FULL } from '../../constants/ActionTypes';

const initialState = {
  visible: false,
};

/**
 * Research View Reducer
 * @param {*} state
 * @param {*} action
 */
export default function researchView(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_VISIBLE_RESEARCH_CONTENT_FULL:
      return {
        ...state,
        visible: action.visible === undefined ? !state.visible : action.visible,
      };
    default:
      return state;
  }
}
