import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { Icon } from '../../../';
import {
  MediaQueryConfig,
  StyleConfig,
} from '../../../../helpers/GlobalStyle/GlobalStyle';
import './TeacherCommentButton.css';
import { connect } from 'react-redux';

const messages = defineMessages({
  close: {
    id: 'Close!',
    defaultMessage: 'Close',
  },
  tellMeMore: {
    id: 'Tell me more!',
    defaultMessage: 'Tell me more!',
  },
  researcherTellMeMore: {
    id: 'Researcher tell me more',
    defaultMessage: 'Tell me more!',
  },
  researcherTellMeMore2: {
    id: 'Researcher tell me more2',
    defaultMessage: '',
  },
});

export const TellMeMoreComponent = ({ props }) => {
  return (
    <button
      className={`TeacherCommentButton TellMeMoreComponent ${
        props.visibleRightBar ? 'on' : null
      }`}
      onClick={() => {
        props.setVisibleRightBar();
      }}
    >
      <div className="commentIcon">
        <Icon icon="comment alternate" color={'orange'} size="40px"></Icon>
      </div>
      <div className="researcherTellMeMore">
        <div>{props.intl.formatMessage(messages.researcherTellMeMore)}</div>
        {props.lang === 'ja' && (
          <div>{props.intl.formatMessage(messages.researcherTellMeMore2)}</div>
        )}
      </div>
      <div className="angleRightIcon">
        <Icon icon="angle right" color={'orange'} size="28px"></Icon>
      </div>
    </button>
  );
};

const TeacherCommentButton = (props) => {
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  return (
    <>
      {props?.teacherName ? (
        isSP ? (
          props.visibleRightBar ? (
            <button
              className={`TeacherCommentButton ${
                props.visibleRightBar ? 'on' : null
              }`}
              onClick={() => {
                props.setVisibleRightBar();
              }}
            >
              <Icon
                icon="close"
                size={StyleConfig.menuIconSizeSmall}
                color={StyleConfig.iconColorLightUniversity}
              ></Icon>
              {props.intl.formatMessage(messages.close)}
            </button>
          ) : (
            <button
              className={`TeacherCommentButton ${
                props.visibleRightBar ? 'on' : null
              }`}
              onClick={() => {
                props.setVisibleRightBar();
              }}
            >
              <Icon
                icon="smile outline"
                size={StyleConfig.menuIconSizeSmall}
                color={StyleConfig.iconColorDark}
              ></Icon>{' '}
              {props.intl.formatMessage(messages.tellMeMore)}
            </button>
          )
        ) : (
          <>
            <TellMeMoreComponent props={props} />
          </>
        )
      ) : null}
    </>
  );
};

TeacherCommentButton.prototype = {
  setVisibleRightBar: PropTypes.func,
  visibleRightBar: PropTypes.bool,
  teacherName: PropTypes.string,
};

/**
 * コンテナ
 */
const TeacherCommentButtonContainer = connect(
  (state, props) => {
    return {
      teacherName: state?.content?.data?.teacher_name,
      lang: state?.content?.data?.language?.token ?? 'ja',
    };
  },
  (dispatch, props) => {
    return {};
  },
)(TeacherCommentButton);

export default injectIntl(TeacherCommentButtonContainer, {});
