import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose';
import { Link } from 'react-router-dom';
import { ExternalLink } from '../../../index';
import './StoryContainer.css';

const ResearchContainer = ({ content, isEditMode }) => {
  const url = content['@id'] ?? '';
  const redirect = content.link ?? '';
  return (
    <div className="StoryContainer">
      <Link
        className="storyContainerHeader"
        to={{ pathname: redirect ? redirect : flattenToAppURL(url) }}
        target={redirect ? '_blank' : ''}
        condition={!isEditMode}
      >
        {/* TODO: SP対応 */}

        {/*!content.title_image_sp && <img src={DefaultImageSVG} alt="" />*/}

        {/* {content.title_image_sp ? ( */}
        {content.title_image_sp_size ? (
          <img
            src={flattenToAppURL(
              // content?.title_image_sp?.scales?.storyz_thumb?.download ?? '',
              `${content.physical_path}/@@images/title_image_sp/storyz_thumb`,
            )}
            alt={content.title}
          />
        ) : (
          <img
            src={flattenToAppURL('/assets/images/nowprinting_580_280.jpg')}
            alt={content.title}
          />
        )}
      </Link>
      <div className="storyContainerBody">
        <Link
          to={{ pathname: redirect ? redirect : flattenToAppURL(url) }}
          target={redirect ? '_blank' : ''}
          condition={!isEditMode}
        >
          <h3 className="title">
            <LinesEllipsisLoose
              text={content.title}
              maxLine="2"
              ellipsis="..."
              lineHeight="24"
            />
          </h3>
        </Link>
        <LinesEllipsisLoose
          text={content.position ? content.position : ''}
          maxLine="2"
          ellipsis="..."
          lineHeight="20"
          className="position"
        />
        <hr className="hr" />
        <LinesEllipsisLoose
          text={content.description ? content.description : ''}
          maxLine="4"
          ellipsis="..."
          lineHeight="24"
          className="customLinesEllipsisLoose description"
        />
      </div>
      <ExternalLink link={url} redirect={redirect} />
    </div>
  );
};

ResearchContainer.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired, // TODO: 詳細追記
  isEditMode: PropTypes.bool,
};

export default ResearchContainer;
