/**
 * Component to display the ExternalLink
 * @function ExternalLink
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon as SemanticIcon } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import './ExternalLink.css';

const ExternalLink = ({ link, redirect, token }) => {
  return redirect ? (
    <div className="ExternalLink">
      <SemanticIcon name="external" />
      {token && (
        <Link to={flattenToAppURL(link)}>
          <SemanticIcon name="edit" />
        </Link>
      )}
    </div>
  ) : null;
};

export default connect((state) => {
  return {
    token: state.userSession.token,
  };
})(ExternalLink);

//export default injectIntl(ExternalLink);
