/**
 * Browsing history slider
 * 閲覧履歴を表示するスライダー
 * @module components/theme/Site/Slider/BrowsingHistoryList
 */
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { CookieSetting } from '../../../../constants/Setting';
import { browsingHistoryList } from '../../../../actions/browsingHistoryList/browsingHistoryList';
import DefaultSlider from './GenericList';

const messages = defineMessages({
  browsingHistory: {
    id: 'Viewing History',
    defaultMessage: 'Viewing History',
  },
});

/**
 * Container function component to connect Redux state, Action and child component.
 * GenericList componentとReduxを接続するコンテナComponent
 * @param {function} connect the function to connect to redux and component.
 * @returns {undefined}
 */
const BrowsingHistoryListContainer = connect(
  (state, props) => ({
    title: props.intl.formatMessage(messages.browsingHistory),
    description:
      state.browsingHistoryList?.result?.data?.attributes?.description,
    data: state.browsingHistoryList?.result?.data?.attributes?.data,
    token: state.userSession?.token,
    intl: state.intl,
    locale: state?.content?.data?.language?.token ?? 'ja',
    baseApiUrl: '',
  }),
  (dispatch, props) => ({
    loadContent: (apiPath) => {
      const uuids = cookie.load(CookieSetting.VIEW_HISTORIES.name);
      dispatch(browsingHistoryList(uuids));
    },
  }),
)(DefaultSlider);

export default injectIntl(BrowsingHistoryListContainer, {});
