import { GET_STORYZ_LIST } from '../../constants/ActionTypes';

export function storyzList(locale) {
  return {
    type: GET_STORYZ_LIST,
    request: {
      op: 'get',
      path: `/${locale}/story/@storyz-list`,
    },
  };
}
