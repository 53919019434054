/**
 * Edit textline form block component for MailForm.
 * @module components/manage/Blocks/MailForm/TextLineBlock/Edit
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';

import FormIDWidget from '../Common/FormIDWidget';
import LabelWidget from '../Common/LabelWidget';
import ValidationWidget from '../Common/ValidationWidget';
import PlaceholderWidget from '../Common/PlaceholderWidget';
import RequiredWidget from '../Common/RequiredWidget';

const validate_options = [
  // { value: 'nothing', label: 'Nothing' },
  // { value: 'email', label: 'Email' },
  '',
  'email',
];

/**
 * Edit text block class.
 * @class Edit
 * @extends Component
 */
const Edit = ({ data, onChangeBlock, block }) => {
  return (
    <Form>
      <Form.Field>
        <FormIDWidget data={data} onChangeBlock={onChangeBlock} block={block} />
      </Form.Field>
      <Form.Field>
        <LabelWidget data={data} onChangeBlock={onChangeBlock} block={block} />
      </Form.Field>
      <Form.Field>
        <RequiredWidget
          data={data}
          onChangeBlock={onChangeBlock}
          block={block}
        />
      </Form.Field>
      <Form.Field>
        <PlaceholderWidget
          data={data}
          onChangeBlock={onChangeBlock}
          block={block}
        />
      </Form.Field>
      <Form.Field>
        <ValidationWidget
          data={data}
          onChangeBlock={onChangeBlock}
          block={block}
          options={validate_options}
        />
      </Form.Field>
    </Form>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default injectIntl(Edit);
