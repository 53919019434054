/**
 * Side Nav PC component.
 * @module components/theme/SideNavPC
 */

import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Accordion } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Icon } from '../../../index';
import { Link } from 'react-router-dom';
import {
  GlobalStyle,
  StyleConfig,
  MediaQueryConfig,
} from '../../../../helpers/GlobalStyle/GlobalStyle';
import { withRouter } from 'react-router';
import './SideNavPc.css';
import { getLocale } from '../../../../helpers/Locale/Locale';

const messages = defineMessages({});

class SideNavPc extends React.Component {
  static propTypes = {
    navigations: PropTypes.array,
    links: PropTypes.array,
    onHide: PropTypes.func,
    locale: PropTypes.string,
  };

  static defaultProps = {
    navigations: [],
    links: [],
    onHide: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1,
      top: 0,
      // 追加分
      currentPOS: 0, // 再表示前のリストtop座標
      initHeight: 0, // リンクリストのheight
      mindBottomPOS: false, // 下スクロール時にリストbottomがwindow下端で止まる
      initPOSwhenDown: 0, // 下スクロール開始時の初期座標
    };
    this.handleLink = this.handleLink.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.eventHandler = this.scrollHandler.bind(this);
    window.addEventListener('scroll', this.eventHandler);
    this.setState({
      initHeight: this.node.getBoundingClientRect().height,
      mindBottomPOS:
        window.innerHeight - this.node.getBoundingClientRect().height < 0 ||
        false,
    });
  }

  scrollHandler(e) {
    if (typeof window !== 'undefined') {
      const clientRect = this.node.getBoundingClientRect();
      const winHeight = window.innerHeight;
      // console.log(this.state.mindBottomPOS);
      if (this.state.mindBottomPOS) {
        if (clientRect.top - this.state.currentPOS > 0) {
          // 上スクロール
          // console.log('up');
          // console.log(clientRect.top < 0);
          if (clientRect.top < 0) {
            // console.log(Math.abs(clientRect.top || 0));
            this.setState({ top: Math.abs(clientRect.top || 0) });
            this.setState({ initPOSwhenDown: Math.abs(clientRect.top || 0) });
          } else {
            // console.log(0);
            this.setState({ top: 0 });
            this.setState({ initPOSwhenDown: 0 });
          }
        } else {
          // 下スクロール
          // console.log('down');
          // console.log(
          //   clientRect.top +
          //     200 +
          //     (this.state.initHeight - winHeight) +
          //     this.state.initPOSwhenDown <
          //     0,
          // );
          if (
            clientRect.top +
              200 +
              (this.state.initHeight - winHeight) +
              this.state.initPOSwhenDown <
            0
          ) {
            // console.log(
            //   this.state.top + (this.state.currentPOS - clientRect.top),
            // );
            this.setState({
              top: this.state.top + (this.state.currentPOS - clientRect.top),
            });
            // } else {
            //   console.log(this.state.top);
          }
        }
        this.setState({ currentPOS: clientRect.top });
      } else {
        if (clientRect.top < 0) {
          this.setState({ top: Math.abs(clientRect.top || 0) });
        } else {
          this.setState({ top: 0 });
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.eventHandler);
  }

  handleLink = (path) => {
    this.props.onHide();
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <div
        className="SideNavPc"
        ref={(node) => {
          this.node = node;
        }}
      >
        <nav
          style={{
            paddingTop: `${this.state.top}px`,
          }}
        >
          <div className="nav-wrapper">
            <h2 className="visually-hidden">Menu</h2>
            <h3 className="visually-hidden">Main Navigation</h3>
            <ul className="MenuList">
              {_.map(this.props.navigations, (item) => {
                return (
                  <li className="MenuListItem" key={item.url}>
                    {item.is_external ? (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.name}
                        <Icon
                          icon="clone outline"
                          size={StyleConfig.menuIconSizeSmall}
                          color={StyleConfig.iconColorLightGray}
                        ></Icon>
                      </a>
                    ) : (
                      <Link
                        to={item.url}
                        onClick={() => {
                          this.handleLink(item.url);
                        }}
                      >
                        {item.name}
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
            <Accordion>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={this.handleClick}
                className="AccordionTitle"
              >
                <span>Links</span>
                <Icon
                  icon={activeIndex === 0 ? 'chevron up' : 'chevron down'}
                  size={StyleConfig.menuIconSizeSmall}
                  color={StyleConfig.iconColorLightGray}
                ></Icon>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <ul className="LinkList">
                  {_.map(this.props.links, (item) => {
                    return (
                      <li className="LinkListItem" key={item.url}>
                        {item.is_external ? (
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.name}
                            <Icon
                              icon="clone outline"
                              size={StyleConfig.menuIconSizeSmall}
                              color={StyleConfig.iconColorLightGray}
                            ></Icon>
                          </a>
                        ) : (
                          <Link
                            to={item.url}
                            onClick={() => {
                              this.handleLink(item.url);
                            }}
                          >
                            {item.name}
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </Accordion.Content>
            </Accordion>
          </div>
        </nav>
      </div>
    );
  }
}

/**
 * コンテナ
 */
const SideNavPcContainer = connect(
  (state, props) => {
    // const locale = state?.content?.data?.language?.token ?? 'ja';
    const locale = getLocale(state?.content?.data?.language?.token);
    return {
      navigations:
        state.siteMainInfo?.result?.data?.attributes?.locale[locale]?.side_nav
          ?.navigations,
      links:
        state.siteMainInfo?.result?.data?.attributes?.locale[locale]?.side_nav
          ?.links,
      locale,
    };
  },
  (dispatch, props) => {
    return {};
  },
)(SideNavPc);

export default injectIntl(SideNavPcContainer, {});
