/**
 * BrowsingHistorySaver Component
 * 閲覧履歴をCookieに保存するComponent
 * @module components/theme/Element/BrowsingHistorySaver/BrowsingHistorySaver/BrowsingHistorySaver
 */

import { useEffect } from 'react';
import cookie from 'react-cookie';
import { connect } from 'react-redux';
import {
  encodedStrToArray,
  updateNumLimitedArray,
  arrayToEncodedStr,
} from '../../../../helpers/Cookie/Cookie';
import { browsingHistorySaver } from '../../../../actions';
import { CookieSetting } from '../../../../constants/Setting';

/**
 * Browsing history saver functional component
 * @param {Object} props component properties.
 * @returns {null}
 */
const BrowsingHistorySaver = (props) => {
  useEffect(() => {
    const history = cookie.load(CookieSetting.VIEW_HISTORIES.name);
    const cookieValue = arrayToEncodedStr(
      updateNumLimitedArray(encodedStrToArray(history), props.uid, 10),
    );
    props.browsingHistorySaver(cookieValue);
  }, [props.uid]);
  return null;
};

/**
 * Container function component to connect Redux state, Action and child component.
 * @param {function} connect the function to connect to redux and component.
 * @returns {undefined}
 */
const BrowsingHistorySaverContainer = connect(
  (state, props) => {
    return {
      uid: state.content?.data?.UID,
    };
  },
  (dispatch, props) => {
    return {
      browsingHistorySaver: (history) =>
        dispatch(browsingHistorySaver(history)),
    };
  },
)(BrowsingHistorySaver);

export default BrowsingHistorySaverContainer;
