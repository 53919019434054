export const toolbarReviseConfig = { attributes: true };

export function getToolbarDOM(block) {
  const blockDOM = document.querySelector(`[data-rbd-draggable-id="${block}"]`);
  if (block) {
    const toolbarDOM = blockDOM.querySelector(
      '.draftJsToolbar__toolbar__dNtBH',
    );
    if (toolbarDOM && toolbarDOM !== []) {
      return toolbarDOM;
    }
  }
  return null;
}

export function createToolbarPositionReviser(block) {
  const blockDOM = document.querySelector(`[data-rbd-draggable-id="${block}"]`);
  if (blockDOM) {
    const config = { attributes: true };
    const observer = new MutationObserver((mutation) => {
      // console.log(
      //   mutation[0]?.target?.style?.visibility,
      //   mutation[0]?.target?.style?.left,
      // );
      // console.log(
      //   mutation[1]?.target?.style?.visibility,
      //   mutation[1]?.target?.style?.left,
      // );
      // console.log(
      //   mutation[2]?.target?.style?.visibility,
      //   mutation[2]?.target?.style?.left,
      // );
      // console.log(blockDOM.clientWidth);
      // console.log(mutation[0].target.clientWidth);
      const blockWidth = blockDOM.clientWidth;
      const toolbarWidth = mutation[0].target.clientWidth;
      const targetStyle = mutation[0]?.target?.style;
      // console.log(targetStyle.left.replace('px', ''));
      if (targetStyle?.visibility && targetStyle?.left) {
        // console.log(targetStyle.left);
        if (targetStyle.left.replace('px', '') <= 160) {
          targetStyle.left = '160px';
        } else if (
          // blockWidth - toolbarWidth + 235 <=
          blockWidth - toolbarWidth + 192 <=
          targetStyle.left.replace('px', '')
        ) {
          // targetStyle.left = blockWidth - toolbarWidth + 235 + 'px';
          targetStyle.left = blockWidth - toolbarWidth + 192 + 'px';
        }
      }
    });
    return observer;
  }
  return null;
}
