/**
 * Tags component.
 * @module components/theme/Tags/Tags
 */

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Tags.css';

/**
 * Tags component class.
 * @function Tags
 * @param {array} tags Array of tags.
 * @returns {string} Markup of the component.
 */
const Tags = ({ tags, locale }) => {
  const keywords = locale === 'ja' ? 'キーワード' : 'Keywords';

  return tags && tags.length > 0 ? (
    <div className="Tags">
      <h3 className="header3">{keywords}</h3>
      <div className="tags">
        {tags.map((tag) => (
          <Link
            className="ui label"
            to={`/search-tag?Subject=${tag}`}
            key={tag}
          >
            {tag}
          </Link>
        ))}
      </div>
    </div>
  ) : (
    <span />
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
Tags.defaultProps = {
  tags: null,
};

const TagsContainer = connect((state, props) => {
  return {
    locale: state?.content?.data?.language?.token ?? 'ja',
  };
})(Tags);

export default TagsContainer;
