/**
 * ResearchCollection component
 * トップページの研究記事一覧を表示するコンポーネント
 * @module src/components/theme/Site/Collection/ResearchCollection
 */

import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Masonry from 'react-masonry-css';
import { useMediaQuery } from 'react-responsive';
import qs from 'query-string';
import {
  MediaQueryConfig,
  MediaQueryWidth,
} from '../../../../helpers/GlobalStyle/GlobalStyle';
import { researchListOnTop } from '../../../../actions';
import { SeeResearchMoreButton } from '../../../index';

import ResearchContainer from '../../../manage/Blocks/RestrictedListing/ResearchContainer';
import './ResearchCollection.css';
import { getLocaleFromPathname } from '../../../../helpers/Locale/Locale';

const messages = defineMessages({
  resentResearches: {
    id: 'Resent Researches',
    defaultMessage: 'Resent Researches',
  },
});

const subRequestKey = 'researchCollectionOnTop';
const TabletWidth = MediaQueryWidth.TABLET;
const queryString = {
  query: [
    {
      i: 'portal_type',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['ResOUResearch'],
    },
    {
      i: 'path',
      o: 'plone.app.querystring.operation.string.relativePath',
      v: 'research',
    },
  ],
  // b_size: 8, //コンポーネント内でAPIから取得した値で上書きしている
  fullobjects: 1,
  sort_on: 'announcement_date',
  sort_order: 'descending',
};

/**
 * querystringSearchのAPIの戻り値に含まれる、batchingURLからページインデックスを取得する
 * @param {Object} batching バッチングオブジェクト
 * @param {number} bSize バッチサイズ
 */
const batchingPage = (batching, bSize) => {
  let url;
  if (!bSize) {
    bSize = 8;
  }
  try {
    url = new URL(batching.next);
  } catch (e) {
    url = undefined;
  }
  let page;
  if (url) {
    const query = qs.parse(url.search);
    const bStart = query.b_start;
    if (bStart) {
      page = bStart / bSize + 1;
    } else {
      page = null;
    }
  } else {
    page = null;
  }
  return page;
};

/**
 * ResearchiCollection Component
 * 研究記事一覧コンポーネント（言語トップページ用）
 */
const ResearchCollection = ({
  batching,
  items = [],
  bSize,
  locale,
  intl,
  setQueryString,
  ...props
}) => {
  queryString.b_size = bSize;
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  const breakpointColumns = {
    default: 4,
    1200: 3,
    1000: 2,
    [TabletWidth]: 1,
  };

  const [nowLocale, setNowLocale] = useState(
    getLocaleFromPathname(props.pathname, 'ja'),
  );

  useEffect(() => {
    setQueryString(locale, queryString, subRequestKey, 1, []);
  }, []);

  /**
   * 言語変更時に表示アイテムをリセットして再取得する
   */
  useEffect(() => {
    if (nowLocale !== locale) {
      setQueryString(locale, queryString, subRequestKey, 1, []);
      setNowLocale(locale);
    }
  }, [nowLocale, setQueryString, locale]);

  const newPage = batchingPage(batching, bSize);
  return (
    <>
      {items.length ? (
        <div className="ResearchCollection">
          <div className="resentResearchHeader">
            <hr></hr>
            <h2>{intl.formatMessage(messages.resentResearches)}</h2>
          </div>
          <Masonry
            breakpointCols={breakpointColumns}
            className={`mGrid ${isSP ? 'sp' : ''}`}
            columnClassName="mGridColumn"
          >
            {items &&
              items.map((item) => (
                <div key={item['@id']}>
                  <ResearchContainer content={item} isEditMode={false} />
                </div>
              ))}
          </Masonry>
          <SeeResearchMoreButton
            intl={intl}
            setQueryString={setQueryString}
            locale={locale}
            queryString={queryString}
            subRequestKey={subRequestKey}
            page={newPage}
            items={items}
          ></SeeResearchMoreButton>
        </div>
      ) : null}
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ResearchCollection.propTypes = {
  batching: PropTypes.shape({
    '@id': PropTypes.string,
    first: PropTypes.string,
    last: PropTypes.string,
    next: PropTypes.string, //"http://localhost:3000/api/ja/@querystring-search?b_start=8"}
    prev: PropTypes.string,
  }),
  items: PropTypes.arrayOf(PropTypes.object),
  locale: PropTypes.string.isRequired,
  bSize: PropTypes.string,
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
  setQueryString: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
};

/**
 * API, Reduxと接続するwrapper
 */
const ResearchCollectionOnTopContainer = connect(
  (state, props) => ({
    items:
      state?.researchListOnTop?.subrequests?.researchCollectionOnTop?.items,
    batching:
      state?.researchListOnTop?.subrequests?.researchCollectionOnTop?.batching,
    bSize:
      state?.siteMainInfo?.result?.data?.attributes?.common?.research_list
        ?.count,
    locale: getLocaleFromPathname(props.pathname, 'ja'),
    intl: props.intl,
  }),
  (dispatch) => {
    return {
      setQueryString: (locale, queryString, subRequest, page, currentItems) =>
        dispatch(
          researchListOnTop(
            locale,
            queryString,
            subRequest,
            page,
            currentItems,
          ),
        ),
    };
  },
)(ResearchCollection);
export default injectIntl(ResearchCollectionOnTopContainer);
