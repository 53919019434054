/**
 * View InquirySource block.
 * @module components/manage/Blocks/InquirySource/View
 */

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Input, Form } from 'semantic-ui-react';
import { validateQueryParams } from '@package/helpers/Validator/Validator';
import './View.css';

const messages = defineMessages({
  articleUrl: {
    id: 'Article URL', // temp
    defaultMessage: '',
  },
  researcherName: {
    id: 'Researcher Name', // temp
    defaultMessage: '',
  },
  affiliationOrganization: {
    id: 'Affiliation Organization', // temp
    defaultMessage: '',
  },
});

/**
 * View InquirySource block class.
 * @class View
 * @extends Component
 */
const View = ({
        properties,
        id,
        data,
        formData,
        handleInputData,
        handleBlur,
        handleInitFormData,
        handleIsValidQuery,
        handleRedirectPath,
        intl,
      }) => {
        const validOueryKeys = ['contentUrl', 'researcher', 'affiliation'];
        const { search } = useLocation();
        const query = new URLSearchParams(search);
        const isValidQuery = validateQueryParams(query, validOueryKeys);
        const article = decodeURIComponent(
          query.get('contentUrl') || '',
        ).substring(0, 100);
        const researcher = decodeURIComponent(
          query.get('researcher') || '',
        ).substring(0, 100);
        const affiliation = decodeURIComponent(
          query.get('affiliation') || '',
        ).substring(0, 100).replace(/\x20/g, '／');
        useEffect(() => {
          handleIsValidQuery(isValidQuery);
          if (isValidQuery) {
            if (!formData) {
              const formDataList = [];
              if (article) {
                formDataList.push({
                  subFormId: 'articleUrl',
                  value: article,
                  label: intl.formatMessage(messages['articleUrl']),
                });
              }
              if (researcher) {
                formDataList.push({
                  subFormId: 'researcherName',
                  value: researcher,
                  label: intl.formatMessage(messages['researcherName']),
                });
              }
              if (affiliation) {
                formDataList.push({
                  subFormId: 'affiliationOrganization',
                  value: affiliation,
                  label: intl.formatMessage(
                    messages['affiliationOrganization'],
                  ),
                });
              }
              const initFormData = {
                formId: 'inquirySource',
                id,
                formDataList,
              };
              if (formDataList.length) {
                handleInitFormData(initFormData);
                handleRedirectPath(article);
              }
            }
          }
        }, []);
        return isValidQuery && formData ? (
          <fieldset className="InquirySourceView">
            {_.map(formData.formDataList, (subFormData) =>
              subFormData.value ? (
                <Form.Field key={subFormData.subFormId}>
                  <label>{subFormData.label}</label>
                  <Input
                    className="StyledInput"
                    id={subFormData.subFormId}
                    name={subFormData.subFormId}
                    readonly
                    type="text"
                    value={
                      subFormData.subFormId === 'articleUrl'
                        ? 'https://resou.osaka-u.ac.jp' + subFormData.value
                        : subFormData.value
                    }
                  />
                </Form.Field>
              ) : null,
            )}
          </fieldset>
        ) : null;
      };;

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(View);
