/**
 * Store form ids of mail form
 * メールフォームのフォームID重複管理用
 * @module actions/mailFormFormIds/mailFormFormIds
 */
import { MAIL_FORM_FORM_IDS } from '../../constants/ActionTypes';

export function mailFormFormIds(uid, block, formId) {
  return {
    type: MAIL_FORM_FORM_IDS,
    formIdData: { uid: uid, block: block, formId: formId },
  };
}
