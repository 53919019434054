/**
 * Label form widget for the part of Edit component of TextLine, TextArea block for MailForm.
 * @module components/manage/Blocks/Mailform/EditorWidgets/LabelWidget
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Input, Label } from 'semantic-ui-react';

/**
 * Edit Label widget class.
 * @class Edit
 * @extends Component
 */
class LabelWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    block: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      label: props.data.label || '',
    };
  }

  /**
   * Component will receive props
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.label) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        label: '',
      });
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Form.Field>
        <Label htmlFor="label">
          <FormattedMessage id="Label" defaultMessage="Label" />
        </Label>
        <Input
          name="label"
          value={this.state.label}
          onChange={(e, target) => {
            this.props.onChangeBlock(this.props.block, {
              ...this.props.data,
              label: target.value,
            });
            this.setState({ label: target.value });
          }}
        />
      </Form.Field>
    );
  }
}

export default injectIntl(LabelWidget);
