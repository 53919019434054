/**
 * フォーム入力値のバリデータ
 */

import _ from 'lodash';

/* emailアドレス正規表現 */
// const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
const emailRegex = new RegExp(
  /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
);
/* HTMLタグ正規表現 */
const htmlTagRegex = new RegExp(/(<([^>]+)>)/gi);
/* '危険なタグ'正規表現 */
const dengarousHtmlTagRegex = new RegExp(/(<(style|object|embed|applet|script|meta)>)/i)

/**
 * validate関数本体
 * 入力必須, Emailチェックに対応
 *
 * @param {*} value フォーム入力値
 * @param {*} label フォームのラベル
 * @param {*} required 入力必須項目
 * @param {*} validation その他のバリデーション方法
 * @returns {string} エラーメッセージ
 */
export const validateFormValue = (
  value,
  label,
  required,
  validation,
  locale,
) => {
       const msgArray = [];
       // if (required && !value) {
       //   if (locale == 'ja') {
       //     msgArray.push(`${label}を入力してください`);
       //   } else {
       //     msgArray.push(`Please input ${label}`);
       //   }
       // }
       // if (validation === 'email' && !emailRegex.test(value)) {
       //   if (locale == 'ja') {
       //     msgArray.push('正しいメールアドレスを入力してください');
       //   } else {
       //     msgArray.push('Please input valid email address');
       //   }
       // }
       if (required && !validation && !value) {
         if (locale === 'ja') {
           msgArray.push(`${label}を入力してください`);
         } else {
           msgArray.push(`Please input ${label}`);
         }
       } else if (
         !required &&
         validation === 'email' &&
         value &&
         !emailRegex.test(value)
       ) {
         if (locale === 'ja') {
           msgArray.push('正しいメールアドレスを入力してください');
         } else {
           msgArray.push('Please input valid email address');
         }
       } else if (required && validation === 'email' && !value) {
         if (locale === 'ja') {
           msgArray.push(`${label}を入力してください`);
         } else {
           msgArray.push(`Please input ${label}`);
         }
       } else if (
         required &&
         validation === 'email' &&
         value &&
         !emailRegex.test(value)
       ) {
         if (locale === 'ja') {
           msgArray.push('正しいメールアドレスを入力してください');
         } else {
           msgArray.push('Please input valid email address');
         }
       }
       return msgArray.length ? msgArray.join(' ') : '';
     };

/**
 * MailForm クエリパラメータのバリデータ
 * @nk24:CMScom 2021/01/26
 * @param {URLSearchParams} query URLSearchParams クエリパラメータオブジェクト
 * @param {Array} allowedKeys 受け入れるキー
 * @returns {boolean} OK ---> true, NG ---> false
 */
export const validateQueryParams = (query, allowedKeys) => {
  const keys = query.keys();
  const values = query.values();
  // queryのキーが全てallowedKeysに含まれる
  for (let key of keys) {
    if (!_.includes(allowedKeys, key)) {
      return false;
    }
  }
  for (let value of values) {
    if (dengarousHtmlTagRegex.test(value)) {
      return false;
    }
    if (_.startsWith(value, 'https://')) {
      return false;
    }
    if (_.startsWith(value, 'http://')) {
      return false;
    }
  }
  return true;
};