/**
 * Edit Link button block component for MailForm block component.
 * @module components/manage/Blocks/MailForm/LinkButtonBlock/Edit
 */

import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';
import { Dropdown, Form, Label, Input } from 'semantic-ui-react';

/**
 * Edit link button block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    properties: PropTypes.objectOf(PropTypes.any).isRequired,
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onAddBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   */
  constructor(props) {
    super(props);
    this.state = {
      link: this.props.data.link || '',
      label: this.props.data.label || '',
      buttonColor: this.props.data.buttonColor || '#CCCCCC',
    };
    this.buttonColorOption = this.buttonColorOption.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  /**
   * Button color options
   */
  buttonColorOption = () => {
    let arr = _.map(this.props.category_color, (val, key) => {
      return {
        key: val,
        text: val,
        value: val,
        icon: { name: 'circle', style: { color: val } },
      };
    });
    arr.unshift({
      key: '#2D287F',
      text: '#2D287F',
      value: '#2D287F',
      icon: { name: 'circle', style: { color: '#2D287F' } },
    });
    return arr;
  };

  /**
   * Button color dropdown handler
   * @param {*} target
   */
  handleSelect(target) {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      buttonColor: target.value,
    });
    this.setState({ buttonColor: target.value });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    return (
      <Form>
        <Form.Field>
          <Label htmlFor="link">Link</Label>
          <Input
            name="link"
            value={this.state.link}
            onChange={(e, target) => {
              this.props.onChangeBlock(this.props.block, {
                ...this.props.data,
                link: target.value,
              });
              this.setState({ link: target.value });
            }}
          />
        </Form.Field>
        <Form.Field>
          <Label htmlFor="label">Label</Label>
          <Input
            name="label"
            value={this.state.label}
            onChange={(e, target) => {
              this.props.onChangeBlock(this.props.block, {
                ...this.props.data,
                label: target.value,
              });
              this.setState({ label: target.value });
            }}
          />
        </Form.Field>
        <Form.Field>
          <Label htmlFor="button-color">Button color</Label>
          <Dropdown
            placeholder="Select button color"
            fluid
            selection
            className="icon"
            icon={{
              name: 'circle',
              style: { color: this.state.buttonColor },
            }}
            value={this.state.buttonColor}
            options={this.buttonColorOption()}
            onChange={(e, target) => this.handleSelect(target)}
          />
        </Form.Field>
      </Form>
    );
  }
}

/**
 * Container method component to connect the redux state and edit link button block component.
 * @function EditWrapper
 * @param {function connect(params)} connect connect function.
 * @returns {undefined}
 */
const EditWrapper = connect((state, props) => {
  return {
    category_color:
      state?.siteMainInfo?.result?.data?.attributes?.common?.category_colors,
  };
})(Edit);

export default injectIntl(EditWrapper);
