/**
 * Add your components here.
 * @module components
 * @example
 * import Footer from './Footer/Footer';
 *
 * export {
 *   Footer,
 * };
 */

import { defineMessages } from 'react-intl';
import { blocks as defaultBlocks } from '@plone/volto/config';
import ViewDefinitionListBlock from './manage/Blocks/DefinitionList/View';
import EditDefinitionListBlock from './manage/Blocks/DefinitionList/Edit';
import ViewHeader3Block from './manage/Blocks/Header3/View';
import EditHeader3Block from './manage/Blocks/Header3/Edit';
import ViewHeaderTextBlock from './manage/Blocks/HeaderText/View';
import EditHeaderTextBlock from './manage/Blocks/HeaderText/Edit';
import ViewListBlock from './manage/Blocks/List/View';
import EditListBlock from './manage/Blocks/List/Edit';
import ViewRestrictedListingBlock from './manage/Blocks/RestrictedListing/View';
import EditRestrictedListingBlock from './manage/Blocks/RestrictedListing/Edit';
import ViewMailFormTextLineBlock from './manage/Blocks/MailForm/TextLineBlock/View';
import EditMailFormTextLineBlock from './manage/Blocks/MailForm/TextLineBlock/Edit';
import ViewMailFormTextAreaBlock from './manage/Blocks/MailForm/TextAreaBlock/View';
import EditMailFormTextAreaBlock from './manage/Blocks/MailForm/TextAreaBlock/Edit';
import ViewLinkButtonBlock from './manage/Blocks/MailForm/LinkButtonBlock/View';
import EditLinkButtonBlock from './manage/Blocks/MailForm/LinkButtonBlock/Edit';
import HolizontalRuleBlock from './manage/Blocks/HolizontalRule/HolizontalRule';
import ViewSDGsBlock from './manage/Blocks/SDGs/View';
import EditSDGsBlock from './manage/Blocks/SDGs/Edit';
import appsSVG from '@plone/volto/icons/apps.svg';
import headingSVG from '@plone/volto/icons/heading.svg';
import listArrowsSVG from '@plone/volto/icons/list-arrows.svg';
import emailSVG from '@plone/volto/icons/email.svg';
import removeSVG from '@plone/volto/icons/remove.svg';
import recordSVG from '@plone/volto/icons/record.svg';
import MailFormView from './theme/View/MailFormView';
import ResearchFolderView from './theme/View/ResearchFolderView';
import ResearchView from './theme/View/ResearchView';
import StoryFolderView from './theme/View/StoryFolderView';
import StoryView from './theme/View/StoryView';
import FeatureMainFolderView from './theme/View/FeatureMainFolderView';
import FeatureFolderView from './theme/View/FeatureFolderView';
import FeatureView from './theme/View/FeatureView';
import LanguageRootFolderView from './theme/View/LanguageRootFolderView';
import TagListView from './theme/View/TagListView';
import FolderBlockView from './theme/View/FolderBlockView';
import PageView from './theme/View/PageView';
import DateWidget from './manage/Widgets/DateWidget';
import TermIndexWidget from './manage/Widgets/TermIndexWidget/TermIndexWidget';
import ListWidget from './manage/Widgets/ListWidget';
import MultilingualSelectWidget from './manage/Widgets/MultilingualSelectWidget';
import MultilingualArrayWidget from './manage/Widgets/MultilingualArrayWidget';
import ViewInquirySource from './manage/Blocks/MailForm/InquirySource/View';
import EditInquirySource from './manage/Blocks/MailForm/InquirySource/Edit';
import ViewMailFormSelect from './manage/Blocks/MailForm/Select/View';
import EditMailFormSelect from './manage/Blocks/MailForm/Select/Edit';

import SiteHeader from './theme/Site/SiteHeader/SiteHeader';
import SiteFooter from './theme/Site/SiteFooter/SiteFooter';
import SiteImage from './theme/Site/SiteImage/SiteImage';
import SideNav from './theme/Site/SideNav/SideNav';
import SideNavPc from './theme/Site/SideNav/SideNavPc';
import SideNavSp from './theme/Site/SideNav/SideNavSp';
import Icon from './theme/Element/Icon/Icon';
import StoryzList from './theme/Site/Slider/StoryzList';
import MainGraph from './theme/Site/MainGraph/MainGraph';
import MainGraphAutoSelect from './theme/Site/MainGraph/MainGraphAutoSelect';
import ResearchCollection from './theme/Site/Collection/ResearchCollection';
import SideTeacherComment from './theme/Site/SideTeacherComment/SideTeacherComment';
import TeacherCommentButton from './theme/Element/TeacherCommentButton/TeacherCommentButton';
import LanguageSelectorPC from './theme/Element/LanguageSelector/LanguageSelectorPC';
import LanguageSelectorSP from './theme/Element/LanguageSelector/LanguageSelectorSP';
import SearchWidgetWordList from './theme/Site/Slider/SearchWidgetWordList';
import BrowsingHistoryList from './theme/Site/Slider/BrowsingHistoryList';
import BrowsingHistorySaver from './theme/Element/BrowsingHistorySaver/BrowsingHistorySaver';
import FeatureList from './theme/Site/Slider/FeatureList';
import GenericList from './theme/Site/Slider/GenericList';
import ItemListRelatedtoTeacher from './theme/Site/Slider/ItemListRelatedtoTeacher';
import ExtraCollection from './theme/Site/Collection/ExtraCollection';
import ExtraSectionHeader from './theme/Element/ExtraSectionHeader/ExtraSectionHeader';
import CustomDimmer from './theme/Element/CustomDimmer/CustomDimmer';
import TermContent from './theme/Element/TermContent/TermContent';
import ItemListRelatedtoDepartment from './theme/Site/Collection/ItemListRelatedtoDepartment';
import PastFeatureList from './theme/Site/Collection/PastFeatureCollection';
import PopularArticleCollection from './theme/Site/Collection/PopularArticleCollection';
import SeeMoreButton from './theme/Element/SeeMoreButton/SeeMoreButton';
import ScrollToTop from './theme/Element/ScrollToTop/ScrollToTop';
import SearchWidgetForSearch from './theme/Element/SearchWidgetForSearch/SearchWidgetForSearch';
import SeeResearchMoreButton from './theme/Element/SeeResearchMoreButton/SeeResearchMoreButton';
import TermButton from './theme/Element/TermButton/TermButton';
import SearchContent from './theme/Element/SearchContent/SearchContent';
import InfiniteScroll from './theme/Element/InfiniteScroll/InfiniteScroll';
import AnchorLinkForResearch from './theme/Element/AnchorLink/AnchorLinkForResearch';
import ExternalLink from './theme/Element/ExternalLink/ExternalLink';
import PublisherBox from './theme/Site/PublisherBox/PublisherBox';
import ArticleInquiryButton from './theme/Element/ArticleInquiryButton/ArticleInquiryButton';

defineMessages({
  definition_list: {
    id: 'definition_list',
    defaultMessage: 'Definition List',
  },
  header3: {
    id: 'header3',
    defaultMessage: 'Header3',
  },
  header_text: {
    id: 'header_text',
    defaultMessage: 'Header Text',
  },
  list: {
    id: 'list',
    defaultMessage: 'List',
  },
  restricted_listing: {
    id: 'restricted_listing',
    defaultMessage: 'Restricted Listing',
  },
  mailform_textline: {
    id: 'mailform_textline',
    defaultMessage: 'Mailform Textline',
  },
  mailform_textarea: {
    id: 'mailform_textarea',
    defaultMessage: 'Mailform Textarea',
  },
  mailform_select: {
    id: 'mailform_select',
    defaultMessage: 'Mailform Select',
  },
  mailform_inquiry_source: {
    id: 'mailform_inquiry_source',
    defaultMessage: 'Inquiry Source',
  },
  link_button: {
    id: 'link_button',
    defaultMessage: 'Link Button',
  },
  sdgs: {
    id: 'sdgs',
    defaultMessage: 'SDGs',
  },
});

export const customBlocks = {
  definition_list: {
    id: 'definition_list',
    title: 'DefinitionList',
    icon: listArrowsSVG,
    group: 'text',
    view: ViewDefinitionListBlock,
    edit: EditDefinitionListBlock,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  },
  header3: {
    id: 'header3',
    title: 'Header3',
    icon: headingSVG,
    group: 'text',
    view: ViewHeader3Block,
    edit: EditHeader3Block,
    restricted: false,
    mostUsed: true,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  },
  header_text: {
    id: 'header_text',
    title: 'HeaderText',
    icon: headingSVG,
    group: 'text',
    view: ViewHeaderTextBlock,
    edit: EditHeaderTextBlock,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  },
  list: {
    id: 'list',
    title: 'List',
    icon: listArrowsSVG,
    group: 'text',
    view: ViewListBlock,
    edit: EditListBlock,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  },
  restricted_listing: {
    id: 'restricted_listing',
    title: 'RestrictedListing',
    icon: appsSVG,
    group: 'common',
    view: ViewRestrictedListingBlock,
    edit: EditRestrictedListingBlock,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  },
  mailform_textline: {
    id: 'mailform_textline',
    title: 'MailFormTextLine',
    icon: emailSVG,
    group: 'mailform',
    view: ViewMailFormTextLineBlock,
    edit: EditMailFormTextLineBlock,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  },
  mailform_textarea: {
    id: 'mailform_textarea',
    title: 'MailFormTextArea',
    icon: emailSVG,
    group: 'mailform',
    view: ViewMailFormTextAreaBlock,
    edit: EditMailFormTextAreaBlock,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  },
  mailform_inquiry_source: {
    id: 'mailform_inquiry_source',
    title: 'MailFormInquirySource',
    icon: emailSVG,
    group: 'mailform',
    view: ViewInquirySource,
    edit: EditInquirySource,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  },
  link_button: {
    id: 'link_button',
    title: 'LinkButton',
    icon: emailSVG,
    group: 'mailform',
    view: ViewLinkButtonBlock,
    edit: EditLinkButtonBlock,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  },
  holizontal_rule: {
    id: 'holizontal_rule',
    title: 'HolizontalRule',
    icon: removeSVG,
    group: 'common',
    view: HolizontalRuleBlock,
    edit: HolizontalRuleBlock,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  },
  sdgs: {
    id: 'sdgs',
    title: 'SDGs',
    icon: recordSVG,
    group: 'common',
    view: ViewSDGsBlock,
    edit: EditSDGsBlock,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  },
  mailform_select: {
    id: 'mailform_select',
    title: 'MailFormSelect',
    icon: emailSVG,
    group: 'mailform',
    view: ViewMailFormSelect,
    edit: EditMailFormSelect,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  },
};

export const updateDefaultBlocksConfig = {
  text: {
    ...defaultBlocks.blocksConfig.text,
    mostUsed: true,
  },
  video: {
    ...defaultBlocks.blocksConfig.video,
    mostUsed: false,
  },
  leadimage: {
    ...defaultBlocks.blocksConfig.leadimage,
    restricted: true,
  },
  listing: {
    ...defaultBlocks.blocksConfig.listing,
    restricted: true,
  },
  hero: {
    ...defaultBlocks.blocksConfig.hero,
    restricted: true,
  },
};

export {
  MailFormView,
  ResearchFolderView,
  ResearchView,
  StoryFolderView,
  StoryView,
  FeatureMainFolderView,
  FeatureFolderView,
  FeatureView,
  LanguageRootFolderView,
  TagListView,
  FolderBlockView,
  PageView,
  HolizontalRuleBlock,
  DateWidget,
  ListWidget,
  TermIndexWidget,
  MultilingualSelectWidget,
  MultilingualArrayWidget,
  SiteHeader,
  SiteFooter,
  SiteImage,
  SideNav,
  SideNavPc,
  SideNavSp,
  Icon,
  StoryzList,
  MainGraph,
  MainGraphAutoSelect,
  ResearchCollection,
  SideTeacherComment,
  TeacherCommentButton,
  LanguageSelectorPC,
  LanguageSelectorSP,
  SearchWidgetWordList,
  FeatureList,
  GenericList,
  PopularArticleCollection,
  ItemListRelatedtoTeacher,
  ExtraCollection,
  ExtraSectionHeader,
  CustomDimmer,
  TermContent,
  BrowsingHistoryList,
  BrowsingHistorySaver,
  ItemListRelatedtoDepartment,
  PastFeatureList,
  SeeMoreButton,
  ScrollToTop,
  SearchWidgetForSearch,
  SeeResearchMoreButton,
  TermButton,
  SearchContent,
  InfiniteScroll,
  AnchorLinkForResearch,
  ExternalLink,
  PublisherBox,
  ArticleInquiryButton,
};
