/**
 * Get vocabulary from field with locale.
 * Based on src/helpers/Vocabularies/Vocabularies
 * デフォルトのAPIがMultilanguageに未対応(v7.7.1時点)のため作成
 * @function getVocabFromMultilingualField
 * @param {Object} props The props.
 * @return {string} Field name of the layout
 */

import _ from 'lodash';

export function getVocabFromMultilingualField(props) {
  /* 今回は意図しない箇所でのバグを防ぐためField名で絞る。
     /configでwidget(MultilingualWidget)とFieldのidを紐つけること */
  const fieldId = ['research_highlight'];
  if (!fieldId.includes(props?.id)) {
    return false;
  }
  const locale = props?.intl?.locale || 'ja';
  const vocabId = props.vocabulary ? props.vocabulary['@id'] : false;
  if (!vocabId) {
    return false;
  }
  let multilingualVocabId = _.cloneDeep(vocabId);
  return multilingualVocabId.replace(
    '/@vocabularies',
    `/${locale}/@vocabularies`,
  );
}

/**
 * Get vocabulary from items for multilingual.
 * Based on src/helpers/Vocabularies/Vocabularies
 * デフォルトのAPIがMultilanguageに未対応(v7.7.1時点)のため作成
 * @function getVocabFromItems
 * @param {Object} props The props.
 * @return {string} Field name of the layout
 */
export function getVocabFromMultilingualItems(props) {
  /* 今回は意図しない箇所でのバグを防ぐためField名で絞る。
     /configでwidget(MultilingualWidget)とFieldのidを紐つけること */
  const fieldId = ['departments'];
  if (!fieldId.includes(props?.id)) {
    return false;
  }
  const locale = props?.intl?.locale || 'ja';
  const vocabId =
    props.items && props.items.vocabulary
      ? props.items.vocabulary['@id']
      : false;
  if (!vocabId) {
    return false;
  }
  let multilingualVocabId = _.cloneDeep(vocabId);
  return multilingualVocabId.replace(
    '/@vocabularies',
    `/${locale}/@vocabularies`,
  );
}
