/**
 * Required form widget for the part of Edit component of TextLine, TextArea block for MailForm.
 * @module components/manage/Blocks/Mailform/EditorWidgets/RequiredWidget
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Checkbox, Label } from 'semantic-ui-react';

/**
 * Edit form ID widget class.
 * @class Edit
 * @extends Component
 */
class Required extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    block: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      checked: props.data.requiredBlock || false,
    };
  }

  /**
   * Component will receive props
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.requiredBlock) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        requiredBlock: false,
      });
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    // if (nextProps.selected) {
    //   this.codeEditor._input.focus();
    // }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Form.Field>
        <Label htmlFor="required">
          <FormattedMessage id="RequiredLabel" defaultMessage="Required" />
        </Label>
        <Checkbox
          toggle
          name="required"
          checked={this.state.checked}
          onChange={(e, target) => {
            this.props.onChangeBlock(this.props.block, {
              ...this.props.data,
              requiredBlock: target.checked,
            });
            // this.state.checked = (target.checked);
            this.setState({ checked: target.checked });
          }}
        />
      </Form.Field>
    );
  }
}

export default injectIntl(Required);
