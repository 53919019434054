import { map } from 'lodash';
import { settings } from '~/config';

import { GET_TAG_LIST } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  result: {},
};

/**
 * Site Main Info Reducer
 * @param {*} state
 * @param {*} action
 */
export default function tagList(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_TAG_LIST}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_TAG_LIST}_SUCCESS`:
      return {
        ...state,
        error: null,
        result: action?.result,
        loaded: true,
        loading: false,
      };
    case `${GET_TAG_LIST}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
