import React from 'react';
import { LanguageSelector } from '@plone/volto/components';
import './LanguageSelectorSP.css';

const LanguageSelectorSP = (props) => {
  return (
    <div className="LanguageSelectorSP">
      <LanguageSelector locale={props.locale} />
    </div>
  );
};

export default LanguageSelectorSP;
