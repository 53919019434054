import { GET_TAG_LIST } from '../../constants/ActionTypes';

export function tagList(locale) {
  return {
    type: GET_TAG_LIST,
    request: {
      op: 'get',
      path: `/${locale}/@tag-list`,
    },
  };
}
