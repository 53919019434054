import { map } from 'lodash';
import { settings } from '~/config';

import { GET_STORYZ_LIST_RELATEDTO_TEACHER } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  result: {},
};

/**
 * Site Main Info Reducer
 * @param {*} state
 * @param {*} action
 */
export default function storyzListRelatedtoTeacher(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_STORYZ_LIST_RELATEDTO_TEACHER}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_STORYZ_LIST_RELATEDTO_TEACHER}_SUCCESS`:
      return {
        ...state,
        error: null,
        result: action?.result,
        loaded: true,
        loading: false,
      };
    case `${GET_STORYZ_LIST_RELATEDTO_TEACHER}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
