import {
  NOW_TERM_CONTENT,
  DISPLAY_TERM_CONTENT,
} from '../../constants/ActionTypes';

/**
 * Term Content の表示非表示アクション
 * @param {array} terms {IDXXXX: {title: '', description: ''}}
 */
// export function updateTerms(terms) {
//   return {
//     type: MANAGE_TERM_CONTENT,
//     terms: terms,
//   };
// }

/**
 * 現在選択中のID
 * @param {string} now_id 'term-term1'
 */
export function updateNowContent(now_id, title, description) {
  return {
    type: NOW_TERM_CONTENT,
    now_id,
    title,
    description,
  };
}

/**
 * Term Content の表示非表示アクション
 * @param {boolean} visible
 */
export function displayTermContent(visible) {
  return {
    type: DISPLAY_TERM_CONTENT,
    visible: visible,
  };
}
