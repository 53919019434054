import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';
import { blocks } from '~/config';
//import { ResouFolderHeader } from './Common';
import { map } from 'lodash';
import './ResearchFolderView.css';
import {
  FolderTitle,
  FolderLeadSentence,
  HelmetTitle,
  HorizontalRule,
} from './Common';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

/**
 * Research folder view component class.
 * @function ResearchFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const ResearchFolderView = ({ content, intl }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

  return (
    <div className="ResearchFolderView">
      <HelmetTitle pageTitle={content.title} />
      <div className="researchFolderInner">
        <div className="researchFolderHeader">
          <HorizontalRule className="hr" />
          <FolderTitle
            title={content.title}
            title_sp1={content.title_sp1}
            title_sp2={content.title_sp2}
          />
          <FolderLeadSentence
            lead_sentence={content.lead_sentence}
            lead_sentence_sp1={content.lead_sentence_sp1}
            lead_sentence_sp2={content.lead_sentence_sp2}
          />
        </div>

        <div className="researchFolderBody">
          {map(content[blocksLayoutFieldname].items, (block) => {
            const blockType =
              blocks.blocksConfig[content[blocksFieldname]?.[block]?.['@type']];
            const isTitle = blockType?.['id'] === 'title';
            if (isTitle) {
              return <></>;
            }
            const Block = blockType?.['view'] || null;
            return Block !== null ? (
              <Block
                key={block}
                id={block}
                properties={content}
                data={content[blocksFieldname][block]}
              />
            ) : (
              <div key={block}>
                {intl.formatMessage(messages.unknownBlock, {
                  block: content[blocksFieldname]?.[block]?.['@type'],
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ResearchFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        image_caption: PropTypes.string,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default injectIntl(ResearchFolderView);
