/**
 * Section header container for 'Read more...' collection and slider component
 * @module components/theme/Element/ExtraSectionHeader/ExtraSectionHeader
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import './ExtraSectionHeader.css';

/**
 * THe header component
 * @param {Object} props component properties
 * @returns {string} Markup of the component.
 */
const ExtraSectionHeader = (props) => {
  return (
    <div className="ExtraSectionHeader">
      <div className="sectionDivider">
        <hr />
      </div>
      <h2 className="sectionTitle">{props.title}</h2>
      <p className="sliderDescription">{props.description}</p>
    </div>
  );
};

export default injectIntl(ExtraSectionHeader, {});
