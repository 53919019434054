import React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import './Loader.css';

const LoaderComponent = ({ visible }) => {
  return (
    <div className="Loader">
      {visible ? (
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      ) : null}
    </div>
  );
};

export default LoaderComponent;
