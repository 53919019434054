import React from 'react';
import { Loader } from '../../../../helpers';
import './InfiniteScroll.css';
import { Button } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';

const isDebug = false;

/**
 * 無限スクロール用コンポーネント
 */
export default class InfiniteScroll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      next_b_start: 0,
    };
  }

  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    next_b_start: PropTypes.number.isRequired,
    b_size: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    has_next: PropTypes.bool.isRequired,
    onEnter: PropTypes.func.isRequired,
    onInit: PropTypes.func.isRequired,
  };

  /**
   * next_b_startをリセット
   */
  resetNextBStart() {
    this.setState({ next_b_start: 0 });
  }

  /**
   * 表示エリアに入ったとき発火するハンドラ
   */
  onEnter() {
    if (typeof window !== 'undefined') {
      const clientRect = this.node.getBoundingClientRect();
      const scrollTop = document.documentElement.offsetHeight - clientRect.top;
      if (scrollTop > 0) {
        if (
          this.props.has_next &&
          this.props.next_b_start > this.state.next_b_start
        ) {
          this.props.onEnter();
          this.setState({ next_b_start: this.props.next_b_start });
        }
      }
    }
  }

  componentDidMount() {
    this.props.onInit();
    this.setState({ next_b_start: 0 });
    this.eventHandler = this.onEnter.bind(this);
    window.addEventListener('scroll', this.eventHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.eventHandler);
  }

  render() {
    return (
      <div
        className={isDebug ? 'InfiniteScroll debug' : 'InfiniteScroll'}
        ref={(node) => {
          this.node = node;
        }}
      >
        {this.props.has_next ? (
          <div className="loading-button">
            <Button
              onClick={(e) => {
                this.onEnter();
              }}
            >
              <Loader visible={this.props.has_next}></Loader> More...
            </Button>
          </div>
        ) : null}
        {isDebug ? (
          <ul class="debug-window">
            <li>now next_b_start: {this.props.next_b_start}</li>
            <li>previous next_b_start: {this.state.next_b_start}</li>
            <li>b_size: {this.props.b_size}</li>
            <li>total: {this.props.total}</li>
            <li>has_next: {String(this.props.has_next)}</li>
          </ul>
        ) : null}
      </div>
    );
  }
}
