/**
 * PageView component.
 * @module components/theme/View/PageView
 */

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { DefaultBody, HelmetTitle, SNSShare } from './Common';
import './PageView.css';

/**
 * Component to display the PageView.
 * @function PageView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const PageView = ({ content }) => {
  return (
    <div className="PageView">
      <HelmetTitle pageTitle={content.title} />
      <div className="pageInnerView">
        <div className="pageHeader">
          <h2 className="title">{content?.title}</h2>
        </div>
        <div className="pageBody">
          <DefaultBody content={content} />
        </div>
        <SNSShare content={content} />
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PageView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default injectIntl(PageView);
