import { CHANGE_VISIBLE_RESEARCH_CONTENT_FULL } from '../../constants/ActionTypes';

/**
 * 研究情報の表示非表示アクション
 * @param {boolean} visible
 */
export function changeVisibleContentFull(visible) {
  return {
    type: CHANGE_VISIBLE_RESEARCH_CONTENT_FULL,
    visible: visible,
  };
}
