/**
 * Search by tag reducer.
 * @module reducers/searchTag/searchTag
 */

import { map, omit } from 'lodash';
import { settings } from '~/config';
import _ from 'lodash';
import { GET_SEARCH_TAG, RESET_SEARCH_TAG } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  items: null,
  total: 0,
  loaded: false,
  loading: false,
  batching: {},
  subrequests: {},
  b_start: 0,
  b_size: 0,
  has_next: true,
};

/**
 * Search tag reducer.
 * @function search
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function searchTag(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_SEARCH_TAG}_PENDING`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  items: [],
                  total: 0,
                  batching: {},
                }),
                error: null,
                loaded: false,
                loading: true,
              },
            },
          }
        : {
            ...state,
            error: null,
            loading: true,
            loaded: false,
          };
    case `${GET_SEARCH_TAG}_SUCCESS`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: null,
                items: map(action.result.items, (item) => ({
                  ...item,
                  '@id': item['@id'].replace(settings.apiPath, ''),
                })),
                total: action.result.items_total,
                loaded: true,
                loading: false,
                batching: { ...action.result.batching },
              },
            },
          }
        : {
            ...state,
            error: null,
            items: _.concat(
              state.items ?? [],
              map(action.result.items, (item) => ({
                ...item,
                '@id': item['@id'].replace(settings.apiPath, ''),
              })),
            ),
            total: action.result.items_total,
            loaded: true,
            loading: false,
            batching: { ...action.result.batching },
            b_start: action.result.b_start,
            b_size: action.result.b_size,
            has_next: action.result.has_next,
          };
    case `${GET_SEARCH_TAG}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: action.error,
                items: [],
                total: 0,
                loading: false,
                loaded: false,
                batching: {},
              },
            },
          }
        : {
            ...state,
            error: action.error,
            items: [],
            total: 0,
            loading: false,
            loaded: false,
            batching: {},
          };
    case RESET_SEARCH_TAG:
      return action.subrequest
        ? {
            ...state,
            subrequests: omit(state.subrequests, [action.subrequest]),
          }
        : {
            ...state,
            error: null,
            items: [],
            total: 0,
            loading: false,
            loaded: false,
            batching: {},
          };
    default:
      return state;
  }
}
