/**
 * i18n for customization folder
 */

import React from 'react';
import { defineMessages } from 'react-intl';

/**
 * NotFoundページのローカライズ
 * src/customizations/components/theme/NotFound/NotFound.jsx
 */
export const messagesNotFound = defineMessages({
  title: {
    id: 'Sorry, this page isn’t available..',
    defaultMessage: 'Sorry, this page isn’t available..',
  },
  message1: {
    id:
      'The URL you requested may have been mistyped or the page may have been moved.',
    defaultMessage:
      'The URL you requested may have been mistyped or the page may have been moved.',
  },
  message2: {
    id: 'You can return to the top page or try searching via the search page.',
    defaultMessage:
      'You can return to the top page or try searching via the search page.',
  },
});
