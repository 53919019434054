/**
 * Edit ResearchPoint unordered list block without schema.
 * @module components/manage/Blocks/ResearchPoint/Edit
 * copied from src/components/manage/Blocks/Subtitle/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, Segment, List, Form } from 'semantic-ui-react';
import { convertToRaw } from 'draft-js';
import { Portal } from 'react-portal';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';
import { map, remove } from 'lodash';
import ListItem from './ListItem';
import { Field, Icon } from '@plone/volto/components';
import plusSVG from '@plone/volto/icons/circle-plus.svg';
import minusSVG from '@plone/volto/icons/circle-minus.svg';
import upSVG from '@plone/volto/icons/circle-top.svg';
import downSVG from '@plone/volto/icons/circle-bottom.svg';

const messages = defineMessages({
  insertListItem: {
    id: 'Insert item',
    defaultMessage: 'Insert item',
  },
  deleteListItem: {
    id: 'Delete item',
    defaultMessage: 'Delete item',
  },
  onMoveListItemUp: {
    id: 'Up selected item',
    defaultMessage: 'Up selected item',
  },
  onMoveListItemDown: {
    id: 'Down selected item',
    defaultMessage: 'Down selected item',
  },
  ResearchPoint: {
    id: 'ResearchPoint',
    defaultMessage: 'ResearchPoint',
  },
  ordered: {
    id: 'Ordered list',
    defaultMessage: 'Ordered list',
  },
});

const getId = () => Math.floor(Math.random() * Math.pow(2, 24)).toString(32);
const valueToDraft = () => ({
  blocks: [
    {
      data: {},
      key: 'co3kh',
      text: '',
      type: 'unstyled',
    },
  ],
  entityMap: {},
});
const emptyListItem = () => ({
  key: getId(),
  type: 'data',
  value: valueToDraft(),
});
const initialList = {
  ordered: true,
  list_items: [
    {
      key: getId(),
      type: 'data',
      value: valueToDraft(),
    },
  ],
};

/**
 * Edit List block class for the Research point.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    detached: PropTypes.bool,
    index: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    onAddBlock: PropTypes.func.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onMutateBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    detached: false,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   */
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
    };
    this.onSelectListItem = this.onSelectListItem.bind(this);
    this.onChangeListItem = this.onChangeListItem.bind(this);
    this.onInsertListItem = this.onInsertListItem.bind(this);
    this.onDeleteListItem = this.onDeleteListItem.bind(this);
    this.onMoveListItemUp = this.onMoveListItemUp.bind(this);
    this.onMoveListItemDown = this.onMoveListItemDown.bind(this);
    this.toggleBool = this.toggleBool.bind(this);
    this.toggleOrdered = this.toggleOrdered.bind(this);
  }

  /**
   * Component will receive props
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.research_point) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        research_point: _.cloneDeep(initialList),
      });
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.data.research_point) {
      this.props.onChangeBlock(nextProps.block, {
        ...nextProps.data,
        research_point: _.cloneDeep(initialList),
      });
    }
  }

  /**
   * Select list item handler
   * @method onSelectListItem
   * @param {Number} index Selected item index.
   * @returns {undefined}
   */
  onSelectListItem(index) {
    this.setState({ selected: index });
  }

  /**
   * Change list item handler
   * @method onChangeListItem
   * @param {Number} index Selected item index.
   * @param {Object} editorState Editor state.
   * @returns {undefined}
   */
  onChangeListItem(index, editorState) {
    const list = { ...this.props.data.research_point };
    list.list_items[index].value = convertToRaw(
      editorState.getCurrentContent(),
    );
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      list,
    });
  }

  /**
   * Insert <LI> item handler
   * @method onInsertListItem
   * @returns {undefined}
   */
  onInsertListItem() {
    const list = this.props.data.research_point;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      research_point: {
        ...list,
        list_items: [
          ...list.list_items.slice(0, this.state.selected + 1),
          emptyListItem(),
          ...list.list_items.slice(this.state.selected + 1),
        ],
      },
    });
  }

  /**
   * Delete <LI> item
   * @method onDeleteListItem
   * @returns {undefined}
   */
  onDeleteListItem() {
    const list = this.props.data.research_point;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      research_point: {
        ...list,
        list_items: remove(
          list.list_items,
          (item, index) => index !== this.state.selected,
        ),
      },
    });
  }

  /**
   * Move <LI> item up handler
   * @method onMoveListItemUp
   * @returns {undefined}
   */
  onMoveListItemUp() {
    const list = this.props.data.research_point;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      research_point: {
        ...list,
        list_items: [
          ...list.list_items.slice(0, this.state.selected - 1),
          list.list_items[this.state.selected],
          list.list_items[this.state.selected - 1],
          ...list.list_items.slice(this.state.selected + 1),
        ],
      },
    });
  }

  /**
   * Move <LI> item down handler
   * @method onMoveListItemDown
   * @returns {undefined}
   */
  onMoveListItemDown() {
    const list = this.props.data.research_point;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      research_point: {
        ...list,
        list_items: [
          ...list.list_items.slice(0, this.state.selected),
          list.list_items[this.state.selected + 1],
          list.list_items[this.state.selected],
          ...list.list_items.slice(this.state.selected + 2),
        ],
      },
    });
  }

  /**
   * Toggle bool
   * @method toggleBool
   * @param {string} value Value to toggle.
   * @returns {undefined}
   */
  toggleBool(value) {
    const list = this.props.data.research_point;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      research_point: {
        ...list,
        [value]: !list[value],
      },
    });
  }

  /**
   * Toggle ordered
   * @method toggleOrdered
   * @returns {undefined}
   */
  toggleOrdered() {
    this.toggleBool('ordered');
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    return (
      <div
        className={cx('block list-research-point', {
          selected: this.props.selected,
        })}
      >
        {this.props.selected && (
          <div className="toolbar">
            <Button.Group>
              <Button
                icon
                basic
                onClick={this.onInsertListItem}
                title={this.props.intl.formatMessage(messages.insertListItem)}
                aria-label={this.props.intl.formatMessage(
                  messages.insertListItem,
                )}
              >
                <Icon name={plusSVG} size="24px" />
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                icon
                basic
                disabled={
                  this.props.data.research_point &&
                  this.props.data.research_point.list_items.length === 1
                }
                onClick={this.onDeleteListItem}
                title={this.props.intl.formatMessage(messages.deleteListItem)}
                aria-label={this.props.intl.formatMessage(
                  messages.deleteListItem,
                )}
              >
                <Icon name={minusSVG} size="24px" />
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                icon
                basic
                disabled={this.state.selected === 0}
                onClick={this.onMoveListItemUp}
                title={this.props.intl.formatMessage(messages.onMoveListItemUp)}
                aria-label={this.props.intl.formatMessage(
                  messages.onMoveListItemUp,
                )}
              >
                <Icon name={upSVG} size="24px" />
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                icon
                basic
                disabled={
                  this.props.data.research_point &&
                  this.props.data.research_point.list_items.length - 1 ===
                    this.state.selected
                }
                onClick={this.onMoveListItemDown}
                title={this.props.intl.formatMessage(
                  messages.onMoveListItemDown,
                )}
                aria-label={this.props.intl.formatMessage(
                  messages.onMoveListItemDown,
                )}
              >
                <Icon name={downSVG} size="24px" />
              </Button>
            </Button.Group>
          </div>
        )}
        {this.props.data.research_point && (
          <List
            ordered={this.props.data.research_point.ordered}
            as={this.props.data.research_point.ordered ? 'ol' : 'ul'}
          >
            {map(
              this.props.data.research_point.list_items,
              (item, itemIndex) => (
                <List.Item
                  as="li"
                  key={item.key}
                  className={
                    this.state.selected === itemIndex ? 'selected' : ''
                  }
                >
                  <ListItem
                    block={this.props.block}
                    value={item.value}
                    index={itemIndex}
                    blockIndex={this.props.index}
                    onSelectListItem={this.onSelectListItem}
                    onSelectBlock={this.onSelectBlock}
                    selected={itemIndex === this.state.selected}
                    isListBlockSelected={this.props.selected}
                    onChange={this.onChangeListItem}
                    onAddBlock={this.props.onAddBlock}
                  />
                </List.Item>
              ),
            )}
          </List>
        )}
        {this.props.selected && (
          <Portal
            node={__CLIENT__ && document.getElementById('sidebar-properties')}
          >
            <Form method="post" onSubmit={(event) => event.preventDefault()}>
              <Segment secondary attached>
                <FormattedMessage id="Table" defaultMessage="Table" />
              </Segment>
              <Segment attached>
                <Field
                  id="ordered"
                  title={this.props.intl.formatMessage(messages.ordered)}
                  type="boolean"
                  value={
                    this.props.data.research_point &&
                    this.props.data.research_point.ordered
                  }
                  onChange={() => this.toggleOrdered()}
                />
              </Segment>
            </Form>
          </Portal>
        )}
      </div>
    );
  }
}

export default injectIntl(Edit);
