/**
 * ExtraCollection Component
 * 汎用の一覧リスト
 * @module components/theme/Collection/ExtraCollection
 */
import _ from 'lodash';
import React from 'react';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import {
  ExternalLink,
  ExtraSectionHeader,
  SeeMoreButton,
} from '../../../index';
import { Link } from 'react-router-dom';
import './ExtraCollection.css';

/**
 * Collection item component used on extra collection.
 * @param {Object} props component properties
 * @returns {string} Markup of the component.
 */
const CollectionItem = (props) => {
  const url = props.item?.url;
  const redirect = props.item?.redirect;
  return (
    <div className="CollectionItem">
      <Link
        className="carouselItemWrapper"
        to={{ pathname: redirect ? redirect : flattenToAppURL(url) }}
        target={redirect ? '_blank' : ''}
      >
        <img
          src={flattenToAppURL(
            props.item?.image_sp
              ? props.item.image_sp
              : props.item?.image ?? '',
          )}
          alt={props.item?.title}
          title={props.item?.title}
        />
        <h3>{props.item?.title}</h3>
      </Link>
      <ExternalLink link={url} redirect={redirect} />
    </div>
  );
};

/**
 * The base component of extra collections.
 * @params {*}
 * @returns {string} Markup of the component.
 */
const ExtraCollection = ({
  contentList,
  bSize,
  loadContent,
  locale,
  bStart,
  intl,
  hasNext,
  title,
  description,
  apiPath,
}) => {
  return (
    <>
      {contentList.length ? (
        <div className="ExtraCollection">
          <ExtraSectionHeader
            title={title}
            description={description}
          ></ExtraSectionHeader>
          <div className="wrapContent">
            <div className="wrapItems">
              {_.map(contentList, (item) => {
                return <CollectionItem item={item} key={item?.url} />;
              })}
            </div>
          </div>
          <SeeMoreButton
            intl={intl}
            apiPath={apiPath}
            loadContent={loadContent}
            bSize={bSize}
            bStart={bStart}
            hasNext={hasNext}
          ></SeeMoreButton>
        </div>
      ) : null}
    </>
  );
};

export default ExtraCollection;
