/**
 * Show related content by same teacher
 * この先生の他の研究記事一覧スライダー
 * @module components/theme/Site/Slider/ItemListRelatedtoTeacher
 */
import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { itemListRelatedtoTeacher } from '../../../../actions/index';
import DefaultSlider from './GenericList';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

const messages = defineMessages({
  itemListRelatedtoTeacher: {
    id: 'Articles by this researcher',
    defaultMessage: 'Articles by this researcher',
  },
});

/**
 * ItemListRelatedtoTeacher component
 * この先生の他の研究記事一覧
 * 言語が変更された際にコンテンツを再取得しないバグを修正するために追加
 * @nk24 2021-4-22
 */
const ItemListRelatedtoTeacher = (props) => {
  const apiPath = flattenToAppURL(props.baseApiUrl ?? '');
  const [localeState, setLocaleState] = useState();
  useEffect(() => {
    if (props.locale && props.locale !== localeState) {
      setLocaleState(props.locale);
      props.loadContent(apiPath);
    }
  });
  return <DefaultSlider {...props} />;
};

/**
 * Container function component to connect Redux state, Action and child component.
 * GenericList componentとReduxを接続するコンテナComponent
 * @param {function} connect the function to connect to redux and component.
 * @returns {undefined}
 */
const ItemListRelatedtoTeacherContainer = connect(
  (state, props) => ({
    title: props.intl.formatMessage(messages.itemListRelatedtoTeacher),
    description:
      state.itemListRelatedtoTeacher?.result?.data?.attributes?.description,
    data: state.itemListRelatedtoTeacher?.result?.data?.attributes?.data,
    token: state.userSession?.token,
    intl: state.intl,
    locale: state?.content?.data?.language?.token ?? 'ja',
    baseApiUrl: state?.content?.data?.['@id'],
  }),
  (dispatch, props) => ({
    loadContent: (apiPath) => {
      dispatch(itemListRelatedtoTeacher(apiPath));
    },
  }),
)(ItemListRelatedtoTeacher);

export default injectIntl(ItemListRelatedtoTeacherContainer, {});
