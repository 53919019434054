/**
 * TermIndexWidget component.
 * @module components/manage/Widgets/TermIndexWidget
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import Editor from 'draft-js-plugins-editor';
import { convertFromRaw, EditorState } from 'draft-js';
import { convertToRaw } from 'draft-js';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import { defineMessages, injectIntl } from 'react-intl';

import { settings } from '~/config';
import './TermIndexForm.css';

const messages = defineMessages({
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  idTitle: {
    id: 'Short Name',
    defaultMessage: 'Short Name',
  },
  idDescription: {
    id: 'Used for programmatic access to the fieldset.',
    defaultMessage: 'Used for programmatic access to the fieldset.',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  required: {
    id: 'Required',
    defaultMessage: 'Required',
  },
  delete: {
    id: 'Delete',
    defaultMessage: 'Delete',
  },
});

/**
 * TermIndexForm component class.
 * @class TermIndexForm
 * @extends Component
 */
class TermIndexForm extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    index: PropTypes.number,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onChangeTerm: PropTypes.func,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    index: 0,
    value: [],
    onChange: null,
    onChangeTerm: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Cell
   */
  constructor(props) {
    super(props);
    if (!__SERVER__) {
      let editorState;
      if (!props.value.description) {
        editorState = EditorState.createEmpty();
      } else {
        editorState = EditorState.createWithContent(
          convertFromRaw(props.value.description),
        );
      }
      const inlineToolbarPlugin = createInlineToolbarPlugin({
        structure: settings.richTextEditorInlineToolbarButtons,
      });
      this.state = {
        editorState,
        inlineToolbarPlugin,
        termValue: null,
      };
    }
    this.onChange = this.onChange.bind(this);
    this.onChangeTerm = this.onChangeTerm.bind(this);
    // this.setSelectedIndexState = this.setSelectedIndexState.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * Component did mount lifecycle method
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    // if (this.node) {
    //   const onFocus = this.node.editor._onFocus;
    //   this.node.editor._onFocus = event => {
    //     onFocus(event);
    //   };
    // }
    if (this.state.termValue === null) {
      this.setState({ termValue: this.props.value.term });
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    //   if (
    //     nextProps.isTableBlockSelected !== this.props.isTableBlockSelected //&&
    //     // this.props.cell === 0 &&
    //     // this.props.row === 0
    //   ) {
    //     this.node.focus();
    //   }
  }

  /**
   * Set selected index to state
   * @method setSelectedIndexState
   * @returns {undefined}
   */
  // setSelectedIndexState(index) {
  //   this.setState({
  //     selected_index: index,
  //   });
  // }
  handleClick() {
    this.props.setSelectedIndex(this.props.index);
  }

  /**
   * Change handler
   * @method onChange
   * @param {object} editorState Editor state.
   * @returns {undefined}
   */
  onChangeTerm(termValue) {
    this.setState({ termValue: termValue });
    // this.props.value.items[0].term = termValue;
    this.props.onChangeTerm(this.props.index, termValue);
  }

  /**
   * Change handler
   * @method onChange
   * @param {object} editorState Editor state.
   * @returns {undefined}
   */
  onChange(editorState) {
    this.setState({ editorState });
    // const items = [...this.props.value.items];

    this.props.onChange(
      this.props.index,
      convertToRaw(editorState.getCurrentContent()),
    );

    // this.props.onChange(
    //   this.props.id,
    //   // this.props.value,
    //   // items,
    //   {
    //     items: initialListItems,
    //   },
    // );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    const {
      id,
      index,
      isActive,
      title,
      required,
      description,
      error,
      value,
      onChange,
      onEdit,
      onDelete,
      intl,
      icon,
      iconAction,
      fieldSet,
    } = this.props;
    const { InlineToolbar } = this.state.inlineToolbarPlugin;
    return (
      <div
        className={isActive ? 'TermIndexForm activeColumn' : 'TermIndexForm'}
      >
        <Input
          id={`field-${this.props.id}-term`}
          name={`${this.props.id}-term`}
          value={this.state.termValue || ''}
          onChange={({ target }) =>
            this.onChangeTerm(target.value === '' ? undefined : target.value)
          }
          onClick={this.handleClick}
        />
        <Editor
          onChange={this.onChange}
          editorState={this.state.editorState}
          plugins={[
            this.state.inlineToolbarPlugin,
            ...settings.richTextEditorPlugins,
          ]}
          blockRenderMap={settings.extendedBlockRenderMap}
          blockStyleFn={settings.blockStyleFn}
          customStyleMap={settings.customStyleMap}
          onClick={this.handleClick}
          // ref={node => {
          //   this.node = node;
          // }}
        />
        <InlineToolbar />
      </div>
    );
  }
}

export default injectIntl(TermIndexForm);
