/**
 * Edit component for definition list block.
 * @module components/manage/Blocks/DefinitionList/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, List } from 'semantic-ui-react';
import { convertToRaw } from 'draft-js';
import { defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';
import ListItem from './Item';
import { Icon } from '@plone/volto/components';
import plusSVG from '@plone/volto/icons/circle-plus.svg';
import minusSVG from '@plone/volto/icons/circle-minus.svg';
import upSVG from '@plone/volto/icons/circle-top.svg';
import downSVG from '@plone/volto/icons/circle-bottom.svg';

const getId = () => Math.floor(Math.random() * Math.pow(2, 24)).toString(32);

const valueToDraft = () => ({
  blocks: [
    {
      data: {},
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: 'co3kh',
      text: '',
      type: 'unstyled',
    },
  ],
  entityMap: {},
});

const emptyElement = () => ({
  elements: [
    {
      key: getId(),
      type: 'term',
      value: valueToDraft(),
    },
    {
      key: getId(),
      type: 'description',
      value: valueToDraft(),
    },
  ],
  key: getId(),
});

const initialListItems = {
  items: [
    {
      elements: [
        {
          key: getId(),
          type: 'term',
          value: valueToDraft(),
        },
        {
          key: getId(),
          type: 'description',
          value: valueToDraft(),
        },
      ],
      key: getId(),
    },
  ],
};

const messages = defineMessages({
  insertElement: {
    id: 'Add list element',
    defaultMessage: 'Add list element',
  },
  deleteElement: {
    id: 'Delete list element',
    defaultMessage: 'Delete list element',
  },
  onMoveElementUp: {
    id: 'Up selected element',
    defaultMessage: 'Up selected element',
  },
  onMoveElementDown: {
    id: 'Down selected element',
    defaultMessage: 'Down selected element',
  },
});

/**
 * Edit definition list block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    detached: PropTypes.bool,
    index: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    onAddBlock: PropTypes.func.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onMutateBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    detached: false,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
    };
    this.onSelectListItem = this.onSelectListItem.bind(this);
    this.onChangeTerm = this.onChangeTerm.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onInsertElement = this.onInsertElement.bind(this);
    this.onDeleteElement = this.onDeleteElement.bind(this);
    this.onMoveElementUp = this.onMoveElementUp.bind(this);
    this.onMoveElementDown = this.onMoveElementDown.bind(this);
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.definition_list) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        definition_list: _.cloneDeep(initialListItems),
      });
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    // 使ってなさそうだが、念のため残しておく。流用時要検討
    if (!nextProps.data.definition_list) {
      this.props.onChangeBlock(nextProps.block, {
        ...nextProps.data,
        definition_list: _.cloneDeep(initialListItems),
      });
    }
  }

  /**
   * Select item handler
   * @method onSelectListItem
   * @param {Number} index Index.
   * @returns {undefined}
   */
  onSelectListItem(index) {
    this.setState({ selected: index });
  }

  /**
   * Change dt element handler
   * @method onChangeElement
   * @param {Number} index Index.
   * @param {Object} editorState Editor state.
   * @returns {undefined}
   */
  onChangeTerm(index, termEditorState) {
    const definition_list = { ...this.props.data.definition_list };
    definition_list.items[index].elements[0].value = convertToRaw(
      termEditorState.getCurrentContent(),
    );
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      definition_list,
    });
  }

  /**
   * Change dd element handler
   * @method onChangeElement
   * @param {Number} index Index.
   * @param {Object} editorState Editor state.
   * @returns {undefined}
   */
  onChangeDescription(index, descriptionEditorState) {
    const definition_list = { ...this.props.data.definition_list };
    definition_list.items[index].elements[1].value = convertToRaw(
      descriptionEditorState.getCurrentContent(),
    );
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      definition_list,
    });
  }

  /**
   * Insert element handler method.
   * @method onInsertElement
   * @returns {undefined}
   */
  onInsertElement() {
    const definition_list = this.props.data.definition_list;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      definition_list: {
        ...definition_list,
        items: [
          ...definition_list.items.slice(0, this.state.selected + 1),
          emptyElement(),
          ...definition_list.items.slice(this.state.selected + 1),
        ],
      },
    });
    this.setState({
      selected: this.state.selected + 1,
    });
  }

  /**
   * Delete element handler method.
   * @method onDeleteElement
   * @returns {undefined}
   */
  onDeleteElement() {
    const definition_list = this.props.data.definition_list;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      definition_list: {
        ...definition_list,
        items: [
          ...definition_list.items.slice(0, this.state.selected),
          ...definition_list.items.slice(this.state.selected + 1),
        ],
      },
    });
  }

  /**
   * Move element up handler method.
   * @method onMoveElementUp
   * @returns {undefined}
   */
  onMoveElementUp() {
    const definition_list = this.props.data.definition_list;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      definition_list: {
        ...definition_list,
        items: [
          ...definition_list.items.slice(0, this.state.selected - 1),
          definition_list.items[this.state.selected],
          definition_list.items[this.state.selected - 1],
          ...definition_list.items.slice(this.state.selected + 1),
        ],
      },
    });
  }

  /**
   * Move element down handler method.
   * @method onMoveElementDown
   * @returns {undefined}
   */
  onMoveElementDown() {
    const definition_list = this.props.data.definition_list;
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      definition_list: {
        ...definition_list,
        items: [
          ...definition_list.items.slice(0, this.state.selected),
          definition_list.items[this.state.selected + 1],
          definition_list.items[this.state.selected],
          ...definition_list.items.slice(this.state.selected + 2),
        ],
      },
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }

    return (
      <div className={cx('block table', { selected: this.props.selected })}>
        {this.props.selected && (
          <div className="toolbar">
            <Button.Group>
              <Button
                icon
                basic
                onClick={this.onInsertElement}
                title={this.props.intl.formatMessage(messages.insertElement)}
                aria-label={this.props.intl.formatMessage(
                  messages.insertElement,
                )}
              >
                <Icon name={plusSVG} size="24px" />
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                icon
                basic
                onClick={this.onDeleteElement}
                disabled={
                  this.props.data.definition_list &&
                  this.props.data.definition_list.items.length === 1
                }
                title={this.props.intl.formatMessage(messages.deleteElement)}
                aria-label={this.props.intl.formatMessage(
                  messages.deleteElement,
                )}
              >
                <Icon name={minusSVG} size="24px" />
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                icon
                basic
                disabled={this.state.selected === 0}
                onClick={this.onMoveElementUp}
                title={this.props.intl.formatMessage(messages.onMoveElementUp)}
                aria-label={this.props.intl.formatMessage(
                  messages.onMoveElementUp,
                )}
              >
                <Icon name={upSVG} size="24px" />
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                icon
                basic
                disabled={
                  this.props.data.definition_list &&
                  this.props.data.definition_list.items.length - 1 ===
                    this.state.selected
                }
                onClick={this.onMoveElementDown}
                title={this.props.intl.formatMessage(
                  messages.onMoveElementDown,
                )}
                aria-label={this.props.intl.formatMessage(
                  messages.onMoveElementDown,
                )}
              >
                <Icon name={downSVG} size="24px" />
              </Button>
            </Button.Group>
          </div>
        )}
        {this.props.data.definition_list && (
          <List>
            {_.map(this.props.data.definition_list.items, (item, index) => (
              <List.Item
                key={item.key}
                className={
                  index === this.state.selected && this.props.selected
                    ? 'selected'
                    : ''
                }
              >
                <ListItem
                  block={this.props.block}
                  term_value={item.elements[0].value}
                  description_value={item.elements[1].value}
                  index={index}
                  onSelectListItem={this.onSelectListItem}
                  selected={index === this.state.selected}
                  isListBlockSelected={this.props.selected}
                  onChangeTermElement={this.onChangeTerm}
                  onChangeDescriptionElement={this.onChangeDescription}
                />
              </List.Item>
            ))}
          </List>
        )}
      </div>
    );
  }
}

export default injectIntl(Edit);
