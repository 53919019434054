import React, { Component } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { asyncConnect } from 'redux-connect';
// import json from './data.json';
// import json from './data_spec.json';
// import json from './data_spec_v2.json';
// import json from './data_spec_v3.json';
// import json from './data_spec_v4.json';
import './MainGraph.css';
import { getResouGraphData } from '../../../../actions';
import { HorizontalRule } from '../../View/Common';
import { FormattedMessage } from 'react-intl';
import { MediaQueryWidth } from '../../../../helpers/GlobalStyle/GlobalStyle';
import * as d3_scale from 'd3-scale';

/**
 * メイングラフのコンポーネント
 */
class MainGraphComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      // now: 'monthly',
      now: 'weekly',
      isSP: null,
    };
    // マージンの初期値
    // this.margin = { top: 20, right: 20, bottom: 30, left: 20 };
    this.margin = { top: 20, right: 0, bottom: 30, left: 0 };
    // 縦の高さの比率
    this.hightRatio = 0.4;
    // 線の太さ
    // this.strokeWidth = 0.8;
    this.strokeWidth = 1.3;
    this.strokeWidthForSP = 0.5;
    // 線が無効な時の色
    // this.colorDisabled = '#4d489f';
    this.colorDisabled = '#ccc';
    // X軸の色
    // this.colorXAxis = '#eeeeff';
    this.colorXAxis = '#ccc';
    // Y軸の色
    // this.colorYAxis = '#4d489f';
    this.colorYAxis = '#ddd';
    // テキストの色
    // this.colorText = '#ffffff';
    this.colorText = '#000';
    // ボタンテキストの色
    // this.buttonTextColor = '#000';
    this.buttonTextColor = '#fff';
    // createMultiLineChart にthisをバインドする
    this.createMultiLineChart = this.createMultiLineChart.bind(this);
    // updateDimensions にthisをバインドする
    this.updateDimensions = this.updateDimensions.bind(this);
    // hover に this をバインドする
    this.hover = this.hover.bind(this);
    // refreshPath に this をバインドする
    this.refreshPath = this.refreshPath.bind(this);
    // xAxis に this をバインドする
    this.xAxis = this.xAxis.bind(this);
    // yAxis に this をバインドする
    this.yAxis = this.yAxis.bind(this);
  }

  /**
   * 全ノードを削除
   * @param {object} node refで指定したSVGノード
   */
  clearAll() {
    let svg = d3.select(this.node);

    svg.selectAll('path').remove().exit();
    svg.selectAll('g').remove().exit();
  }

  /**
   * dates軸を取得
   * @param {*} columns
   */
  getDates(columns) {
    if (this.state.now === 'monthly') {
      // 月単位
      return columns.map(d3.utcParse('%Y-%m'));
    } else {
      // 週単位
      return columns.map(d3.utcParse('%Y-%V'));
    }
  }

  /**
   * グラフ表示用のデータを作成
   * @param {*} sourceData
   */
  makeData(sourceData) {
    const data = sourceData[this.state.now]?.data ?? [];

    const columns = sourceData[this.state.now]?.columns ?? [];

    return {
      y: '% Name',
      series: data.map((d) => {
        return {
          name: d.name,
          url: d.url,
          color: d.color,
          displayByLog10: d.items.map((k) => {
            const v = +k;
            if (!isNaN(v)) {
              return Math.round(10 ** v);
            } else {
              return null;
            }
          }),
          values: d.items.map((k) => +k),
        };
      }),
      dates: this.getDates(columns),
    };
  }

  /**
   * 表示エリアのサイズを取得
   */
  getRect() {
    // 要素の位置座標を取得
    const clientRect = this.node.getBoundingClientRect();
    // ページの左端から、要素の左端までの距離
    const px = window.pageXOffset + clientRect.left;
    // ページの上端から、要素の上端までの距離
    const py = window.pageYOffset + clientRect.top;
    const width =
      window.innerWidth - px - (this.state.isSP ? 0 : this.margin.right);
    const height = width * this.hightRatio;
    return {
      width,
      height,
    };
  }

  // Hover時の動作
  hover(svg, dot, path, colorText, colorDisabled, data, x, y) {
    const moved = () => {
      d3.event.preventDefault();
      if (data.series && data.series.length > 0) {
        const clientRect = this.node.getBoundingClientRect();
        // ページの左端から、要素の左端までの距離
        const px = window.pageXOffset + clientRect.left;
        // ページの上端から、要素の上端までの距離
        const py = window.pageYOffset + clientRect.top;
        const ym = y.invert(d3.event.pageY - py);
        const xm = x.invert(d3.event.pageX - px);
        const i1 = d3.bisectLeft(data.dates, xm, 1);
        const i0 = i1 - 1;
        const i = xm - data.dates[i0] > data.dates[i1] - xm ? i1 : i0;
        const s = data.series.reduce((a, b) =>
          Math.abs(a.values[i] - ym) < Math.abs(b.values[i] - ym) ? a : b,
        );
        path
          .attr('stroke', (d) => (d === s ? d.color : colorDisabled))
          .filter((d) => d === s)
          .raise();
        dot.attr(
          'transform',
          `translate(${x(data.dates[i])},${y(s.values[i])})`,
        );
        dot
          .select('a')
          .attr('xlink:href', s.url)
          .text(`(${s.displayByLog10[i]}) ${s.name}`);
      }
    };

    const entered = () => {
      path.style('mix-blend-mode', null);
      dot.attr('display', null);
    };

    const left = () => {
      path.style('mix-blend-mode', null).attr('stroke', (d) => d.color);
      dot.attr('display', 'none');
    };

    if ('ontouchstart' in document)
      svg
        .style('-webkit-tap-highlight-color', 'transparent')
        .on('touchmove', moved)
        .on('touchstart', entered)
        .on('touchend', left);
    else
      svg
        .on('mousemove', moved)
        .on('mouseenter', entered)
        .on('mouseleave', left);
  }

  /**
   * パスのリフレッシュ
   * @param {*} dot
   * @param {*} path
   * @param {*} line
   * @param {*} xAxisGroup
   * @param {*} yAxisGroup
   * @param {*} switchGraph
   * @param {*} svg
   * @param {*} sourceData
   */
  refreshPath(
    dot,
    path,
    line,
    xAxisGroup,
    yAxisGroup,
    switchGraph,
    svg,
    sourceData,
  ) {
    const data = this.makeData(sourceData);
    const { width, height } = this.getRect();
    const x = d3
      .scaleUtc()
      .domain(d3.extent(data.dates))
      .range([this.margin.left, width - this.margin.right]);
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data.series, (d) => d3.max(+d.values))])
      .nice()
      .range([height - this.margin.bottom, this.margin.top]);
    path
      .data(data.series)
      .transition()
      .duration(1000)
      .style('mix-blend-mode', 'normal')
      .attr('d', (d) => line(+d.values))
      .attr('stroke', (d) => d.color);
    xAxisGroup
      .transition()
      .duration(1000)
      // .style('font', '10px georgia')
      // .style('color', this.colorXAxis)
      .call(this.xAxis, x, width, height, data);
    yAxisGroup
      .transition()
      .duration(1000)
      // .style('font', '10px georgia')
      // .style('color', this.colorYAxis)
      .call(this.yAxis, y, width, height, data);
    line
      .x((d, i) => x(data.dates[i]))
      .y((d) => y(d))
      .curve(d3.curveCatmullRom.alpha(0.7));
    switchGraph.text(`Switch Graph : ${this.state.now}`);
    dot.append('circle').attr('r', 2.5).attr('fill', this.colorText);
    dot
      .append('text')
      .style('font', '10px sans-serif')
      .attr('fill', this.colorText)
      .attr('text-anchor', 'middle')
      .attr('y', -8)
      .append('a')
      .attr('fill', this.colorText);
    // svg.call(
    //   this.hover,
    //   dot,
    //   path,
    //   this.colorText,
    //   this.colorDisabled,
    //   data,
    //   x,
    //   y,
    // );
  }

  /**
   * X軸の描画
   * @param {*} g
   * @param {*} x
   * @param {*} width
   * @param {*} height
   * @param {*} data
   */
  xAxis(g, x, width, height, data) {
    g.attr('transform', `translate(0,${height - this.margin.bottom})`)
      // .attr('style', `color: ${this.colorXAxis}`)
      .style('font', '12px georgia')
      .style('color', this.colorXAxis)
      .attr('class', 'axisX')
      .call(
        d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickSizeOuter(0),
      );
  }

  /**
   * Y軸の描画
   * @param {*} g
   * @param {*} y
   * @param {*} width
   * @param {*} height
   * @param {*} data
   */
  yAxis(g, y, width, height, data) {
    g.attr('transform', `translate(${this.margin.left},0)`)
      // .attr('style', `color: ${this.colorYAxis}`)
      .style('font', '10px georgia')
      .style('color', this.colorYAxis)
      .call(d3.axisLeft(y))
      .call((g) =>
        g
          .select('.tick:last-of-type text')
          // .clone()
          .attr('x', 3)
          .attr('text-anchor', 'start')
          .attr('font-weight', 'bold')
          .text(data.y),
      )
      .call(
        d3
          .axisLeft(y)
          .ticks(10)
          .tickSize(-width + this.margin.left + this.margin.right),
      )
      .call((g) => g.select('.domain').remove())
      .call((g) => g.selectAll('.tick text').remove());
  }

  pathTween(values, line) {
    var interpolate = d3
      .scaleQuantile()
      .domain([0, 1])
      .range(d3.range(1, values.length + 1));
    return function (t) {
      return line(values.slice(0, interpolate(t)));
    };
  }

  /**
   * チャートを作成
   * @param {*} title
   * @param {*} data
   */
  createMultiLineChart(title, sourceData) {
    if (sourceData?.monthly && sourceData?.weekly) {
      // ------------------------------- settings
      const { width, height } = this.getRect();

      // ------------------------------- d3 init

      // 表示を一旦クリア
      this.clearAll();

      // 表示用データの用意
      const data = this.makeData(sourceData);

      // -------------------------------

      // x軸の用意
      const x = d3
        .scaleUtc()
        .domain(d3.extent(data.dates))
        .range([this.margin.left, width - this.margin.right]);

      // Y軸の用意
      const y = d3
        .scaleLinear()
        .domain([0, d3.max(data.series, (d) => d3.max(d.values))])
        .nice()
        .range([height - this.margin.bottom, this.margin.top]);

      // グラフの描画 (曲線)
      const line = d3
        .line()
        .defined((d) => !isNaN(d))
        .x((d, i) => x(data.dates[i]))
        .y((d) => y(d))
        .curve(d3.curveCatmullRom.alpha(0.7));

      // ------------------------------- main

      const chart = (node, x, y) => {
        let svg = d3.select(node);

        svg
          .attr('height', height)
          .attr('width', width)
          .style('overflow', 'hidden');

        const xAxisGroup = svg
          .append('g')
          .call(this.xAxis, x, width, height, data);

        const yAxisGroup = svg
          .append('g')
          .call(this.yAxis, y, width, height, data);

        const path = svg
          .append('g')
          .attr('fill', 'none')
          .attr(
            'stroke-width',
            this.state.isSP ? this.strokeWidthForSP : this.strokeWidth,
          )
          .attr('stroke-linejoin', 'round')
          .attr('stroke-linecap', 'round')
          .selectAll('path')
          .data(data.series)
          .join('path')
          .style('mix-blend-mode', 'normal')
          // .attr('d', (d) => line(d.values))
          .attr('stroke', (d) => d.color);

        path
          .transition()
          .duration(800)
          .attrTween('d', (d) => this.pathTween(d.values, line));

        // タイトルの表示
        // const text = svg
        //   .append('g')
        //   .append('text')
        //   .attr('x', 20)
        //   .attr('y', 30)
        //   .text(title)
        //   .style('font', '18px georgia')
        //   .attr('font-weight', 'bold')
        //   .attr('fill', this.colorText);

        const dot = svg.append('g').attr('display', 'none');

        dot.append('circle').attr('r', 2.5).attr('fill', this.colorText);

        dot
          .append('text')
          .style('font', '10px sans-serif')
          .attr('fill', this.colorText)
          .attr('text-anchor', 'middle')
          .attr('y', -8)
          .append('a')
          .attr('fill', this.colorText);

        const nowGraph = svg
          .append('g')
          .append('text')
          .attr('x', 20)
          // .attr('y', 49)
          .attr('y', 44)
          .text(`Data : ${this.state.now}`)
          .style('font', '12px georgia')
          .attr('font-weight', 'normal')
          .attr('fill', this.colorText);

        const switchGraph = svg.append('g');
        //
        switchGraph
          .append('rect')
          .attr('x', 115)
          // .attr('y', 35)
          .attr('y', 30)
          .attr('width', 110)
          .attr('height', 20)
          .attr('rx', 2)
          .attr('ry', 2)
          .style('cursor', 'pointer')
          .attr('fill', this.colorText)
          .on('click', () => {
            if (this.state.now === 'monthly') {
              this.setState({ now: 'weekly' });
            } else {
              this.setState({ now: 'monthly' });
            }
            this.refreshPath(
              dot,
              path,
              line,
              xAxisGroup,
              yAxisGroup,
              switchGraph,
              svg,
              sourceData,
              x,
              y,
            );
          });

        switchGraph
          .append('text')
          .attr('x', 150)
          // .attr('y', 49)
          .attr('y', 44)
          .text(this.state.now === 'monthly' ? 'To weekly' : 'To monthly')
          .style('font', '12px georgia')
          .attr('font-weight', 'normal')
          .attr('fill', this.buttonTextColor)
          .style('cursor', 'pointer')
          .on('click', () => {
            if (this.state.now === 'monthly') {
              this.setState({ now: 'weekly' });
            } else {
              this.setState({ now: 'monthly' });
            }
            this.refreshPath(
              dot,
              path,
              line,
              xAxisGroup,
              yAxisGroup,
              switchGraph,
              svg,
              sourceData,
              x,
              y,
            );
          });

        switchGraph
          .append('text')
          .attr('x', 128)
          .attr('y', 44)
          .attr('font-family', 'Icons')
          .attr('font-size', '12px')
          .attr('fill', this.buttonTextColor)
          .text(function (d) {
            return '\uf362';
          });

        // TODO: hover部分の動き
        svg.call(
          this.hover,
          dot,
          path,
          this.colorText,
          this.colorDisabled,
          data,
          x,
          y,
        );

        return svg.node();
      };

      chart(this.node, x, y);

      // ------------------------------- functions
    }
  }

  setDimensions() {
    if (window !== undefined) {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight,
        isSP: window.innerWidth <= MediaQueryWidth.SP,
      });
    }
  }

  /**
   * グラフのサイズを変更
   */
  updateDimensions() {
    this.setDimensions();
    this.createMultiLineChart(
      this.props.resouGraphData?.title,
      this.props.resouGraphData?.data,
    );
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    this.props.getResouGraphData();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate() {
    this.createMultiLineChart(
      this.props.resouGraphData?.title,
      this.props.resouGraphData?.sourceData,
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props?.resouGraphData?.sourceData?.monthly &&
      this.props?.resouGraphData?.sourceData?.weekly
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div className="MultiLineChart">
        <HorizontalRule></HorizontalRule>
        <h2>
          <FormattedMessage
            id="ResOU Trend History 25"
            defaultMessage="ResOU Trend History 25"
          />
        </h2>
        {/* <div
          ref={rect => (this.rect = rect)}
          style={{ lineHeight: 0, fontSize: 0, height: '1px' }}
        ></div> */}
        <div>
          <svg ref={(node) => (this.node = node)}></svg>
        </div>
      </div>
    );
  }
}

MainGraphComponent.propTypes = {
  resouGraphData: PropTypes.shape({
    title: PropTypes.string,
    sourceData: PropTypes.shape({
      monthly: PropTypes.object,
      weekly: PropTypes.object,
    }),
  }),
};

/**
 * コンテナ作成
 */
const MainGraph = connect(
  (state, props) => {
    return {
      //TODO: テストが終わったら元に戻す
      resouGraphData: {
        title: state.resouGraphData?.result?.name,
        // title: 'ResOU Trend History',
        // title: json?.name,
        sourceData: {
          monthly: state.resouGraphData?.result?.monthly,
          // monthly: json?.monthly,
          weekly: state.resouGraphData?.result?.weekly,
          // weekly: json?.weekly,
        },
      },
    };
  },
  (dispatch, props) => {
    return {
      getResouGraphData: () => {
        const locale = 'ja';
        dispatch(getResouGraphData(locale));
      },
    };
  },
)(MainGraphComponent);

export default MainGraph;
