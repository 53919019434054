/**
 * トップページかどうかを判定
 * @param {string} contentType @typeの値
 */
export function isTop(contentType) {
  return contentType === 'LRF';
}

/**
 * トップページかどうかを判定
 * @param {string} contentType @typeの値
 */
export function isTopByLocation(pathname) {
  // console.log({ isTopByLocation: pathname });
  if(pathname === '/ja' || pathname === '/en') {
    return true;
  }
  return false;
}
