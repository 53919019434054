/**
 * SeeMoreButton component for 'See more...' collection
 * "もっと見る"ボタンの実装
 * @module components/theme/Element/SeeMoreButton/SeeMoreButton
 */

import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import './SeeMoreButton.css';

const messages = defineMessages({
  readMore: {
    id: 'See More',
    defaultMessage: 'See More',
  },
});

/**
 * SeeMoreButton component class.
 * @function SeeMoreButton
 * @returns {string} Markup of the component.
 */
const SeeMoreButton = ({
  intl,
  apiPath,
  loadContent,
  bStart,
  bSize,
  hasNext,
}) => {
  return (
    <div className="SeeMoreButtonWrapper">
      <button
        className={hasNext ? 'SeeMoreButton' : 'SeeMoreButton hidden'}
        type="button"
        onClick={() => {
          loadContent(apiPath, bSize, bStart, hasNext);
        }}
      >
        {intl.formatMessage(messages.readMore)}
      </button>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SeeMoreButton.propTypes = {
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
  apiPath: PropTypes.string.isRequired,
  loadContent: PropTypes.func.isRequired,
  bSize: PropTypes.number,
  bStart: PropTypes.number,
  hasNext: PropTypes.bool,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
SeeMoreButton.defaultProps = {
  bSize: 4,
  bStart: 0,
  hasNext: true,
};

export default injectIntl(SeeMoreButton, {});
