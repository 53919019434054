/**
 * Search component.
 * @module components/theme/Search/Search
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Helmet } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';
import { asyncConnect } from 'redux-connect';
import { Portal } from 'react-portal';
import { Container } from 'semantic-ui-react';
import qs from 'query-string';
import './Search.css';
import { searchContent, popularArticleList } from '../../../../actions';
import { Toolbar } from '@plone/volto/components';
import { SearchWidgetForSearch } from '../../../../components';
// import { Waypoint } from 'react-waypoint';
import { Locale } from '../../../../helpers';
import _ from 'lodash';
import { SearchContent } from '../../../../components/';
import { InfiniteScroll } from '../../../../components/index';
import { ExternalLink } from '../../../../components/index';
import { flattenToAppURL } from '@plone/volto/helpers';
export const toSearchOptions = (
  searchableText,
  subject,
  path,
  b_start,
  locale,
  sort,
) => {
  // console.info('toSearchOptions', {
  //   searchableText,
  //   subject,
  //   path,
  //   b_start,
  //   locale,
  // });
  return {
    ...(searchableText && { SearchableText: searchableText }),
    ...(subject && {
      Subject: subject,
    }),
    ...(path && {
      path: path,
    }),
    ...(!isNaN(b_start) && {
      b_start: b_start,
    }),
    ...(locale && {
      lang: locale,
    }),
    ...(sort && {
      sort: sort,
    }),
  };
};

/**
 * Search class.
 * @class SearchComponent
 * @extends Component
 */
class Search extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    searchContent: PropTypes.func.isRequired,
    searchableText: PropTypes.string,
    subject: PropTypes.string,
    path: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
    pathname: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    items: null,
    searchableText: null,
    subject: null,
    path: null,
  };

  /**
   * Component will mount
   * @method componentWillMount
   * @returns {undefined}
   */
  UNSAFE_componentWillMount() {
    // 無限スクロールとの相性悪く現時点ではコメントアウト
    // this.doSearch(
    //   this.props.searchableText,
    //   this.props.subject,
    //   this.props.path,
    // );
  }

  componentDidMount() {
    this.props.popularArticleList(this.props.locale, 0);
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    // 無限スクロールとの相性悪く現時点ではコメントアウト
    // if (
    //   nextProps.searchableText !== this.props.searchableText ||
    //   nextProps.subject !== this.props.subject
    // ) {
    //   this.doSearch(
    //     nextProps.searchableText,
    //     nextProps.subject,
    //     this.props.path,
    //   );
    // }
  };

  /**
   * Search based on the given searchableText, subject and path.
   * @method doSearch
   * @param {string} searchableText The searchable text string
   * @param {string} subject The subject (tag)
   * @param {string} path The path to restrict the search to
   * @returns {undefined}
   */
  doSearch(searchableText, subject, path, b_start = 0, locale, sort) {
    this.props.searchContent(
      '',
      toSearchOptions(searchableText, subject, path, b_start, locale, sort),
    );
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    // console.log({ Search: this.props });
    return (
      <Container id="page-search" className="Search">
        <Helmet title="Search" />
        {/* {console.log(this.props.locale)} */}
        <div className="container">
          <article id="content">
            {/* もともとSearchにあったコード */}
            {/* <header>
              <h1 className="documentFirstHeading">
                {this.props.searchableText ? (
                  <FormattedMessage
                    id="Search results for {term}"
                    defaultMessage="Search results for {term}"
                    values={{
                      term: <q>{this.props.searchableText}</q>,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="Search results"
                    defaultMessage="Search results"
                  />
                )}
              </h1>
              <SearchTags />
            </header> */}

            <SearchWidgetForSearch
              pathname={this.props.location.pathname}
              location={this.props.location}
              locale={this.props.locale}
              loadingRef={this.myRef.current}
            />

            {this.props.searchableText &&
            this.props.items &&
            this.props.items.length === 0 ? (
              this.props.locale === 'ja' ? (
                <div className="search-result">
                  <p>
                    {this.props.searchableText}の検索結果{' '}
                    <span className="total">{this.props.total}件</span>
                  </p>
                  {this.props.searchableText &&
                  this.props.items?.length === 0 ? (
                    <p>
                      {this.props.searchableText}
                      に該当する項目はありませんでした
                    </p>
                  ) : null}
                </div>
              ) : (
                <div className="search-result">
                  <p>
                    Search results for {this.props.searchableText}{' '}
                    <span className="total">{this.props.total} results.</span>
                  </p>
                  {this.props.searchableText &&
                  this.props.items?.length === 0 ? (
                    <p>
                      {/* {this.props.searchableText} */}
                      No results were found.
                    </p>
                  ) : null}
                </div>
              )
            ) : null}

            <SearchContent items={this.props.items}></SearchContent>

            <InfiniteScroll
              ref={this.myRef}
              onInit={() => {
                this.doSearch(
                  this.props.searchableText,
                  this.props.subject,
                  this.props.path,
                  this.props.b_start + this.props.b_size,
                  this.props.locale,
                );
              }}
              onEnter={() => {
                this.doSearch(
                  this.props.searchableText,
                  this.props.subject,
                  this.props.path,
                  this.props.b_start + this.props.b_size,
                  this.props.locale,
                );
              }}
              next_b_start={this.props.b_start + this.props.b_size}
              b_size={this.props.b_size}
              total={this.props.total}
              has_next={this.props.has_next}
            ></InfiniteScroll>

            {/* <Waypoint
              onEnter={({ previousPosition, currentPosition, event }) => {
                console.log(
                  `Waypoint ${this.props.b_start} ${this.props.b_size} : ${previousPosition} ${currentPosition}`,
                );
                this.doSearch(
                  this.props.searchableText,
                  this.props.subject,
                  this.props.path,
                  this.props.b_start + this.props.b_size,
                );
              }}
              onLeave={(e) => {
                console.log({ onLeave: e });
              }}
              onPositionChange={(e) => {
                console.log({ onPositionChange: e });
              }}
              fireOnRapidScroll={true}
            ></Waypoint> */}

            {/* <div className="loading-button">
              <Button
                onClick={(e) => {
                  this.doSearch(
                    this.props.searchableText,
                    this.props.subject,
                    this.props.path,
                    this.props.b_start + this.props.b_size,
                  );
                }}
              >
                <Loader visible={this.props.has_next}></Loader> More...
              </Button>
            </div> */}

            {/* よく読まれている記事 */}

            {this.props.items && this.props.items.length === 0 ? (
              <>
                {this.props.locale === 'ja' ? (
                  <h3 className="popular-content-title">
                    よく読まれている記事
                    {/* {this.props.popularArticleTitle} */}
                  </h3>
                ) : (
                  <h3 className="popular-content-title">Popular Articles</h3>
                )}

                <section
                  id="popular-content-core"
                  className="content-core popular-content-core"
                >
                  {_.map(this.props.popularArticleItems, (item) => {
                    const url = item.url;
                    const redirect = item.redirect;
                    return (
                      <article className="item tileItem" key={item.url}>
                        <Link
                          className="summary url"
                          title={item.title}
                          to={{
                            pathname: redirect
                              ? redirect
                              : flattenToAppURL(url),
                          }}
                          target={redirect ? '_blank' : ''}
                        >
                          <div className="item-wrapper">
                            <div className="item-thumbnail">
                              <img src={item.image} alt="" />
                              {/* <img
                              src="/assets/images/feature_image_001.png"
                              alt=""
                            /> */}
                            </div>
                            <div className="item-content">
                              <h3 className="tileHeadline">{item.title}</h3>
                            </div>
                          </div>
                          <div className="visualClear" />
                          <ExternalLink link={url} redirect={redirect} />
                        </Link>
                      </article>
                    );
                  })}
                </section>
              </>
            ) : null}
          </article>
        </div>
        <Portal node={__CLIENT__ && document.getElementById('toolbar')}>
          <Toolbar
            pathname={this.props.pathname}
            hideDefaultViewButtons
            inner={<span />}
          />
        </Portal>
      </Container>
    );
  }
}

export const __test__ = connect(
  (state, props) => ({
    items: state.search.items,
    searchableText: qs.parse(props.location.search).SearchableText,
    subject: qs.parse(props.location.search).Subject,
    path: qs.parse(props.location.search).path,
    pathname: props.location.pathname,
  }),
  { searchContent },
)(Search);

export default compose(
  connect(
    (state, props) => {
      const locale = Locale.getLocale(state?.content?.data?.language?.token);
      return {
        items: state.searchResult.items,
        searchableText: qs.parse(props.location.search).SearchableText,
        subject: qs.parse(props.location.search).Subject,
        path: qs.parse(props.location.search).path,
        pathname: props.location.pathname,
        total: state.searchResult.total,
        b_start: state.searchResult.b_start,
        b_size: state.searchResult.b_size,
        has_next: state.searchResult.has_next,
        locale,
        popularArticleTitle:
          state.popularArticleList?.result?.data?.attributes?.title,
        popularArticleItems:
          state.popularArticleList?.result?.data?.attributes?.data,
      };
    },
    (dispatch, props) => {
      return {
        // searchContentは関数を返しておく
        searchContent: (url, options) => {
          dispatch(searchContent(url, options));
        },
        popularArticleList: (locale, b_start) =>
          dispatch(popularArticleList(locale, b_start)),
      };
    },
  ),
  asyncConnect([
    // ここは、コンポーネント表示前に行う状態であるが、無限スクロールとの相性が悪く一旦コメントアウト。もともとあったコード。
    // {
    //   key: 'search',
    //   promise: ({ location, store: { dispatch } }) => {
    //     console.log({ location, dispatch });
    //     dispatch(
    //       searchContent(
    //         '',
    //         toSearchOptions(
    //           qs.parse(location.search).SearchableText,
    //           qs.parse(location.search).Subject,
    //           qs.parse(location.search).path,
    //           qs.parse(location.search).b_start,
    //           qs.parse(location.search).lang,
    //         ),
    //       ),
    //     );
    //   },
    // },
  ]),
)(Search);
