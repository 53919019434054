/**
 * Edit textarea form block component for MailForm.
 * @module components/manage/Blocks/MailForm/TextAreaBlock/Edit
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import FormIDWidget from '../Common/FormIDWidget';
import LabelWidget from '../Common/LabelWidget';
import PlaceholderWidget from '../Common/PlaceholderWidget';
import RequiredWidget from '../Common/RequiredWidget';

/**
 * Edit textarea block functional component.
 * @function Edit
 * @param {Object} data property data.
 * @param {function} onChangeBlock onChangeBlock function.
 * @param {Object} block the block data.
 * @returns {string} Markup of the component.
 */
const Edit = ({ data, onChangeBlock, block }) => {
  return (
    <Form>
      <Form.Field>
        <FormIDWidget data={data} onChangeBlock={onChangeBlock} block={block} />
      </Form.Field>
      <Form.Field>
        <LabelWidget data={data} onChangeBlock={onChangeBlock} block={block} />
      </Form.Field>
      <Form.Field>
        <RequiredWidget
          data={data}
          onChangeBlock={onChangeBlock}
          block={block}
        />
      </Form.Field>
      <Form.Field>
        <PlaceholderWidget
          data={data}
          onChangeBlock={onChangeBlock}
          block={block}
        />
      </Form.Field>
    </Form>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Edit.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default injectIntl(Edit);
