import { settings } from '~/config';

export function getOrigin() {
  const location = window.location;
  if (location.origin === undefined) {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '')
    );
  } else {
    return location.origin;
  }
}

export function getLocaleFromUrl(url) {
  const vocabPath = url && url.replace(settings.apiPath, '').split('/');
  if (['ja', 'en'].includes(vocabPath[1])) {
    return vocabPath[1];
  } else {
    return '';
  }
}
