/**
 * Vocabularies actions.
 * マルチリンガル対応 based on /actions/vocabularies/vocabularies
 * @module actions/multilingualVocabularies/multilingualVocabularies
 */

import { settings } from '~/config';
import { GET_VOCABULARY } from '@plone/volto/constants/ActionTypes';

/**
 * Get vocabulary given a URL (coming from a Schema) or from a vocabulary name.
 * @function getVocabulary
 * @param {string} vocabNameOrURL Full API URL of vocabulary or vocabulary name
 * @param {string} query Only include results containing this string.
 * @param {number} start Start of result batch.
 * @returns {Object} Get vocabulary action.
 */
export function getMultilingualVocabulary(
  vocabNameOrURL,
  query = null,
  start = 0,
) {
  // In case we have a URL, we have to get the vocabulary name
  let vocabulary =
    vocabNameOrURL && vocabNameOrURL.replace(settings.apiPath, '');
  let queryString = `b_start=${start}`;
  if (query) {
    queryString = `${queryString}&title=${query}`;
  }
  return {
    type: GET_VOCABULARY,
    vocabulary: vocabNameOrURL,
    start,
    request: {
      op: 'get',
      path: `${vocabulary}?${queryString}`,
    },
  };
}
