/**
 * Related content item list by department
 * この組織の他の研究記事記事一覧コレクション
 * @module components/theme/Site/Collection/ItemListRelatedtoDepartment
 */
import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import { itemListRelatedtoDepartment } from '../../../../actions/index';
import ExtraCollection from './ExtraCollection';

const messages = defineMessages({
  itemListRelatedtoDepartment: {
    id: 'Articles from this organization',
    defaultMessage: 'Articles from this organization',
  },
});

/**
 * Preprocess data from container component to put ExtraCollection component.
 * ExtraCollectionに渡すデータを加工する中間コンポーネント
 * @class ItemListRelatedDepartment
 * @extends Component
 */
class ItemListRelatedDepartment extends Component {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs state
   */
  constructor(props) {
    super(props);
    this.state = {
      UID: '',
    };
  }

  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    UID: PropTypes.string,
    contentList: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    description: PropTypes.string,
    bStart: PropTypes.number,
    bSize: PropTypes.number,
    basNext: PropTypes.bool,
    token: PropTypes.string,
    locale: PropTypes.string,
    url: PropTypes.string,
    intl: PropTypes.objectOf(PropTypes.any),
    loadContent: PropTypes.func,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    UID: '',
    contentList: [],
    title: '',
    description: '',
    bStart: 0,
    bSize: 0,
    hasNext: false,
    token: '',
    locale: '',
    url: '',
  };

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */

  componentDidMount() {
    const path = flattenToAppURL(this.props.url);
    this.setState({ UID: this.props.UID });
    this.props.loadContent(path);
  }

  /**
   * Component did update
   * @method componentDidUpdate
   * @returns {undefined}
   */
  componentDidUpdate() {
    if (this.props.UID !== this.state.UID) {
      const path = flattenToAppURL(this.props.url);
      this.setState({ UID: this.props.UID });
      this.props.loadContent(path);
    }
  }

  render() {
    return (
      <div>
        <ExtraCollection
          contentList={this.props.contentList}
          bSize={this.props.bSize}
          loadContent={this.props.loadContent}
          bStart={this.props.bStart}
          intl={this.props.intl}
          hasNext={this.props.hasNext}
          title={this.props.title}
          description={this.props.description}
          apiPath={flattenToAppURL(this.props.url)}
        />
      </div>
    );
  }
}

/**
 * Container function component to connect Redux state, Action and child component.
 * ComponentとReduxを接続するコンテナComponent
 * @param {function} connect the function to connect to redux and component.
 * @returns {undefined}
 */
const ItemListRelatedtoDepartmentContainer = connect(
  (state, props) => ({
    UID: state.content.data.UID,
    contentList: state.itemListRelatedtoDepartment?.contentList ?? [],
    title: props.intl.formatMessage(messages.itemListRelatedtoDepartment),
    description:
      state.itemListRelatedtoDepartment?.result?.data?.attributes?.description,
    bStart:
      state.itemListRelatedtoDepartment?.result?.data?.attributes?.b_start,
    bSize: state.itemListRelatedtoDepartment?.result?.data?.attributes?.b_size,
    hasNext:
      state.itemListRelatedtoDepartment?.result?.data?.attributes?.has_next,
    token: state.userSession?.token,
    locale: state?.content?.data?.language?.token ?? 'ja',
    url: state?.content?.data?.['@id'],
    intl: props.intl,
  }),
  (dispatch, props) => {
    return {
      loadContent: (apiPath, bSize = 0, bStart = 0, hasNext = true) => {
        if (hasNext) {
          dispatch(itemListRelatedtoDepartment(apiPath, bSize + bStart));
        }
      },
    };
  },
)(ItemListRelatedDepartment);

export default injectIntl(ItemListRelatedtoDepartmentContainer, {});
