/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import { Container, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SiteHeader } from '../../../../components/';
import { getBaseUrl } from '@plone/volto/helpers';
import { Breadcrumbs } from '@plone/volto/components';
import './Header.css';

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    /** locale */
    locale: PropTypes.string,
    identity: PropTypes.object,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const path = getBaseUrl(this.props.pathname);

    return (
      <div className="Header">
        {!this.props.token ? (
          // <Anontools />
          <div></div>
        ) : (
          <div className="tools Tools">
            <Breadcrumbs pathname={path} />
          </div>
        )}

        <Segment basic className="header-wrapper headerWrapper" role="banner">
          <Container>
            <div className="header headerArea">
              {/* <div className="logo-nav-wrapper"> */}
              <div>
                <div className="logo">
                  {/* <Logo /> */}
                  <SiteHeader
                    titleImageUrl={this.props.identity?.url}
                    titleImageImg={this.props.identity?.image}
                    titleImageLabel={this.props.identity?.title}
                  ></SiteHeader>
                </div>
                {/* <Navigation pathname={this.props.pathname} /> */}
              </div>
            </div>
          </Container>
        </Segment>
      </div>
    );
  }
}

export default connect((state) => {
  const locale = state?.content?.data?.language?.token ?? 'ja';
  return {
    token: state.userSession.token,
    identity:
      state.siteMainInfo?.result?.data?.attributes?.locale?.[locale]?.assets
        ?.identity,
  };
})(Header);
