import React from 'react';
import { MicrosoftClaritySetting } from 'constants/Setting';
import { Helmet } from '@plone/volto/helpers';

/**
 * Microsoft Clarity出力タグ
 * @returns
 */
export const MicrosoftClarity = () => {
  if (__CLIENT__) {
    return (
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: `
            (function(c,l,a,r,i,t,y){
                c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
                t=l.createElement(r);
                t.async=1;
                t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];
                y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${MicrosoftClaritySetting.MICROSOFT_CLARITY_ID}");`,
          },
        ]}
      />
    );
  } else {
    return null;
  }
};
