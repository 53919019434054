/**
 * Related department listing action
 * {apiPath}の記事と同じ組織の記事を取得するaction
 * @module actions/itemListRelatedtoDepartment/itemListRelatedtoDepartment
 */
import {
  GET_ITEM_LIST_RELATEDTO_DEPARTMENT,
  GET_ITEM_LIST_RELATEDTO_DEPARTMENT_CLEAR,
} from '../../constants/ActionTypes';

export function itemListRelatedtoDepartment(apiPath, bStart = 0) {
  const safeApiPath = apiPath || 'ja';
  return {
    type: GET_ITEM_LIST_RELATEDTO_DEPARTMENT,
    request: {
      op: 'get',
      path: `/${safeApiPath}/@item-list-related-department?b_start=${bStart}`,
    },
    bStart: bStart,
  };
}

export function itemListRelatedtoDepartmentClear() {
  return {
    type: GET_ITEM_LIST_RELATEDTO_DEPARTMENT_CLEAR,
  };
}
