import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '../Icon/Icon';
import './TermContent.css';
import { GlobalStyle } from '../../../../helpers/';
import {
  changeCustomDimmer,
  displayTermContent,
} from '../../../../actions/index';

/**
 * TermContent
 */
const TermContent = ({ now_id, title, description, visible, close }) => {
  return (
    <div
      id="term-content"
      className={
        visible
          ? 'TermContent term-content visible'
          : 'TermContent term-content'
      }
      data-now-id={now_id}
    >
      <span
        className="term-content-close"
        onClick={() => {
          close();
        }}
        onKeyDown={() => {
          close();
        }}
        role="button"
        tabIndex={0}
      >
        <Icon
          icon="close"
          size={GlobalStyle.StyleConfig.menuIconSizeSmall}
        ></Icon>
      </span>
      <h3 id="term-content-title">{title}</h3>
      <p id="term-content-description">{description}</p>
    </div>
  );
};

TermContent.propTypes = {
  now_id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  visible: PropTypes.bool,
};

/**
 * コンテナ
 */
const TermContentContainer = connect(
  (state, props) => {
    return {
      visible: state.termContent?.visible,
      now_id: state.termContent?.now_id,
      title: state.termContent?.now_title,
      description: state.termContent?.now_description,
    };
  },
  (dispatch, props) => {
    return {
      close: () => {
        dispatch(displayTermContent(false));
        dispatch(changeCustomDimmer(false));
      },
    };
  },
)(TermContent);

export default TermContentContainer;
