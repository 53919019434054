import _ from 'lodash';
import {
  MANAGE_TERM_CONTENT,
  NOW_TERM_CONTENT,
  DISPLAY_TERM_CONTENT,
} from '../../constants/ActionTypes';

const initialState = {
  now_id: '',
  now_title: '',
  now_description: '',
  // terms: {},
  visible: false,
};

/**
 * Custom Dimmer Reducer
 * @param {*} state
 * @param {*} action
 */
export default function termContent(state = initialState, action = {}) {
  switch (action.type) {
    // case MANAGE_TERM_CONTENT:
    //   return {
    //     ...state,
    //     terms: action.terms,
    //   };
    case NOW_TERM_CONTENT:
      return {
        ...state,
        now_id: action.now_id,
        now_title: action.title,
        now_description: action.description,
      };
    case DISPLAY_TERM_CONTENT:
      return {
        ...state,
        visible: action.visible,
      };
    default:
      return state;
  }
}
