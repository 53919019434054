/**
 * Feature Main Folder view component.
 * @module components/theme/View/FeatureMainFolderView
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { FeatureFolderBody } from './FeatureFolderView';
import {
  Helmet,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  flattenToAppURL,
} from '@plone/volto/helpers';
import { HelmetTitle, HorizontalRule, LabelFeature } from './Common';
import { Link } from 'react-router-dom';
import './FeatureFolderView.css';
import './FeatureMainFolderView.css';
import _ from 'lodash';
import { connect } from 'react-redux';
// import { Waypoint } from 'react-waypoint';
// import { Loader } from '../../../helpers';
import { featureListForTop } from '../../../actions/';
import { useMediaQuery } from 'react-responsive';
import Masonry from 'react-masonry-css';
import {
  MediaQueryConfig,
  StyleConfig,
} from '../../../helpers/GlobalStyle/GlobalStyle';
import { Icon, InfiniteScroll } from '../../../components/index';
import { Content } from '../../../helpers';
import { blocks } from '~/config';
import { ExternalLink } from '../../index';
import { FolderTitle, FolderLeadSentence } from './Common';

const messages = defineMessages({});

export const FeatureFolderMainInnerBody = injectIntl(({ content, intl }) => {
  // const blocksFieldname = getBlocksFieldname(content);
  const blocksFieldname = 'blocks';
  // const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const blocksLayoutFieldname = 'layout';

  function isPickup(items) {
    return content[blocksLayoutFieldname]?.items?.length > 0;
  }

  return (
    <div>
      {_.map(content[blocksLayoutFieldname]?.items, (block) => {
        const blockType =
          blocks.blocksConfig[content[blocksFieldname]?.[block]?.['@type']];
        const isTitle = blockType?.['id'] === 'title';
        if (isTitle) {
          return <div key={block}></div>;
        }
        const Block = blockType?.['view'] || null;
        return Block !== null ? (
          <Block
            key={block}
            id={block}
            properties={content}
            data={content[blocksFieldname][block]}
          />
        ) : (
          <div key={block}>
            {intl.formatMessage(messages.unknownBlock, {
              block: content[blocksFieldname]?.[block]?.['@type'],
            })}
          </div>
        );
      })}
    </div>
  );
});

/**
 * 一覧で表示するコンポーネント
 * @param {*} param0
 */
export const FeatureFolderMainBodyHasBlocks = ({ content }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  const breakpointSubItems = {
    default: 4,
    1200: 3,
    1100: 2,
  };
  const [visibleBody, setVisibleBody] = useState(false);
  return (
    <div className="FeatureFolderMainBodyHasBlocks featureFolderBody">
      <div className="FeatureFolderMainBodyHasBlocks-inner">
        <div className="labelFeatureWrapper">
          <LabelFeature className="labelFeature" />
        </div>
        <h2 className="title">{content.title}</h2>
        <div className="featureFolderBodyInner">
          {/* Header */}
          <div className="fheader">
            {isSP
              ? content.image_sp && (
                  <img
                    className="image"
                    src={flattenToAppURL(content?.image_sp ?? '')}
                    alt=""
                  />
                )
              : content.image_pc && (
                  <img
                    className="image"
                    src={flattenToAppURL(content?.image_pc ?? '')}
                    alt=""
                  />
                )}
            <div className="headerInner">
              <h3 className="subtitle">{content.subtitle}</h3>
              <div className="lead_sentence">{content.lead_sentence}</div>
            </div>
          </div>

          {/* Body */}
          <div className={visibleBody ? 'body show' : 'body hide'}>
            {/* {isSP ? null : <HorizontalRule className="hr" />} */}
            <HorizontalRule className="secondary" />
            <FeatureFolderMainInnerBody content={content} />
          </div>

          {/* 詳細を読むボタン */}
          <div className="read-more">
            <button
              onClick={(e) => {
                setVisibleBody(!visibleBody);
              }}
            >
              {visibleBody ? (
                <span>
                  <Icon
                    icon="angle up"
                    size={StyleConfig.menuIconSize}
                    color={StyleConfig.iconColorLightUniversity}
                  ></Icon>{' '}
                  <FormattedMessage id="Close" defaultMessage="Close" />
                </span>
              ) : (
                <span>
                  <Icon
                    icon="angle down"
                    size={StyleConfig.menuIconSize}
                    color={StyleConfig.iconColorLightUniversity}
                  ></Icon>{' '}
                  <FormattedMessage id="Read More" defaultMessage="Read More" />
                </span>
              )}
            </button>
          </div>
        </div>
        {/* <div className="sub-items">
          {_.map(content.items, (item) => {
            return (
              <div className="sub-item" key={item?.url}>
                <Link className="sub-item-link" to={item?.url}>
                  <div className="sub-item-image">
                    <img src={item?.image} alt="" />
                  </div>
                  <h3>{item?.title}</h3>
                </Link>
              </div>
            );
          })}
        </div> */}
        <Masonry
          breakpointCols={breakpointSubItems}
          className="sub-items"
          columnClassName=""
        >
          {_.map(content.items, (item) => {
            const url = item?.url;
            const redirect = item?.redirect;
            return (
              <div key={item?.url} className="sub-item">
                <Link
                  className="sub-item-link"
                  to={{ pathname: redirect ? redirect : flattenToAppURL(url) }}
                  target={redirect ? '_blank' : ''}
                >
                  <div className="sub-item-image">
                    <img src={item?.image} alt="" />
                  </div>
                  <h3>
                    {item?.title}
                    <ExternalLink link={url} redirect={redirect} />
                  </h3>
                </Link>
              </div>
            );
          })}
        </Masonry>
      </div>
    </div>
  );
};

/**
 * 一覧で表示するコンポーネント
 * @param {*} param0
 */
export const FeatureFolderMainBody = ({ content }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });

  const [visibleBody, setVisibleBody] = useState(false);
  return (
    <div className="FeatureFolderMainBody featureFolderBody">
      {isSP ? (
        <div className="sp">
          <Link to={content?.url} className="wrapper-body">
            <div className="wrapper-body-inner">
              <div className="titleHasNoBlock">
                <div className="labelFeature-wrapper">
                  <LabelFeature className="labelFeature" />
                </div>
                <h2>{content.title}</h2>
              </div>
              {content.image_sp ? (
                <img
                  className="image"
                  src={flattenToAppURL(content?.image_sp ?? '')}
                  alt=""
                />
              ) : null}
              <h3 className="subtitle">a{content.subtitle}</h3>
              <div className="lead_sentence">{content.lead_sentence}</div>
            </div>
          </Link>
        </div>
      ) : (
        <div className="pc">
          <Link to={content?.url} className="wrapper-body">
            <div className="wrapper-body-inner">
              {content.image_pc ? (
                <img
                  className="image"
                  src={flattenToAppURL(content?.image_pc ?? '')}
                  alt=""
                />
              ) : null}
              <div className="headerInner">
                <div className="titleHasNoBlock">
                  <div className="labelFeature-wrapper">
                    <LabelFeature className="labelFeature" />
                  </div>
                  <h2>{content.title}</h2>
                </div>
                <h3 className="subtitle">{content.subtitle}</h3>
                <div className="lead_sentence">{content.lead_sentence}</div>
              </div>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

/**
 * OuFolderView view component class.
 * @function OuFolderView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const FeatureMainFolderView = ({
  content,
  loading,
  result,
  featureListForTop,
  contentList,
  b_size,
  b_start,
  has_next,
  total,
}) => {
  return (
    <div className="FeatureMainFolderView FeatureFolderView">
      <HelmetTitle pageTitle={content.title} />

      <div className="featureFolderHeader">
        <div className="featureFolderHeaderInner">
          <HorizontalRule className="hr" />
          <FolderTitle
            title={content.title}
            title_sp1={content.title_sp1}
            title_sp2={content.title_sp2}
          />
          <FolderLeadSentence
            lead_sentence={content.lead_sentence}
            lead_sentence_sp1={content.lead_sentence_sp1}
            lead_sentence_sp2={content.lead_sentence_sp2}
          />
        </div>
      </div>

      <div className="FeatureMainFolderBodyWrapper">
        {_.map(contentList, (content) => {
          const hasBlock = Content.hasBlock(content);
          return hasBlock ? (
            <FeatureFolderMainBodyHasBlocks
              key={content.url}
              content={content}
            />
          ) : (
            <FeatureFolderMainBody key={content.url} content={content} />
          );
        })}
      </div>

      <div className="loading">
        <InfiniteScroll
          onInit={() => {
            featureListForTop(b_size, b_start, has_next);
          }}
          onEnter={() => {
            featureListForTop(b_size, b_start, has_next);
          }}
          next_b_start={b_start + b_size}
          b_size={b_size}
          total={total}
          has_next={has_next}
        ></InfiniteScroll>
      </div>

      {/* <Waypoint
        onEnter={() => {
          featureListForTop(b_size, b_start, has_next);
        }}
      ></Waypoint> */}

      {/* <Loader visible={has_next}></Loader> */}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
FeatureMainFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        review_state: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }).isRequired,
  loading: PropTypes.bool,
  result: PropTypes.object,
  featureListForTop: PropTypes.func,
  contentList: PropTypes.array,
  b_size: PropTypes.number,
  b_start: PropTypes.number,
  has_next: PropTypes.bool,
};

const FeatureMainFolderViewContainer = connect(
  (state, props) => {
    return {
      loading: state.featureListForTop?.loading,
      result: state.featureListForTop?.result,
      contentList: state.featureListForTop?.contentList ?? [],
      b_size: state.featureListForTop?.result?.data?.attributes?.b_size ?? 0,
      b_start: state.featureListForTop?.result?.data?.attributes?.b_start ?? 0,
      total: state.featureListForTop?.result?.data?.attributes?.total ?? 0,
      has_next:
        state.featureListForTop?.result?.data?.attributes?.has_next ?? true,
    };
  },
  (dispatch, props) => {
    return {
      featureListForTop: (b_size, b_start, has_next) => {
        if (has_next) {
          dispatch(featureListForTop('ja', b_size + b_start));
        }
      },
    };
  },
)(FeatureMainFolderView);

export default injectIntl(FeatureMainFolderViewContainer);
