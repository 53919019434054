/**
 * Side Nav component.
 * @module components/theme/SideNav
 */

import React from 'react';
import {
  StyleConfig,
  MediaQueryConfig,
  isSpAndTablet,
} from '../../../../helpers/GlobalStyle/GlobalStyle';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';
import SideNavSp from './SideNavSp';
import { Icon, LanguageSelectorPC } from '../../../../components/index';
import { defineMessages, injectIntl } from 'react-intl';
import './SideNav.css';
import { getLocale } from '../../../../helpers/Locale/Locale';

const messages = defineMessages({});

const SideNav = (props) => {
  // const isTabletAndSP = useMediaQuery({
  //   query: MediaQueryConfig.SP_AND_TABLET,
  // });

  return (
    <div className="SideNav">
      <SideNavSp
        className="SideAreaSp"
        setVisibleLeftBar={(visible) => props.setVisibleLeftBar(visible)}
        visibleLeftBar={props.visibleLeftBar}
        setVisibleRightBar={(visible) => props.setVisibleRightBar(visible)}
        visibleRightBar={props.visibleRightBar}
        history={props.history}
        locale={props.locale}
      ></SideNavSp>
      <div className="SideArea">
        <button
          className="Menu"
          onClick={() => {
            props.setVisibleLeftBar(true);
          }}
        >
          {props.visibleLeftBar ? (
            <Icon
              icon="close"
              size={StyleConfig.menuIconSize}
              color={StyleConfig.iconColorDark}
            ></Icon>
          ) : (
            <Icon
              icon="bars"
              size={StyleConfig.menuIconSize}
              color={StyleConfig.iconColorDark}
            ></Icon>
          )}
        </button>
        <div className="navs">
          <Link to={flattenToAppURL('/search')} className="nav">
            <Icon
              icon="search"
              size={StyleConfig.menuIconSize}
              color={StyleConfig.iconColorLight}
            ></Icon>
          </Link>
          <Link
            to={flattenToAppURL(`/${props.locale}/contact`)}
            className="nav"
          >
            <Icon
              icon="mail outline"
              size={StyleConfig.menuIconSize}
              color={StyleConfig.iconColorLight}
            ></Icon>
          </Link>
          <div className="nav">
            <LanguageSelectorPC locale={props.locale}></LanguageSelectorPC>
          </div>
        </div>
      </div>
    </div>
  );
};

SideNav.prototype = {
  setVisibleLeftBar: PropTypes.func,
  visibleLeftBar: PropTypes.bool,
  setVisibleRightBar: PropTypes.func,
  visibleRightBar: PropTypes.bool,
  history: PropTypes.object,
};

/**
 * コンテナ
 */
const SideNavContainer = connect(
  (state, props) => {
    // const locale = state?.content?.data?.language?.token ?? 'ja';
    const locale = getLocale(state?.content?.data?.language?.token);
    return {
      isSpAndTablet: isSpAndTablet(),
      locale,
    };
  },
  (dispatch, props) => {
    return {};
  },
)(SideNav);

export default injectIntl(SideNavContainer);
