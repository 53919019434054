/**
 * Browsing history list action
 * UUIDのリストから各々のタイトル, URL, サムネイル画像のURLを取得する
 * @module actions/browsingHistoryList/browsingHistoryList
 */
import { GET_BROWSING_HISTORY_LIST } from '../../constants/ActionTypes';

/**
 * Browsing history list function
 * @function browsingHistoryList
 * @param {string} uuids uuids connected with semicolon(%3B).
 * @returns {Object} browsingHistoryList action.
 */
export function browsingHistoryList(uuids) {
  const safeUuids = uuids ?? '';
  return {
    type: GET_BROWSING_HISTORY_LIST,
    request: {
      op: 'get',
      path: `/@viewing-history-list?uuid=${safeUuids}`,
    },
  };
}
