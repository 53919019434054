/**
 * Show past feature article list by extra collection.
 * [過去の特集記事] 一覧を表示する
 * @module comonents/theme/Site/Collection/PastFeatureCollection
 */

import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { pastFeatureList } from '../../../../actions';
import ExtraCollection from './ExtraCollection';

/**
 * Preprocess data from container component to put ExtraCollection component.
 * ExtraCollectionに渡すデータを加工する中間コンポーネント
 * @class PopularArticleList
 * @extends Component
 */
class PopularArticleList extends Component {
  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.state = {
      locale: '',
    };
  }
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    UID: PropTypes.string,
    contentList: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    description: PropTypes.string,
    bStart: PropTypes.number,
    bSize: PropTypes.number,
    basNext: PropTypes.bool,
    token: PropTypes.string,
    locale: PropTypes.string,
    url: PropTypes.string,
    intl: PropTypes.objectOf(PropTypes.any),
    loadContent: PropTypes.func,
  };
  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    UID: '',
    contentList: [],
    title: '',
    description: '',
    bStart: 0,
    bSize: 0,
    hasNext: false,
    token: '',
    locale: '',
    url: '',
  };
  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.setState({ locale: this.props.locale });
    this.props.loadContent(this.props.locale);
  }
  /**
   * Component did update
   * @method componentDidUpdate
   * @returns {undefined}
   */
  componentDidUpdate() {
    if (this.props.locale !== this.state.locale) {
      this.setState({ locale: this.props.locale });
      this.props.loadContent(this.props.locale);
    }
  }

  render() {
    const title = () => {
      console.log(this.props.title);
      if (this.props.title) {
        return this.props.title;
      } else if (this.props.locale) {
        if (this.props.locale === 'ja') {
          return '過去の特集記事';
        }
      }
      return 'Past Featured Articles';
    };
    return (
      <ExtraCollection
        contentList={this.props.contentList}
        bSize={this.props.bSize}
        loadContent={this.props.loadContent}
        bStart={this.props.bStart}
        intl={this.props.intl}
        hasNext={this.props.hasNext}
        title={title()}
        description={this.props.description}
        apiPath={this.props.locale}
      />
    );
  }
}

/**
 * Container function component to connect Redux state, Action and child component.
 * ComponentとReduxを接続するコンテナComponent
 * @param {function} connect the function to connect to redux and component.
 * @returns {undefined}
 */
const PastFeatureContainer = connect(
  (state, props) => {
    return {
      contentList: state.pastFeatureList?.contentList ?? [],
      // title: state.pastFeatureList?.result?.data?.attributes?.title,
      title: '',
      description: state.pastFeatureList?.result?.data?.attributes?.description,
      bStart: state.pastFeatureList?.result?.data?.attributes?.b_start,
      bSize: state.pastFeatureList?.result?.data?.attributes?.b_size,
      hasNext: state.pastFeatureList?.result?.data?.attributes?.has_next,
      token: state.userSession?.token,
      locale: state?.content?.data?.language?.token ?? 'ja',
      apiUrl: `${state?.content?.data?.language?.token ?? 'ja'}/feature`,
    };
  },
  (dispatch, props) => {
    return {
      loadContent: (locale, bSize = 0, bStart = 0, hasNext = true) => {
        if (hasNext) {
          dispatch(pastFeatureList(locale, bSize + bStart));
        }
      },
    };
  },
)(PopularArticleList);

export default injectIntl(PastFeatureContainer, {});
