import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './Icon.css';

/**
 * Icon
 */
const IconElement = ({ icon, size, color }) => (
  <span className="Icon">
    <Icon
      className="IconStyled"
      name={icon}
      style={{ fontSize: size, color: color }}
    />
  </span>
);

IconElement.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
};

export default IconElement;
