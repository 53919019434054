/**
 * Browsing history saver action
 * 閲覧履歴（UUIDのarray）をcookieに保存(更新)するaction
 * @module actions/browsingHistorySaver/browsingHistorySaver
 */
import { UPDATE_BROWSING_HISTORY } from '../../constants/ActionTypes';
import { getDayAfter } from '../../helpers/Date/Date';

const expireCookie = getDayAfter(90);

/**
 * Browsing history saver function
 * @param {string} history uuids connected with semicolon(%3B).
 * @returns {Object} browsingHistorySaver action
 */
export function browsingHistorySaver(history) {
  return {
    type: UPDATE_BROWSING_HISTORY,
    history: history,
    expireCookie: expireCookie,
  };
}
