import { map } from 'lodash';
import { settings } from '~/config';

import { SITE_IMAGE_DISPLAY } from '../../constants/ActionTypes';
import cookie from 'react-cookie';
import { CookieSetting } from '../../constants/Setting';

const initialState = {
  visible: CookieSetting.SITE_IMAGE_VISIBLE.off,
};

/**
 * SiteImage Reducer
 * @param {*} state
 * @param {*} action
 */
export default function siteImage(state = initialState, action = {}) {
  switch (action.type) {
    case SITE_IMAGE_DISPLAY:
      cookie.save(
        CookieSetting.SITE_IMAGE_VISIBLE.name,
        action.visible,
        CookieSetting.SITE_IMAGE_VISIBLE.option,
      );
      return {
        ...state,
        visible: action.visible,
      };
    default:
      return state;
  }
}
