/**
 * App container.
 * @module components/theme/App/App
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { asyncConnect } from 'redux-connect';
import { Segment, Sidebar } from 'semantic-ui-react';
// import Raven from 'raven-js';
import { renderRoutes } from 'react-router-config';
import { Slide, ToastContainer, toast } from 'react-toastify';
import split from 'lodash/split';
import join from 'lodash/join';
import trim from 'lodash/trim';
import cx from 'classnames';
import loadable from '@loadable/component';
import { settings, views } from '~/config';
import Error from '@plone/volto/error';
import {
  Header,
  Footer,
  OutdatedBrowser,
  AppExtras,
} from '@plone/volto/components';
import { BodyClass, getBaseUrl, getView } from '@plone/volto/helpers';
import {
  getBreadcrumbs,
  getContent,
  getNavigation,
  getTypes,
  getWorkflow,
} from '@plone/volto/actions';
import MultilingualRedirector from '@plone/volto/components/theme/MultilingualRedirector/MultilingualRedirector';
import _ from 'lodash';
// 独自コンポーネント
import {
  SiteImage,
  SideNav,
  SideNavPc,
  MainGraph,
  CustomDimmer,
  TermContent,
  ScrollToTop,
} from '../../../../components/';
// VoltoのAPIパス取得テストï
// import { settings } from '@plone/volto/config';
import { getSiteMainInfo } from '../../../../actions';
import { Icon, SideTeacherComment } from '../../../../components/index';
import './App.css';
import '../../../../helpers/GlobalStyle/GlobalStyle.css';
import '../../../../helpers/GlobalStyle/Print.css';
import smoothscroll from 'smoothscroll-polyfill';

import * as Sentry from '@sentry/browser';

if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

/**
 * @export
 * @class App
 * @extends {Component}
 */
class App extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    // getSiteMainInfo: PropTypes.func.isRequired,
  };

  state = {
    hasError: false,
    error: null,
    errorInfo: null,
    visibleLeftBar: false,
    visibleRightBar: false,
  };

  /**
   * ComponentDidMount
   * @method ComponentDidMount
   * @param {string} error  The error
   * @param {string} info The info
   * @returns {undefined}
   */
  // componentDidMount() {
  //   if (__CLIENT__ && process.env.SENTRY_DSN) {
  //     const Raven = loadable(() => import('raven-js'));
  //     Raven.config(process.env.SENTRY_DSN).install();
  //   }
  // }

  UNSAFE_componentWillMount() {
    // this.props.getSiteMainInfo(getBaseUrl(this.props.pathname));
    // this.props.getSearchBasic(getBaseUrl(this.props.pathname));
  }

  /**
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    }
  }

  /**
   * ComponentDidCatch
   * @method ComponentDidCatch
   * @param {string} error  The error
   * @param {string} info The info
   * @returns {undefined}
   */
  // componentDidCatch(error, info) {
  //   this.setState({ hasError: true, error, errorInfo: info });
  //   if (__CLIENT__ && process.env.SENTRY_DSN) {
  //     const Raven = loadable(() => import('raven-js'));
  //     Raven.captureException(error, { extra: info });
  //   }
  // }
  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, errorInfo: info });
    if (__CLIENT__) {
      if (window?.env?.RAZZLE_SENTRY_DSN || __SENTRY__?.SENTRY_DSN) {
        Sentry.captureException(error);
      }
    }
  }

  /**
   * Knob Left
   * @param {*} visible
   */
  setVisibleLeftBar(visible) {
    this.setState({ visibleLeftBar: visible });
  }

  /**
   * Knob Right
   * @param {*} visible
   */
  setVisibleRightBar(visible) {
    this.setState({ visibleRightBar: visible });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const path = getBaseUrl(this.props.pathname);
    const action = getView(this.props.pathname);
    const ConnectionRefusedView = views.errorViews.ECONNREFUSED;

    return (
      <div className="App">
        <Fragment>
          {/* <GlobalStyle backgroundColor="turquoise" /> */}
          <BodyClass className={`view-${action}view`} />

          {/* Body class depending on content type */}
          {this.props.content && this.props.content['@type'] && (
            <BodyClass
              className={`contenttype-${this.props.content['@type']
                .replace(' ', '-')
                .toLowerCase()}`}
            />
          )}

          {/* Body class depending on sections */}
          <BodyClass
            className={cx({
              [trim(join(split(this.props.pathname, '/'), ' section-'))]:
                this.props.pathname !== '/',
              siteroot: this.props.pathname === '/',
            })}
          />
          <div className="app-inner">
            {/* TODO: 後ほど動的にする */}
            <SiteImage
              imageUrl="/assets/images/main_back.png"
              imageLabel=""
              pathname={this.props.pathname}
            ></SiteImage>
            <div className="AppMainWrapper">
              {/* TODO: MediaQuery挿入 */}
              <SideNav
                visibleLeftBar={this.state.visibleLeftBar}
                setVisibleLeftBar={(visible) => this.setVisibleLeftBar(visible)}
                visibleRightBar={this.state.visibleRightBar}
                setVisibleRightBar={(visible) =>
                  this.setVisibleRightBar(visible)
                }
                history={this.props.history}
              ></SideNav>

              <Sidebar.Pushable as="div" className="AppMain">
                <Sidebar
                  as="div"
                  animation="overlay"
                  onHide={() => this.setVisibleLeftBar(false)}
                  visible={this.state.visibleLeftBar}
                  width="wide"
                  className="SideBar"
                >
                  <SideNavPc
                    onHide={() => this.setVisibleLeftBar(false)}
                  ></SideNavPc>
                </Sidebar>
                <Sidebar.Pusher
                  className="main"
                  dimmed={this.state.visibleLeftBar}
                >
                  <Sidebar.Pushable as="div" className="MainWithRightSideBar">
                    <Sidebar
                      as="div"
                      animation="overlay"
                      onHide={() => this.setVisibleRightBar(false)}
                      visible={this.state.visibleRightBar}
                      direction="right"
                      width="wide"
                      className={`SideBarRight`}
                    >
                      <SideTeacherComment
                        visible={this.state.visibleRightBar}
                      ></SideTeacherComment>
                    </Sidebar>
                    <Sidebar.Pusher
                      className="main-inner"
                      dimmed={this.state.visibleRightBar}
                    >
                      <Header pathname={path} />

                      {/* 検索窓 */}
                      {/* <SearchWidget pathname={this.props.pathname} /> */}

                      {/* <Breadcrumbs pathname={path} /> */}

                      <MultilingualRedirector pathname={this.props.pathname}>
                        <Segment basic className="content-area contentArea">
                          <main>
                            <OutdatedBrowser />
                            {this.props.connectionRefused ? (
                              <ConnectionRefusedView />
                            ) : this.state.hasError ? (
                              <Error
                                message={this.state.error.message}
                                stackTrace={this.state.errorInfo.componentStack}
                              />
                            ) : (
                              renderRoutes(this.props.route.routes, {
                                /**
                                 * 先生のコメントをみるボタンを開く関数
                                 */
                                openTeacherComment: () =>
                                  this.setVisibleRightBar(true),
                                visibleTeacherComment: this.state
                                  .visibleRightBar,
                              })
                            )}

                            {/* {this.state.hasError ? (
                              <Error
                                message={this.state.error.message}
                                stackTrace={this.state.errorInfo.componentStack}
                              />
                            ) : (
                              renderRoutes(this.props.route.routes, {
                                // 先生のコメントをみるボタンを開く関数
                                openTeacherComment: () =>
                                  this.setVisibleRightBar(true),
                                visibleTeacherComment: this.state
                                  .visibleRightBar,
                              })
                            )} */}
                          </main>
                        </Segment>
                      </MultilingualRedirector>

                      <Footer />
                    </Sidebar.Pusher>
                  </Sidebar.Pushable>
                </Sidebar.Pusher>
              </Sidebar.Pushable>
            </div>
          </div>
          <ScrollToTop></ScrollToTop>

          <ToastContainer
            position={toast.POSITION.BOTTOM_CENTER}
            hideProgressBar
            transition={Slide}
            autoClose={5000}
            closeButton={
              <Icon className="toast-dismiss-action" icon="close" size="18px" />
            }
          />
          <AppExtras />
        </Fragment>
        <CustomDimmer></CustomDimmer>
        <TermContent></TermContent>
      </div>
    );
  }
}

export const __test__ = connect(
  (state, props) => ({
    pathname: props.location.pathname,
    content: state.content.data,
    apiError: state.apierror.error,
    connectionRefused: state.apierror.connectionRefused,
  }),
  {},
)(App);

export default compose(
  // ここは、SSR時に先読みされる
  asyncConnect([
    {
      key: 'breadcrumbs',
      promise: ({ location, store: { dispatch } }) =>
        __SERVER__ && dispatch(getBreadcrumbs(getBaseUrl(location.pathname))),
    },
    {
      key: 'content',
      promise: ({ location, store: { dispatch } }) =>
        __SERVER__ && dispatch(getContent(getBaseUrl(location.pathname))),
    },
    {
      key: 'navigation',
      promise: ({ location, store: { dispatch } }) =>
        __SERVER__ &&
        dispatch(
          getNavigation(getBaseUrl(location.pathname), settings.navDepth),
        ),
    },
    {
      key: 'types',
      promise: ({ location, store: { dispatch } }) =>
        __SERVER__ && dispatch(getTypes(getBaseUrl(location.pathname))),
    },
    {
      key: 'workflow',
      promise: ({ location, store: { dispatch } }) =>
        __SERVER__ && dispatch(getWorkflow(getBaseUrl(location.pathname))),
    },
    {
      key: 'siteMainInfo',
      promise: ({ location, store: { dispatch } }) =>
        __SERVER__ && dispatch(getSiteMainInfo(getBaseUrl(location.pathname))),
    },
  ]),
  connect(
    (state, props) => ({
      pathname: props.location.pathname,
      content: state.content.data,
      apiError: state.apierror.error,
      connectionRefused: state.apierror.connectionRefused,
    }),
    (dispatch, props) => ({
      // getSiteMainInfo: getSiteMainInfo,
    }),
  ),
)(App);
