/**
 * PopularArticleCollection Component based on ExtraCollection component
 * よく読まれている記事一覧
 * @module components/theme/Collection/PopularArticleCollection
 */

import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { popularArticleList } from '../../../../actions/popularArticleList/popularArticleList';
import ExtraCollection from './ExtraCollection';
import './PopularArticleCollection.css';

const messages = defineMessages({
  popularArticles: {
    id: 'Popular Articles',
    defaultMessage: 'Popular Articles',
  },
});

/**
 * Preprocess data from container component to put ExtraCollection component.
 * ExtraCollectionに渡すデータを加工する中間コンポーネント
 * @class PopularArticleList
 * @extends Component
 */
class PopularArticleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: '',
    };
  }
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    UID: PropTypes.string,
    contentList: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    description: PropTypes.string,
    bStart: PropTypes.number,
    bSize: PropTypes.number,
    basNext: PropTypes.bool,
    token: PropTypes.string,
    locale: PropTypes.string,
    url: PropTypes.string,
    intl: PropTypes.objectOf(PropTypes.any),
    loadContent: PropTypes.func,
  };
  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    UID: '',
    contentList: [],
    title: '',
    description: '',
    bStart: 0,
    bSize: 0,
    hasNext: false,
    token: '',
    locale: '',
    url: '',
  };
  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.setState({ locale: this.props.locale });
    this.props.loadContent(this.props.locale);
  }
  /**
   * Component did update
   * @method componentDidUpdate
   * @returns {undefined}
   */
  componentDidUpdate() {
    if (this.props.locale !== this.state.locale) {
      this.setState({ locale: this.props.locale });
      this.props.loadContent(this.props.locale);
    }
  }

  render() {
    return (
      <div className="PopularArticleCollection">
        <ExtraCollection
          contentList={this.props.contentList}
          bSize={this.props.bSize}
          loadContent={this.props.loadContent}
          bStart={this.props.bStart}
          intl={this.props.intl}
          hasNext={this.props.hasNext}
          title={this.props.title}
          description={this.props.description}
          apiPath={this.props.locale}
        />
      </div>
    );
  }
}
/**
 * Container function component to connect Redux state, Action and child component.
 * ComponentとReduxを接続するコンテナComponent
 * @param {function} connect the function to connect to redux and component.
 * @returns {undefined}
 */
const PopularArticleContainer = connect(
  (state, props) => ({
    contentList: state.popularArticleList?.contentList ?? [],
    title: props.intl.formatMessage(messages.popularArticles),
    description:
      state.popularArticleList?.result?.data?.attributes?.description,
    bStart: state.popularArticleList?.result?.data?.attributes?.b_start,
    bSize: state.popularArticleList?.result?.data?.attributes?.b_size,
    hasNext: state.popularArticleList?.result?.data?.attributes?.has_next,
    token: state.userSession?.token,
    locale: state?.content?.data?.language?.token ?? 'ja',
  }),
  (dispatch, props) => {
    return {
      loadContent: (locale, bSize = 0, bStart = 0, hasNext = true) => {
        if (hasNext) {
          dispatch(popularArticleList(locale, bSize + bStart));
        }
      },
    };
  },
)(PopularArticleList);

export default injectIntl(PopularArticleContainer, {});
