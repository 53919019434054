/**
 * Edit HeaderText block (pair of <h3> and <p>).
 * @module components/manage/Blocks/HeaderText/Edit
 * based on @module components/manage/Blocks/Image/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Form, Segment } from 'semantic-ui-react';
import { Portal } from 'react-portal';
import { Editor, DefaultDraftBlockRenderMap, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';
import { Field } from '@plone/volto/components';

const messages = defineMessages({
  title: {
    id: 'Type the header text…',
    defaultMessage: 'Type the header text…',
  },
  text: {
    id: 'Type the body text…',
    defaultMessage: 'Type the body text…',
  },
  header_text: {
    id: 'Header Text',
    defaultMessage: 'Header Text',
  },
  inverted: {
    id: 'Invert style',
    defaultMessage: 'Invert style',
  },
  center: {
    id: 'Center the body text',
    defaultMessage: 'Center the body text',
  },
});

const getId = () => Math.floor(Math.random() * Math.pow(2, 24)).toString(32);
const blockTitleRenderMap = Map({
  unstyled: {
    element: 'h3',
  },
});
const blockTextRenderMap = Map({
  unstyled: {
    element: 'p',
  },
});

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  blockTitleRenderMap,
);
const extendedTextBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  blockTextRenderMap,
);

/**
 * Edit image block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    inverted: PropTypes.bool.isRequired,
    center: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    detached: false,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   */
  constructor(props) {
    super(props);
    if (!__SERVER__) {
      let titleEditorState;
      let textEditorState;
      if (props.data && props.data.title) {
        titleEditorState = EditorState.createWithContent(
          stateFromHTML(props.data.title),
        );
      } else {
        titleEditorState = EditorState.createEmpty();
      }
      if (props.data && props.data.text) {
        textEditorState = EditorState.createWithContent(
          stateFromHTML(props.data.text),
        );
      } else {
        textEditorState = EditorState.createEmpty();
      }
      if (props.data && props.data.inverted == null) {
        props.data.inverted = false;
      }
      if (props.data && props.data.center == null) {
        props.data.center = false;
      }
      this.state = {
        titleEditorState,
        textEditorState,
        currentFocused: 'title',
      };
    }
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.toggleBool = this.toggleBool.bind(this);
    this.toggleInvert = this.toggleInvert.bind(this);
    this.toggleCenter = this.toggleCenter.bind(this);
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.key) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        key: getId(),
      });
    }
    if (this.props.selected) {
      this.titleEditor.focus();
    }
    // document.addEventListener('mousedown', this.handleClickOutside, false); TODO: Blockテスト時にHeader3を参考に要不要を調査する
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.data.title &&
      this.props.data.title !== nextProps.data.title &&
      !this.props.selected
    ) {
      const contentState = stateFromHTML(nextProps.data.title);
      this.setState({
        titleEditorState: nextProps.data.title
          ? EditorState.createWithContent(contentState)
          : EditorState.createEmpty(),
      });
    }
    if (
      nextProps.data.text &&
      this.props.data.text !== nextProps.data.text &&
      !this.props.selected
    ) {
      const contentState = stateFromHTML(nextProps.data.text);
      this.setState({
        textEditorState: nextProps.data.text
          ? EditorState.createWithContent(contentState)
          : EditorState.createEmpty(),
      });
    }

    if (nextProps.selected !== this.props.selected) {
      if (this.state.currentFocused === 'title') {
        this.titleEditor.focus();
      } else {
        this.textEditor.focus();
      }
    }
  }

  toggleAddNewBlock = () =>
    this.setState((state) => ({ addNewBlockOpened: !state.addNewBlockOpened }));

  /**
   * Change Title handler
   * @method onChangeTitle
   * @param {object} titleEditorState Editor state.
   * @returns {undefined}
   */
  onChangeTitle(titleEditorState) {
    this.setState({ titleEditorState }, () => {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        title: titleEditorState.getCurrentContent().getPlainText(),
      });
    });
  }

  /**
   * Change Text handler
   * @method onChangeText
   * @param {object} textEditorState Editor state.
   * @returns {undefined}
   */
  onChangeText(textEditorState) {
    this.setState({ textEditorState }, () => {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        text: textEditorState.getCurrentContent().getPlainText(),
      });
    });
  }

  /**
   * Toggle bool method.
   * @method toggleBool
   * @param {string} value Value to toggle.
   */
  toggleBool(value) {
    const data = this.props.data;
    this.props.onChangeBlock(this.props.block, {
      ...data,
      [value]: !data[value],
    });
  }

  /**
   * Toggle invert method.
   * @method toggleInvert
   * @returns {undefined}
   */
  toggleInvert() {
    this.toggleBool('inverted');
  }

  /**
   * Toggle center method.
   * @method toggleCenter
   * @returns {undefined}
   */
  toggleCenter() {
    this.toggleBool('center');
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    return (
      <div
        className={cx('block header-text', {
          selected: this.props.inverted,
          centertext: this.props.center,
        })}
      >
        <div inverted={this.props.data.inverted}>
          <Editor
            ref={(node) => {
              this.titleEditor = node;
            }}
            onChange={this.onChangeTitle}
            editorState={this.state.titleEditorState}
            blockRenderMap={extendedBlockRenderMap}
            handleKeyCommand={(command, editorState) => {
              if (
                command === 'backspace' &&
                editorState.getCurrentContent().getPlainText().length === 0
              ) {
                this.props.onDeleteBlock(this.props.block, true);
              }
            }}
            handleReturn={() => true}
            placeholder={this.props.intl.formatMessage(messages.title)}
            blockStyleFn={() => 'title-editor'}
            onUpArrow={() => {
              const selectionState = this.state.titleEditorState.getSelection();
              const { titleEditorState } = this.state;
              if (
                titleEditorState
                  .getCurrentContent()
                  .getBlockMap()
                  .first()
                  .getKey() === selectionState.getFocusKey()
              ) {
                this.props.onFocusPreviousBlock(
                  this.props.block,
                  this.props.blockNode.current,
                );
              }
            }}
            onDownArrow={() => {
              const selectionState = this.state.titleEditorState.getSelection();
              const { titleEditorState } = this.state;
              if (
                titleEditorState
                  .getCurrentContent()
                  .getBlockMap()
                  .last()
                  .getKey() === selectionState.getFocusKey()
              ) {
                this.setState(() => ({ currentFocused: 'text' }));
                this.textEditor.focus();
              }
            }}
          />
          <Editor
            ref={(node) => {
              this.textEditor = node;
            }}
            onChange={this.onChangeText}
            editorState={this.state.textEditorState}
            blockRenderMap={extendedTextBlockRenderMap}
            handleReturn={() => {
              this.props.onSelectBlock(
                this.props.onAddBlock('text', this.props.index + 1),
              );
              return 'handled';
            }}
            handleKeyCommand={(command, editorState) => {
              const selectionState = this.state.textEditorState.getSelection();
              const currentCursorPosition = selectionState.getStartOffset();
              if (currentCursorPosition === 0) {
                this.setState(() => ({ currentFocused: 'title' }));
                this.titleEditor.focus();
              }
            }}
            placeholder={this.props.intl.formatMessage(messages.text)}
            blockStyleFn={() => 'text-editor'}
            onUpArrow={() => {
              const selectionState = this.state.textEditorState.getSelection();
              const currentCursorPosition = selectionState.getStartOffset();

              if (currentCursorPosition === 0) {
                this.setState(() => ({ currentFocused: 'title' }));
                this.titleEditor.focus();
              }
            }}
            onDownArrow={() => {
              const selectionState = this.state.textEditorState.getSelection();
              const { textEditorState } = this.state;
              const currentCursorPosition = selectionState.getStartOffset();
              const blockLength = textEditorState
                .getCurrentContent()
                .getFirstBlock()
                .getLength();

              if (currentCursorPosition === blockLength) {
                this.props.onFocusNextBlock(
                  this.props.block,
                  this.props.blockNode.current,
                );
              }
            }}
          />
        </div>
        {this.props.selected && (
          <Portal
            node={__CLIENT__ && document.getElementById('sidebar-properties')}
          >
            <Form method="post" onSubmit={(event) => event.preventDefault()}>
              <Segment secondary attached>
                <FormattedMessage id="HeaderText" defaultMessage="HeaderText" />
              </Segment>
              <Segment attached>
                <Field
                  id="inverted"
                  title={this.props.intl.formatMessage(messages.inverted)}
                  type="boolean"
                  value={this.props.data && this.props.data.inverted}
                  onChange={() => this.toggleInvert()}
                />
                <Field
                  id="center"
                  title={this.props.intl.formatMessage(messages.center)}
                  type="boolean"
                  value={this.props.data && this.props.data.center}
                  onChange={() => this.toggleCenter()}
                />
              </Segment>
            </Form>
          </Portal>
        )}
      </div>
    );
  }
}
export default injectIntl(Edit);
