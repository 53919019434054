/**
 * View select form block for MailForm
 * @module components/manage/Blocks/MailForm/SelectBlock/View
 * created @nk24@CMScom 2021/12/08
 */

import React, { useState, useEffect } from 'react';
import { concat, map } from 'lodash';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import './View.css';

const messages = defineMessages({
  noselect: {
    id: 'No value',
    defaultMessage: 'No value',
  },
});

/**
 * View select form block function component
 * @function View
 */
const View = ({ id, data, formData, handleInputData, handleBlur, intl }) => {
  const errorMsg = formData?.errorMsg;
  const formId = formData?.formId;
  const input_options = map(formData?.options, (option) => ({
    key: escape(option),
    label: option,
    value: option,
  }));
  const required = formData?.required;
  const options = concat(
    [
      {
        key: '0_noselect',
        label: intl.formatMessage(messages.noselect),
        value: '',
      },
    ],
    input_options,
  );
  const [value, setValue] = useState(formData?.value);
  useEffect(() => {
    if (formData?.value !== value) {
      setValue(formData.value);
    }
  }, [formData?.value, value]);

  return (
    <Form.Field className="SelectBlock">
      <label>
        {data.label}
        <span className="RequiredMark">{required ? '*' : ''}</span>
        <span className="ErrorMessage">{errorMsg}</span>
      </label>
      <select
        id={formId}
        name={formId}
        value={value}
        onChange={(e) => {
          formData.value = e.target.value;
          handleInputData(formData);
        }}
        onBlur={() => {
          handleBlur(formData);
        }}
      >
        {options.map((option) => {
          return (
            <option key={option.key} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      {/* <Select
         className="StyledInput MailForm Select"
         id={formId}
         name={formId}
         value={value}
         options={options}
         onChange={(target) => {
           formData.value = target.value;
           handleInputData(formData);
           setValue(target.value);
         }}
         onBlur={() => {
           handleBlur(formData);
         }}
       /> */}
    </Form.Field>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  id: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  formData: PropTypes.any,
  handleInputData: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  intl: PropTypes.any,
};

export default injectIntl(View);
