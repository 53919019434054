/**
 * Side Teacher Comment component.
 * @module components/theme/SideTeacherComment
 */

import React, { useEffect, useState } from 'react';
import {
  StyleConfig,
  MediaQueryConfig,
  isSpAndTablet,
} from '../../../../helpers/GlobalStyle/GlobalStyle';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from '../../../../components/index';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Locale } from '../../../../helpers/index';
import './SideTeacherComment.css';

const messages = defineMessages({});

const SideTeacherComment = (props) => {
  // const isTabletAndSP = useMediaQuery({
  //   query: MediaQueryConfig.SP_AND_TABLET,
  // });
  const [visible, setVisible] = useState(false);
  const [top, setTop] = useState(0);

  useEffect(() => {
    const setHitokotoPOS = () => {
      if (typeof window !== 'undefined') {
        const offsetTop = window.pageYOffset;
        setTop(Math.abs(offsetTop || 0));
      }
    };
    if (props.visible !== visible) {
      if (props.visible) {
        setHitokotoPOS();
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [props.visible, visible, setVisible]);

  return (
    <div className="SideTeacherComment">
      <div className="layout" style={{ top: `${top}px` }}>
        <h3 className="teacher-comment-title">
          <FormattedMessage
            id="About This Research"
            defaultMessage="About this research"
          />
        </h3>
        <p className="teacher-comment">{props.teacher_comment}</p>
        <hr />
        <div className="teacher-image">
          <img
            src={
              props.teacher_image
                ? flattenToAppURL(props.teacher_image)
                : '/assets/images/photo_drwan_640.png'
            }
            alt=""
          />
        </div>
        <h4 className="teacher-name">{props.teacher_name}</h4>
        <ul className="teacher-position">
          <li>{props.teacher_division1}</li>
          <li>{props.teacher_division2}</li>
          <li>{props.teacher_position}</li>
        </ul>
        <ul className="teacher-url">
          <li>
            <a href={props.teacher_url}>
              <FormattedMessage
                id="Researcher Profile"
                defaultMessage="Researcher profile"
              />
            </a>{' '}
            <Icon
              icon="clone outline"
              size={StyleConfig.menuIconSizeSmall}
              color={StyleConfig.iconColorLightGray}
            ></Icon>
          </li>
        </ul>
      </div>
    </div>
  );
};

SideTeacherComment.prototype = {
  locale: PropTypes.string,
  teacher_comment: PropTypes.string,
  teacher_division1: PropTypes.string,
  teacher_division2: PropTypes.string,
  teacher_image: PropTypes.string,
  teacher_name: PropTypes.string,
  teacher_position: PropTypes.string,
  teacher_url: PropTypes.string,
  visible: PropTypes.bool,
};

/**
 * コンテナ
 */
const SideTeacherCommentContainer = connect(
  (state, props) => {
    const locale = state?.content?.data?.language?.token ?? 'ja';
    return {
      locale: locale,
      teacher_comment: state?.content?.data?.teacher_comment,
      teacher_division1: state?.content?.data?.teacher_division1,
      teacher_division2: state?.content?.data?.teacher_division2,
      teacher_image: state?.content?.data?.teacher_image,
      teacher_name: state?.content?.data?.teacher_name,
      teacher_position: state?.content?.data?.teacher_position,
      teacher_url: state?.content?.data?.teacher_url,
    };
  },
  (dispatch, props) => {
    return {};
  },
)(SideTeacherComment);

export default injectIntl(SideTeacherCommentContainer);
