/**
 * Site Footer component.
 * @module components/theme/SiteFooter
 */

import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
// import { Icon } from 'semantic-ui-react';
import _ from 'lodash';
import { Icon } from '../../../index';
import { Icon as IconComponent } from '@plone/volto/components';
import { StyleConfig } from '../../../../helpers/GlobalStyle/GlobalStyle';
// import { Icon } from '@plone/volto/components';
// import facebookSVG from '@plone/volto/icons/facebook.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';
import xSVG from '~/icons/x.svg';
import './SiteFooter.css';

const messages = defineMessages({});

// const SiteFooterComponent = styled.div`
//   line-height: 1;
//   padding-bottom: 3rem;
// `;

// const SiteFooterSnsIcons = styled.div`
//   border-bottom: 1px solid #3c3c3c;
//   text-align: center;
//   display: flex;
//   justify-content: space-between;

//   a {
//     display: inline-block;
//     padding: 1rem 0;
//     border-left: 1px solid #3c3c3c;
//     width: 100%;
//     &:first-child {
//       border-left: none;
//     }
//     &:link,
//     &:active,
//     &:hover,
//     &:visited {
//       color: #fff;
//     }
//     .icon {
//       &&& {
//         font-size: 2rem;
//       }
//     }
//   }
// `;

// const SiteFooterLogo = styled.div`
//   text-align: center;
//   padding-top: 2rem;
// `;

// const SiteFooterCopyright = styled.div`
//   text-align: center;
//   color: #fff;
//   padding-top: 2rem;
// `;

class SiteFooter extends React.Component {
  static propTypes = {
    /** タイトルラベル */
    titleImageLabel: PropTypes.string,
    // SNS Icons
    sns: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        icon: PropTypes.string,
      }),
    ),
  };

  render() {
    return (
      <div className="SiteFooter">
        <div className="snsIcons">
          <div className="snsIcons-inner">
            {_.map(this.props.sns, (item) => {
              return (
                <a
                  href={item.url}
                  key={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.icon === 'twitter' ? (
                    <IconComponent
                      name={xSVG}
                      size={StyleConfig.footerIconSize}
                    />
                  ) : (
                    <Icon icon={item.icon} size={StyleConfig.footerIconSize} />
                  )}
                </a>
              );
            })}
          </div>
        </div>
        <div className="logo">
          <img
            width="33"
            src={
              this.props.footer_logo?.src
                ? flattenToAppURL(this.props.footer_logo.src)
                : ''
            }
            alt={this.props.footer_logo?.alt}
          />
        </div>
        {this.props?.footer_text_link && (
          <ul className="textLink">
            {this.props?.footer_text_link.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={flattenToAppURL(item?.url)}>{item?.name}</Link>
                </li>
              );
            })}
          </ul>
        )}
        <div className="copyright">
          <small>{this.props.copyright}</small>
        </div>
      </div>
    );
  }
}

/**
 * コンテナ作成
 */
const SiteFooterContainer = connect(
  (state, props) => {
    // 現在のロケールを取得
    const locale = state?.content?.data?.language?.token ?? 'ja';
    return {
      // jpのSNSを取得
      sns:
        state.siteMainInfo?.result?.data?.attributes?.locale?.[locale]?.footer
          ?.sns,
      footer_logo:
        state.siteMainInfo?.result?.data?.attributes?.locale?.[locale]?.footer
          ?.footer_logo,
      footer_text_link:
        state.siteMainInfo?.result?.data?.attributes?.locale?.[locale]?.footer
          ?.footer_text_link,
      copyright:
        state.siteMainInfo?.result?.data?.attributes?.locale?.[locale]?.footer
          ?.copyright,
    };
  },
  (dispatch, props) => {
    return {};
  },
)(SiteFooter);

export default injectIntl(SiteFooterContainer, {});
