/**
 * Side Nav component for SP.
 * @module components/theme/SideNavSp
 */

import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Icon } from '../../../';
import {
  GlobalStyle,
  StyleConfig,
  MediaQueryConfig,
} from '../../../../helpers/GlobalStyle/GlobalStyle';
import './SideNavSp.css';
import { TeacherCommentButton, TermButton } from '../../../index';

const messages = defineMessages({});

class SideNavSp extends React.Component {
  static propTypes = {
    setVisibleLeftBar: PropTypes.func,
    visibleLeftBar: PropTypes.bool,
    setVisibleRightBar: PropTypes.func,
    visibleRightBar: PropTypes.bool,
    history: PropTypes.object,
    locale: PropTypes.string,
  };
  static defaultProps = {};

  setVisibleLeftBar(visible) {
    this.props.setVisibleLeftBar(true);
  }

  setVisibleRightBar(visible) {
    this.props.setVisibleRightBar(true);
  }

  render() {
    return (
      <div className="SideNavSp">
        <div className="left">
          <button
            className="MenuButton"
            onClick={() => {
              this.setVisibleLeftBar();
            }}
          >
            {this.props.visibleLeftBar ? (
              <Icon
                icon="close"
                size={StyleConfig.menuIconSize}
                color={StyleConfig.iconColorLight}
              ></Icon>
            ) : (
              <Icon
                icon="bars"
                size={StyleConfig.menuIconSize}
                color={StyleConfig.iconColorLight}
              ></Icon>
            )}
          </button>
          <button
            className="IconButton"
            onClick={(e) => {
              this.props.history.push('/search');
            }}
          >
            <Icon
              icon="search"
              size={StyleConfig.menuIconSizeSmall}
              color={StyleConfig.iconColorLight}
            ></Icon>
          </button>
          <button
            className="IconButton"
            onClick={(e) => {
              this.props.history.push(`/${this.props.locale}/contact`);
            }}
          >
            <Icon
              icon="mail outline"
              size={StyleConfig.menuIconSizeSmall}
              color={StyleConfig.iconColorLight}
            ></Icon>
          </button>
        </div>
        <div className={`right`}>
          <TermButton termTo="#TechnicalGlossary"></TermButton>
          <TeacherCommentButton
            setVisibleRightBar={() => {
              this.setVisibleRightBar();
            }}
            visibleRightBar={this.props.visibleRightBar}
          ></TeacherCommentButton>
        </div>
      </div>
    );
  }
}

export default injectIntl(SideNavSp, {});
