/**
 * 日時スタイルの文字列を日付スタイルに変換
 * @param {string} str @typeの値
 */

export const strDateTimeToStrDate = (str) => (str && str.split('T')[0]) || '';

/**
 * 今日から指定した期間（単位:日）後の日付を返す
 * @param {number} range 期間
 */
export const getDayAfter = (range = 30) => {
  let date = new Date();
  date.setDate(date.getDate() + range);
  return date;
};
