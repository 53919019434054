/**
 * Edit text cell component for Definition list block.
 * @module components/manage/Blocks/DefinitionList/Cell
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Map } from 'immutable';
import {
  convertFromRaw,
  DefaultDraftBlockRenderMap,
  EditorState,
} from 'draft-js';
import { defineMessages, injectIntl } from 'react-intl';
import Editor from 'draft-js-plugins-editor';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import { stateFromHTML } from 'draft-js-import-html';
import { settings } from '@plone/volto/config';
import {
  createToolbarPositionReviser,
  getToolbarDOM,
  toolbarReviseConfig,
} from '~/helpers';

const messages = defineMessages({
  term: {
    id: 'Type the term text…',
    defaultMessage: 'Type the term text…',
  },
  description: {
    id: 'Type the description…',
    defaultMessage: 'Type the description…',
  },
});

const blockTermRenderMap = Map({
  unstyled: {
    element: 'dt',
  },
});
const blockDescriptionRenderMap = Map({
  unstyled: {
    element: 'dd',
  },
});
const extendedTermBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  blockTermRenderMap,
);
const extendedDescriptionBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  blockDescriptionRenderMap,
);

/**
 * Edit definition list item class.
 * @class DefinitionListItem
 * @extends Component
 */
class DefinitionListItem extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    block: PropTypes.string.isRequired,
    term_value: PropTypes.object,
    description_value: PropTypes.object,
    index: PropTypes.number,
    onSelectListItem: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    isListBlockSelected: PropTypes.func.isRequired,
    onChangeTermElement: PropTypes.func.isRequired,
    onChangeDescriptionElement: PropTypes.func.isRequired,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    detached: false,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Cell
   */
  constructor(props) {
    super(props);
    if (!__SERVER__) {
      let termEditorState;
      let descriptionEditorState;
      if (props.term_value && props.term_value.blocks[0].text) {
        termEditorState = EditorState.createWithContent(
          stateFromHTML(props.term_value.blocks[0].text),
        );
      } else {
        termEditorState = EditorState.createEmpty();
      }
      if (props.description_value) {
        descriptionEditorState = EditorState.createWithContent(
          convertFromRaw(props.description_value),
        );
      } else {
        descriptionEditorState = EditorState.createEmpty();
      }
      const inlineToolbarPlugin = createInlineToolbarPlugin({
        structure: settings.richTextEditorInlineToolbarButtons,
      });
      this.state = {
        termEditorState,
        descriptionEditorState,
        inlineToolbarPlugin,
      };
    }
    this.onChangeTerm = this.onChangeTerm.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
  }

  /**
   * Component did mount lifecycle method
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (this.termNode) {
      const onFocus = this.termNode.editor._onFocus;
      this.termNode.editor._onFocus = (event) => {
        onFocus(event);
        this.props.onSelectListItem(this.props.index);
      };
    }
    if (this.descriptionNode) {
      const onFocus = this.descriptionNode.editor._onFocus;
      this.descriptionNode.editor._onFocus = (event) => {
        onFocus(event);
        this.props.onSelectListItem(this.props.index);
      };
    }
    this.toolbarReviser = createToolbarPositionReviser(this.props.block);
    this.toolBarDOM = getToolbarDOM(this.props.block);
    if (this.toolbarReviser && this.toolBarDOM) {
      this.toolbarReviser.observe(this.toolBarDOM, toolbarReviseConfig);
    }
  }

  /**
   * Component will unmount lifecycle method
   * @method componentWillUnmount
   * @returns {undefined}
   */
  componentWillUnmount() {
    if (this.toolbarReviser) {
      this.toolbarReviser.disconnect();
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.isListBlockSelected !== this.props.isListBlockSelected &&
      this.props.index === 0
    ) {
      this.descriptionNode.focus();
    }
  }

  /**
   * Change term handler
   * @method onChangeTerm
   * @param {object} editorState Editor state.
   * @returns {undefined}
   */
  onChangeTerm(termEditorState) {
    this.setState({ termEditorState }, () => {
      this.props.onChangeTermElement(this.props.index, termEditorState);
    });
  }

  /**
   * Change description handler
   * @method onChangeDescription
   * @param {object} editorState Editor state.
   * @returns {undefined}
   */
  onChangeDescription(descriptionEditorState) {
    this.setState({ descriptionEditorState }, () => {
      this.props.onChangeDescriptionElement(
        this.props.index,
        descriptionEditorState,
      );
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    const { InlineToolbar } = this.state.inlineToolbarPlugin;
    return (
      <div>
        <Editor
          onChange={this.onChangeTerm}
          editorState={this.state.termEditorState}
          blockRenderMap={extendedTermBlockRenderMap}
          blockStyleFn={settings.blockStyleFn}
          placeholder={this.props.intl.formatMessage(messages.term)}
          ref={(node) => {
            this.termNode = node;
          }}
          handleReturn={() => true}
        />
        <Editor
          onChange={this.onChangeDescription}
          editorState={this.state.descriptionEditorState}
          plugins={[
            this.state.inlineToolbarPlugin,
            ...settings.richTextEditorPlugins,
          ]}
          blockRenderMap={extendedDescriptionBlockRenderMap}
          blockStyleFn={settings.blockStyleFn}
          customStyleMap={settings.customStyleMap}
          placeholder={this.props.intl.formatMessage(messages.description)}
          ref={(node) => {
            this.descriptionNode = node;
          }}
        />
        <InlineToolbar />
      </div>
    );
  }
}

export default injectIntl(DefinitionListItem);
