/**
 * Site Header component.
 * @module components/theme/SiteHeader
 */

import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './SiteHeader.css';
import { connect } from 'react-redux';
import { LanguageSelectorSP } from '../../../index';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getLocale } from '../../../../helpers/Locale/Locale';

const messages = defineMessages({});

class SiteHeader extends React.Component {
  static propTypes = {
    /** タイトルラベル */
    titleImageLabel: PropTypes.string,
    /** タイトルリンク先 */
    titleImageUrl: PropTypes.string,
    /** タイトル画像URL */
    titleImageImg: PropTypes.string,
    /** Locale */
    locale: PropTypes.string,
  };

  render() {
    return (
      <div id="SiteHeader" className="SiteHeader">
        <Link to={this.props.titleImageUrl}>
          <img
            width="109"
            src={
              this.props.titleImageImg
                ? flattenToAppURL(this.props.titleImageImg)
                : ''
            }
            alt={this.props.titleImageLabel}
          />
        </Link>
        <LanguageSelectorSP locale={this.props.locale}></LanguageSelectorSP>
      </div>
    );
  }
}

/**
 * コンテナ
 */
const SiteHeaderContainer = connect(
  (state, props) => {
    const locale = getLocale(state?.content?.data?.language?.token);
    return {
      locale,
    };
  },
  (dispatch, props) => {
    return {};
  },
)(SiteHeader);

export default injectIntl(SiteHeaderContainer, {});
