/**
 * View text block.
 * @module components/manage/Blocks/Text/View
 */

import PropTypes from 'prop-types';
import redraft from 'redraft';
import React from 'react';
import { settings } from '~/config';
import './View.css';

/**
 * View text block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => (
  <div className="TextBlock">
    {data.text ? (
      redraft(data.text, settings.ToHTMLRenderers, settings.ToHTMLOptions)
    ) : (
      <br />
    )}
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
