/**
 * Styled Component / React Responsive 用
 */
export const MediaQueryWidth = {
  SP: 480,
  LANDSCAPE: 768,
  TABLET: 768,
  PC: 1025,
};

/**
 * Global Style Config
 */
export const StyleConfig = {
  /** フッターのアイコンサイズ */
  footerIconSize: '18px',
  /** メニューのアイコンサイズ */
  menuIconSize: '18px',
  /** カルーセルのアイコンサイズ */
  carouselIconSize: '22px',
  /** メニューのアイコンサイズ(小) */
  menuIconSizeSmall: '14px',
  /** アイコンカラー (黒背景向け) */
  iconColorDark: '#fff',
  /** アイコンカラー (黒背景向け阪大カラー) */
  iconColorDarkUniversity: '#fdd000',
  /** アイコンカラー (白背景向け) */
  iconColorLight: '#000',
  /** アイコンカラー (白背景向け阪大カラー) */
  iconColorLightUniversity: '#2d287f',
  /** アイコンカラー (薄い色) */
  iconColorLightGray: '#aaa',
  /** h3見出し */
  defaultH3Color: '#1B1B1B',
};

/**
 * Styled Component 用
 */
export const MediaQueryConfig = {
  // PCとタブレット向け
  PC_AND_TABLET: `screen and (min-width: ${MediaQueryWidth.SP + 1}px)`,
  // PC向け
  PC: `screen and (min-width: ${MediaQueryWidth.TABLET + 1}px)`,
  // スマホ＆タブレット
  SP_AND_TABLET: `screen and (max-width: ${MediaQueryWidth.TABLET}px)`,
  // タブレット向け
  TABLET: `screen and (min-width: ${
    MediaQueryWidth.SP + 1
  }px) and (max-width: ${MediaQueryWidth.TABLET}px)`,
  // スマホ
  DEFAULT_SP: `screen and (max-width: ${MediaQueryWidth.SP}px)`,
  // 文字操作用、スマホ最小
  TEXT_MIN_FOR_SP: `screen and (min-width: ${MediaQueryWidth.SP + 1}px)`,
  // 文字操作用、スマホ最大
  TEXT_MAX_FOR_SP: `screen and (min-width: ${MediaQueryWidth.TABLET + 1}px)`,
};

/**
 * レスポンシブコンポーネント用
 */
export function isSpAndTablet() {
  if (typeof window !== 'undefined') {
    return window.innerWidth < MediaQueryWidth.TABLET;
  } else {
    return false;
  }
}
