import React from 'react';
import './ScrollToTop.css';
import { Icon } from '../../../';
import { StyleConfig } from '../../../../helpers/GlobalStyle/GlobalStyle';

export default class ScrollToTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false,
    };
  }

  componentDidMount() {
    this.eventHandler = this.scrollHandler.bind(this);
    window.addEventListener('scroll', this.eventHandler);
  }

  scrollHandler(e) {
    this.toggleVisibility();
  }

  toggleVisibility() {
    if (typeof window !== 'undefined') {
      if (window.pageYOffset > 300) {
        this.setState({
          is_visible: true,
        });
      } else {
        this.setState({
          is_visible: false,
        });
      }
    }
  }

  scrollToTop() {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.eventHandler);
  }

  render() {
    return (
      <>
        {this.state.is_visible ? (
          <button className="ScrollToTop" onClick={() => this.scrollToTop()}>
            <div className="scroll-to-top">
              <Icon icon="angle up" size={StyleConfig.footerIconSize}></Icon>
            </div>
          </button>
        ) : null}
      </>
    );
  }
}
