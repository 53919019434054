/**
 * Feature Folder view component.
 * @module components/theme/View/FeatureFolderView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Helmet } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';
import { blocks } from '~/config';
import { defineMessages, injectIntl } from 'react-intl';
import Masonry from 'react-masonry-css';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';
import _ from 'lodash';
import './FeatureFolderView.css';
import { connect } from 'react-redux';
import { featureList, featureListForTop } from '../../../actions/';
import { useMediaQuery } from 'react-responsive';
import { MediaQueryConfig } from '../../../helpers/GlobalStyle/GlobalStyle';
import PastFeatureCollection from '../Site/Collection/PastFeatureCollection';
import {
  DefaultBody,
  FolderLeadSentence,
  FolderTitle,
  HelmetTitle,
  HorizontalRule,
  LabelFeature,
} from './Common';
import { ExternalLink } from '../../index';
import {
  FeatureFolderMainInnerBody,
  FeatureFolderMainBodyHasBlocks,
} from './FeatureMainFolderView';

/**
 * OuFolderView view component class.
 * @function OuFolderView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

/*export const FeatureFolderHeader = ({ content, result }) => {
  return (
    <div className="featureFolderHeader">
      <div className="featureFolderHeaderInner">
        <HorizontalRule className="hr" />
        <FolderTitle
          title={result?.title}
          title_sp1={result?.title_sp1}
          title_sp2={result?.title_sp2}
        />
        <FolderLeadSentence
          lead_sentence={result?.lead_sentence}
          lead_sentence_sp1={result?.lead_sentence_sp1}
          lead_sentence_sp2={result?.lead_sentence_sp2}
        />
      </div>
    </div>
  );
};*/

export const FeatureFolderBody = ({ content, featureList }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  const breakpointSubItems = {
    default: 4,
    1200: 3,
    1100: 2,
  };
  return (
    <div className="FeatureFolderMainBodyHasBlocks featureFolderBody">
      <div className="FeatureFolderMainBodyHasBlocks-inner">
        <div className="labelFeatureWrapper">
          <LabelFeature className="labelFeature" />
        </div>
        <h2 className="title">{content.title}</h2>
        <div className="featureFolderBodyInner">
          {/* Header */}
          <div className="fheader">
            {isSP ? (
              content.title_image_sp ? (
                <img
                  className="image"
                  src={flattenToAppURL(
                    content?.title_image_sp?.scales?.large?.download ?? '',
                  )}
                  alt=""
                />
              ) : (
                <img
                  className="image"
                  src={flattenToAppURL(
                    '/assets/images/nowprinting_580_280.jpg',
                  )}
                  alt=""
                />
              )
            ) : content.title_image_pc ? (
              <img
                className="image"
                src={flattenToAppURL(
                  content?.title_image_pc?.scales?.large?.download ?? '',
                )}
                alt=""
              />
            ) : (
              <img
                className="image"
                src={flattenToAppURL('/assets/images/nowprinting_580_280.jpg')}
                alt=""
              />
            )}

            <div className="headerInner">
              <h3 className="subtitle">{content.subtitle}</h3>
              <div className="lead_sentence">{content.lead_sentence}</div>
            </div>
          </div>

          {/* Body */}
          <div className="body show">
            <HorizontalRule className="secondary" />
            {/* {isSP ? null : <HorizontalRule className="hr" />} */}
            <DefaultBody content={content} />
          </div>
        </div>

        {/* <div className="sub-items">
          {_.map(featureList, (item) => {
            return (
              <div className="sub-item" key={item?.url}>
                <Link className="sub-item-link" to={item?.url}>
                  <div className="sub-item-image">
                    <img src={item?.image} alt="" />
                  </div>
                  <h3>{item?.title}</h3>
                </Link>
              </div>
            );
          })}
        </div> */}

        <Masonry
          breakpointCols={breakpointSubItems}
          className="sub-items"
          columnClassName=""
        >
          {_.map(featureList, (item) => {
            const url = item?.url;
            const redirect = item?.redirect;
            return (
              <div key={item?.url} className="sub-item">
                <Link
                  className="sub-item-link"
                  to={{ pathname: redirect ? redirect : flattenToAppURL(url) }}
                  target={redirect ? '_blank' : ''}
                >
                  <div className="sub-item-image">
                    <img src={item?.image} alt="" />
                  </div>
                  <h3>
                    {item?.title}
                    <ExternalLink link={url} redirect={redirect} />
                  </h3>
                </Link>
              </div>
            );
          })}
        </Masonry>
      </div>
    </div>
  );
};

const FeatureFolderView = ({
  content,
  result,
  loadContent,
  featureList,
  locale,
}) => {
  React.useEffect(() => {
    loadContent(locale, content.id);
  }, []);

  return (
    <div className="FeatureFolderView">
      <HelmetTitle pageTitle={content.title} />
      {/*<FeatureFolderHeader content={content} result={result} />*/}
      <FeatureFolderBody content={content} featureList={featureList} />
      <PastFeatureCollection />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
FeatureFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        review_state: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }).isRequired,
};

const FeatureFolderViewContainer = connect(
  (state, props) => {
    return {
      result: state?.featureListForTop?.result?.data?.attributes,
      featureList: state?.featureList?.result?.data?.attributes?.data,
      locale: state?.content?.data?.language?.token ?? 'ja',
    };
  },
  (dispatch, props) => {
    return {
      loadContent: (locale, cid) => {
        dispatch(featureListForTop(locale));
        dispatch(featureList(locale, cid));
      },
    };
  },
)(FeatureFolderView);

export default injectIntl(FeatureFolderViewContainer);
