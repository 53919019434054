import { UPDATE_BROWSING_HISTORY } from '../../constants/ActionTypes';
import cookie from 'react-cookie';
import { CookieSetting } from '../../constants/Setting';

const initialState = {
  history: '',
};

/**
 * BrowsingHistory Reducer
 * @param {*} state
 * @param {*} action
 */
export default function browsingHistorySaver(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case UPDATE_BROWSING_HISTORY:
      cookie.save(CookieSetting.VIEW_HISTORIES.name, action.history, {
        ...CookieSetting.VIEW_HISTORIES.option,
        expires: action.expireCookie,
      });
      return {
        ...state,
        history: action.history,
      };
    default:
      return state;
  }
}
