/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
// import { RESOU_SAMPLE_ACTION } from '../constants/ActionTypes';
// import { handleActions } from 'redux-actions';
// import { resouSampleAction } from '../actions';
import { createSlice } from '@reduxjs/toolkit';
import siteMainInfo from './siteMainInfo/siteMainInfo';
import storyzList from './storyzList/storyzList';
import storyzListRelatedtoTeacher from './storyzListRelatedtoTeacher/storyzListRelatedtoTeacher';
import featureList from './featureList/featureList';
import featureListForTop from './featureListForTop/featureListForTop';
import popularArticleList from './popularArticleList/popularArticleList';
import itemListRelatedtoTeacher from './itemListRelatedtoTeacher/itemListRelatedtoTeacher';
import itemListRelatedtoDepartment from './itemListRelatedtoDepartment/itemListRelatedtoDepartment';
import pastFeatureList from './pastFeatureList/pastFeatureList';
import siteImage from './siteImage/siteImage';
import searchBasic from './searchBasic/searchBasic';
import termContent from './termContent/termContent';
import customDimmer from './customDimmer/customDimmer';
import browsingHistoryList from './browsingHistoryList/browsingHistoryList';
import browsingHistorySaver from './browsingHistorySaver/browsingHistorySaver';
import resouGraphData from './resouGraphData/resouGraphData';
import searchResult from './searchResult/searchResult';
import featureFolderList from './featureFolderList/featureFolderList';
import sendMailForm from './sendMailForm/sendMailForm';
import tagList from './tagList/tagList';
import mailFormFormIds from './mailFormFormIds/mailFormFormIds';
import researchListOnTop from './researchListOnTop/researchListOnTop';
import researchView from './researchView/researchView';
import searchTag from './searchTag/searchTag';

/**
 * 初期状態の定義
 */
// const initialState = {
//   message: '',
// };

// /**
//  * 昔ながらの Redux の方法で Reducer を作成
//  * @param {*} state
//  * @param {*} action
//  */
// function resouSampleReducer(state = initialState, action = {}) {
//   switch (action.type) {
//     case RESOU_SAMPLE_ACTION:
//       console.log(action);
//       return { ...state, message: action.message };
//     default:
//       return state;
//   }
// }

/**
 * 今時の Redux の方法で Action と Reducer を一気に作成
 */
// export const resouSampleReducer2 = createSlice({
//   name: 'RESOU_SAMPLE_ACTION',
//   initialState: initialState,
//   reducers: {
//     message: (state, action) => {
//       // console.log({ action });
//       return {
//         ...state,
//         message: action.payload.message,
//       };
//     },
//   },
// });

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  // Add your reducers here
  // handleActions({
  //   [Actions.RESOU_SAMPLE_ACTION]: (state, action) => {return {
  //     ...state
  //   }}
  // }, initialState)
  // 昔ながらの Redux の方法で作った Reducer
  // resouSampleReducer,
  siteMainInfo: siteMainInfo,
  storyzList: storyzList,
  storyzListRelatedtoTeacher: storyzListRelatedtoTeacher,
  siteImage: siteImage,
  searchBasic: searchBasic,
  termContent: termContent,
  customDimmer: customDimmer,
  featureList: featureList,
  featureListForTop: featureListForTop,
  popularArticleList: popularArticleList,
  itemListRelatedtoTeacher: itemListRelatedtoTeacher,
  itemListRelatedtoDepartment: itemListRelatedtoDepartment,
  pastFeatureList: pastFeatureList,
  browsingHistoryList: browsingHistoryList,
  browsingHistorySaver: browsingHistorySaver,
  resouGraphData: resouGraphData,
  searchResult: searchResult,
  featureFolderList: featureFolderList,
  sendMailForm: sendMailForm,
  tagList: tagList,
  mailFormFormIds: mailFormFormIds,
  researchListOnTop: researchListOnTop,
  researchView: researchView,
  searchTag: searchTag,

  // 今時の Redux の方法で作った Reducer
  // resouSampleReducer2: resouSampleReducer2.reducer,
};

export default reducers;
