import React, { useEffect } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  Helmet,
} from '@plone/volto/helpers';
import {
  StoryzList,
  GenericList,
  FeatureList,
  BrowsingHistoryList,
  SearchWidgetForSearch,
} from '../../../components/index';
import {
  // MainGraph,
  MainGraphAutoSelect,
  PopularArticleCollection,
  ResearchCollection,
} from '../../../components/';
import { blocks } from '~/config';
import { map } from 'lodash';
import { SearchWidget } from '@plone/volto/components';
import { HelmetTitle } from './Common';
import './LanguageRootFolderView.css';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

/**
 * Language root folder view component class.
 * @function LanguageRootFolderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const LanguageRootFolderView = ({ content, intl, ...otherProps }) => {
  // const blocksFieldname = getBlocksFieldname(content);
  // const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

  return (
    <div className="LanguageRootFolderView view-wrapper">
      <HelmetTitle pageTitle={content.title} />
      {/* 検索窓 */}
      <div className="SearchWidgetForTop">
        <SearchWidgetForSearch
          pathname={otherProps.location.pathname}
          location={otherProps.location}
          locale={content.language.token}
        />
      </div>

      {/* d3を使ったグラフ */}
      <MainGraphAutoSelect pathname={otherProps.location.pathname} />
      {/* <div>
        {map(content[blocksLayoutFieldname].items, block => {
          const blockType =
            blocks.blocksConfig[content[blocksFieldname]?.[block]?.['@type']];
          const isTitle = blockType?.['id'] === 'title';
          if (isTitle) {
            return <></>;
          }
          const Block = blockType?.['view'] || null;
          return Block !== null ? (
            <Block
              key={block}
              id={block}
              properties={content}
              data={content[blocksFieldname][block]}
            />
          ) : (
            <div key={block}>
              {intl.formatMessage(messages.unknownBlock, {
                block: content[blocksFieldname]?.[block]?.['@type'],
              })}
            </div>
          );
        })}
      </div> */}
      {/* 特集まとめスライダー */}
      <FeatureList />
      {/* StoryZスライダー */}
      <StoryzList />
      {/* Research一覧 */}
      <ResearchCollection
        content={content}
        intl={intl}
        lang={content.language.token}
        pathname={otherProps.location.pathname}
        location={otherProps.location}
      />
      {/* よく読まれている記事ミニコレクション */}
      <PopularArticleCollection />
      {/* 閲覧履歴スライダー */}
      <BrowsingHistoryList />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
LanguageRootFolderView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        image_caption: PropTypes.string,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default injectIntl(LanguageRootFolderView);
