import cookie from 'react-cookie';
import { LocaleSetting } from '../../constants/Setting';
import { isString } from '../String/String';

/**
 * 日本語かどうか判定
 * @param {string} locale
 */
export function isJa(locale) {
  if (locale && locale === 'ja') {
    return true;
  }
  return false;
}

/**
 * 英語かどうか判定
 * @param {string} locale
 */
export function isEn(locale) {
  if (locale && locale === 'en') {
    return true;
  }
  return false;
}

/**
 * Localeを取得する関数
 * locationは通常Viewコンポーネントに渡ってくるので、それを渡すこと
 */
export function getLocale(location) {
  // cookie から取得
  const cookieLang = cookie.load('lang');

  // default
  const locale = location ?? cookieLang ?? LocaleSetting.DEFAULT_LANG;
  return locale;
}

/**
 * パスからデフォルトのロケールを取得
 * @param {*} pathname
 * @param {*} defaultValue
 */
export function getLocaleFromPathname(pathname, defaultValue) {
  if (isString(pathname)) {
    const locale = pathname.split('/')[1];
    return locale ? locale : defaultValue;
  }
  return defaultValue;
}
