/**
 * Edit image block.
 * @module components/manage/Blocks/Image/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { readAsDataURL } from 'promise-file-reader';
import { Button, Dimmer, Input, Loader, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';
import Dropzone from 'react-dropzone';

import { Icon, ImageSidebar, SidebarPortal } from '@plone/volto/components';
import { createContent, getContent } from '@plone/volto/actions';
// import {
//   createContent,
//   getContent,
// } from '../../../../../../omelette/src/actions/content/content';
import {
  flattenToAppURL,
  getBaseUrl,
  isInternalURL,
} from '@plone/volto/helpers';

import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import uploadSVG from '@plone/volto/icons/upload.svg';
import addDocumentSVG from '@plone/volto/icons/add-document.svg';
import './Edit.css';

const messages = defineMessages({
  ImageBlockInputPlaceholder: {
    id: 'Browse the site, drop an image, or type an URL',
    defaultMessage: 'Browse the site, drop an image, or type an URL',
  },
  Next: {
    id: 'Next',
    defaultMessage: 'Next',
  },
  AddFilesFolder: {
    id: 'Add files folder',
    defaultMessage: 'Add files folder',
  },
});

/**
 * Edit image block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    setContent: PropTypes.func.isRequired,
    loadContent: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
    dontHaveFilesFolder: PropTypes.bool,
    parent: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  state = {
    uploading: false,
    url: '',
    dragging: false,
    isFilesFolderCreated: false,
  };

  // constructor() {
  //   super();
  //   this.createFilesFolder = this.createFilesFolder.bind(this);
  // }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.request.loading &&
      nextProps.request.loaded &&
      this.state.uploading
    ) {
      this.setState({
        uploading: false,
      });
      // console.log(flattenToAppURL(nextProps.content['@id']));
      // console.log(flattenToAppURL(undefined));
      if (nextProps.content['@id']) {
        this.props.onChangeBlock(this.props.block, {
          ...this.props.data,
          url: flattenToAppURL(nextProps.content['@id']),
          alt: nextProps.content.title,
        });
      }
    }
  }

  /**
   * filesフォルダのpathnameを取得
   * @function getFilesFolderPathname
   * @param {object} parent - 親フォルダ
   * @returns {[string, null]} - filesフォルダのpathname
   */
  getFilesFolderPathname(parent) {
    const subContents = parent?.items;
    if (!subContents || subContents.length === 0) {
      return null;
    } else {
      for (let item of subContents) {
        if (item.id === 'files') {
          return item.url;
        }
      }
    }
    return null;
  }

  /**
   * Upload image handler (not used), but useful in case that we want a button
   * not powered by react-dropzone
   * @method onUploadImage
   * @returns {undefined}
   */
  onUploadImage = (e) => {
    e.stopPropagation();
    const file = e.target.files[0];
    const filesFolderPathName = this.getFilesFolderPathname(this.props.parent);
    this.setState({
      uploading: true,
    });
    readAsDataURL(file).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      this.props.setContent(filesFolderPathName, {
        '@type': 'Image',
        title: file.name,
        image: {
          data: fields[3],
          encoding: fields[2],
          'content-type': fields[1],
          filename: file.name,
        },
      });
      // this.props.createContent(
      //   getBaseUrl(this.props.pathname),
      //   {
      //     '@type': 'Image',
      //     title: file.name,
      //     image: {
      //       data: fields[3],
      //       encoding: fields[2],
      //       'content-type': fields[1],
      //       filename: file.name,
      //     },
      //   },
      //   this.props.block,
      // );
    });
  };

  /**
   * Upload image handler (not used), but useful in case that we want a button
   * not powered by react-dropzone
   * @method onUploadImage
   * @returns {undefined}
   */
  // onUploadImage = ({ target }) => {
  //   const file = target.files[0];
  //   const filesFolderPathName = this.getFilesFolderPathname(this.props.parent);
  //   this.setState({
  //     uploading: true,
  //   });
  //   readAsDataURL(file).then((data) => {
  //     const fields = data.match(/^data:(.*);(.*),(.*)$/);
  //     this.props.setContent(filesFolderPathName, {
  //       '@type': 'Image',
  //       title: file.name,
  //       image: {
  //         data: fields[3],
  //         encoding: fields[2],
  //         'content-type': fields[1],
  //         filename: file.name,
  //       },
  //     });
  //   });
  // };

  /**
   * Align block handler
   * @method onAlignBlock
   * @param {string} align Alignment option
   * @returns {undefined}
   */
  onAlignBlock(align) {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      align,
    });
  }

  /**
   * Change url handler
   * @method onChangeUrl
   * @param {Object} target Target object
   * @returns {undefined}
   */
  onChangeUrl = ({ target }) => {
    this.setState({
      url: target.value,
    });
  };

  /**
   * Submit url handler
   * @method onSubmitUrl
   * @param {object} e Event
   * @returns {undefined}
   */
  onSubmitUrl = () => {
    if (this.state.url) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        url: flattenToAppURL(this.state.url),
      });
    }
  };

  resetSubmitUrl = () => {
    this.setState({
      url: '',
    });
  };

  /**
   * Drop handler
   * @method onDrop
   * @param {array} files File objects
   * @returns {undefined}
   */
  // TODO:
  onDrop = (file) => {
    this.setState({
      uploading: true,
    });

    readAsDataURL(file[0]).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      // const files_folder = getBaseUrl(this.props.pathname) + '/files';
      const filesFolderPathname = this.getFilesFolderPathname(
        this.props.parent,
      );
      this.props.setContent(filesFolderPathname, {
        // this.props.createContent(filesFolderPathname, {
        // this.props.createContent(getBaseUrl(this.props.pathname), {
        '@type': 'Image',
        title: file[0].name,
        image: {
          data: fields[3],
          encoding: fields[2],
          'content-type': fields[1],
          filename: file[0].name,
        },
      });
    });
  };

  /**
   * Keydown handler on Variant Menu Form
   * This is required since the ENTER key is already mapped to a onKeyDown
   * event and needs to be overriden with a child onKeyDown.
   * @method onKeyDownVariantMenuForm
   * @param {Object} e Event object
   * @returns {undefined}
   */
  onKeyDownVariantMenuForm = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitUrl();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      // TODO: Do something on ESC key
    }
  };
  onDragEnter = () => {
    this.setState({ dragging: true });
  };
  onDragLeave = () => {
    this.setState({ dragging: false });
  };

  node = React.createRef();

  /**
   * アップロード／選択された画像を保存するfilesフォルダを作成する
   * @function createFilesFolder
   * @param {object} e
   */
  createFilesFolder = (e) => {
    const parentPathname = this.props.parent
      ? flattenToAppURL(this.props.parent['@id'])
      : null;
    if (parentPathname && !this.state.isFilesFolderCreated) {
      this.props.setContent(getBaseUrl(parentPathname), {
        '@type': 'Folder',
        title: 'files',
      });
      this.setState({ isFilesFolderCreated: true });
      // this.props.loadContent(parentPathname);
    }
  };

  /**
   * filesフォルダ作成後にBlockを再描画するハンドラ
   * @function reloadContent
   * @param {object} e
   */
  reloadContent(e) {
    const parentPathname = this.props.parent
      ? flattenToAppURL(this.props.parent['@id'])
      : null;
    this.props.loadContent(parentPathname);
  }

  render() {
    const { data } = this.props;
    const placeholder =
      this.props.data.placeholder ||
      this.props.intl.formatMessage(messages.ImageBlockInputPlaceholder);
    const filesFolderPathname = this.getFilesFolderPathname(this.props.parent);
    return (
      <div
        className={cx(
          'block image align',
          {
            center: !Boolean(data.align),
          },
          data.align,
        )}
      >
        {data?.url ? (
          <img
            className={cx({
              'full-width': data.align === 'full',
              large: data.size === 'l',
              medium: data.size === 'm',
              small: data.size === 's',
            })}
            src={
              isInternalURL(data.url)
                ? // Backwards compat in the case that the block is storing the full server URL
                  (() => {
                    if (data.size === 'l')
                      return `${flattenToAppURL(data.url)}/@@images/image`;
                    if (data.size === 'm')
                      return `${flattenToAppURL(
                        data.url,
                      )}/@@images/image/preview`;
                    if (data.size === 's')
                      return `${flattenToAppURL(data.url)}/@@images/image/mini`;
                    return `${flattenToAppURL(data.url)}/@@images/image`;
                  })()
                : data.url
            }
            alt={data.alt || ''}
          />
        ) : !filesFolderPathname ? (
          <div>
            <Button.Group>
              <Button
                basic
                icon
                onClick={(e) => {
                  e.stopPropagation();
                  this.createFilesFolder();
                }}
              >
                <Icon name={addDocumentSVG} size="24px" />
              </Button>
            </Button.Group>
            <span className="ButtonText">
              {' '}
              {this.props.intl.formatMessage(messages.AddFilesFolder)}
            </span>
            　
            <Button
              basic
              icon
              onClick={(e) => {
                e.stopPropagation();
                this.reloadContent();
              }}
            >
              <Icon name={aheadSVG} size="30px" />
              {this.props.intl.formatMessage(messages.Next)}
            </Button>
          </div>
        ) : (
          <div>
            <Dropzone
              noClick
              onDrop={this.onDrop}
              onDragEnter={this.onDragEnter}
              onDragLeave={this.onDragLeave}
              className="dropzone"
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <Message>
                    {this.state.dragging && <Dimmer active></Dimmer>}
                    {this.state.uploading && (
                      <Dimmer active>
                        <Loader indeterminate>Uploading image</Loader>
                      </Dimmer>
                    )}
                    <center>
                      <img src={imageBlockSVG} alt="" />
                      <div className="toolbar-inner">
                        <Button.Group>
                          <Button
                            basic
                            icon
                            onClick={(e) => {
                              e.stopPropagation();
                              this.props.openObjectBrowser();
                            }}
                          >
                            <Icon name={navTreeSVG} size="24px" />
                          </Button>
                        </Button.Group>
                        <Button.Group>
                          <label className="ui button basic icon">
                            <Icon name={uploadSVG} size="24px" />
                            <input
                              {...getInputProps({
                                type: 'file',
                                onChange: this.onUploadImage,
                                style: { display: 'none' },
                              })}
                            />
                          </label>
                        </Button.Group>
                        <Input
                          onKeyDown={this.onKeyDownVariantMenuForm}
                          onChange={this.onChangeUrl}
                          placeholder={placeholder}
                          value={this.state.url}
                          // Prevents propagation to the Dropzone and the opening
                          // of the upload browser dialog
                          onClick={(e) => e.stopPropagation()}
                        />
                        {this.state.url && (
                          <Button.Group>
                            <Button
                              basic
                              className="cancel"
                              onClick={(e) => {
                                e.stopPropagation();
                                this.setState({ url: '' });
                              }}
                            >
                              <Icon name={clearSVG} size="30px" />
                            </Button>
                          </Button.Group>
                        )}
                        <Button.Group>
                          <Button
                            basic
                            primary
                            disabled={!this.state.url}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.onSubmitUrl();
                            }}
                          >
                            <Icon name={aheadSVG} size="30px" />
                          </Button>
                        </Button.Group>
                      </div>
                    </center>
                  </Message>
                </div>
              )}
            </Dropzone>
            {/* <Dropzone disableClick onDrop={this.onDrop} className="dropzone">
              <Message>
                {this.state.uploading && (
                  <Dimmer active>
                    <Loader indeterminate>Uploading image</Loader>
                  </Dimmer>
                )}
                <center>
                  <img src={imageBlockSVG} alt="" />
                  <div className="toolbar-inner">
                    <Button.Group>
                      <Button
                        basic
                        icon
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.openObjectBrowser();
                        }}
                      >
                        <Icon name={navTreeSVG} size="24px" />
                      </Button>
                    </Button.Group>
                    <Button.Group>
                      <label className="ui button basic icon">
                        <Icon name={uploadSVG} size="24px" />
                        <input
                          type="file"
                          onChange={this.onUploadImage}
                          style={{ display: 'none' }}
                        />
                      </label>
                    </Button.Group>
                    <Input
                      onKeyDown={this.onKeyDownVariantMenuForm}
                      onChange={this.onChangeUrl}
                      placeholder={this.props.intl.formatMessage(
                        messages.ImageBlockInputPlaceholder,
                      )}
                      value={this.state.url}
                      // Prevents propagation to the Dropzone and the opening
                      // of the upload browser dialog
                      onClick={(e) => e.stopPropagation()}
                    />
                    {this.state.url && (
                      <Button.Group>
                        <Button
                          basic
                          className="cancel"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({ url: '' });
                          }}
                        >
                          <Icon name={clearSVG} size="30px" />
                        </Button>
                      </Button.Group>
                    )}
                    <Button.Group>
                      <Button
                        basic
                        primary
                        disabled={!this.state.url}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.onSubmitUrl();
                        }}
                      >
                        <Icon name={aheadSVG} size="30px" />
                      </Button>
                    </Button.Group>
                  </div>
                </center>
              </Message>
            </Dropzone> */}
          </div>
        )}
        <SidebarPortal selected={this.props.selected}>
          <ImageSidebar {...this.props} resetSubmitUrl={this.resetSubmitUrl} />
        </SidebarPortal>
      </div>
    );
  }
}

/**
 * filesフォルダの有無と親コンポーネントのpathnameを取得する中間コンポーネント
 * @function EditMiddleContainer
 * @param id {string} 自コンテンツのID
 * @param loadContent {func} 次項のparentを取得しにいく関数
 * @param parent {[object, null, undefined]}} filesフォルダの親コンポーネントオブジェクト
 * @param parentId {string} 自コンテンツの親オブジェクトのId
 * @param pathname {string} 自コンテンツのpathname
 * @param setContent {func} コンテンツを生成する関数
 */
const EditMiddleContainer = ({
  // 自コンポーネントで使用
  loadContent,
  parentId,
  pathname,
  contentObj,
  contentCreated,
  // Editコンポーネントに渡す
  sourceProps,
  request,
  content,
  setContent,
  parent,
  test,
}) => {
  let parentPathname;
  const mode = pathname.split('/').slice(-1)[0];
  // if (['add', 'edit'].indexOf(mode) !== -1) {
  if (mode === 'edit' && parentId) {
    parentPathname = flattenToAppURL(parentId);
  } else if (mode === 'add') {
    parentPathname = getBaseUrl(pathname);
  } else {
    parentPathname = null;
  }
  const [hasFilesFolder, setHasFilesFolder] = React.useState(false);
  React.useEffect(() => {
    // const filesFolderCreated = () => {
    //   if (
    //     contentCreated.loaded &&
    //     contentObj?.title === 'files' &&
    //     contentObj?.['@type'] === 'Folder' &&
    //     flattenToAppURL(contentObj?.parent?.['@id']) === pathname
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // };
    if (parentPathname && !hasFilesFolder) {
      // } else if (filesFolderCreated && parentPathname) {
      loadContent(parentPathname);
      setHasFilesFolder(true);
    }
  }, [
    loadContent,
    parentPathname,
    contentCreated.loaded,
    contentObj,
    hasFilesFolder,
  ]);
  return (
    <Edit
      {...sourceProps}
      request={request}
      content={content}
      setContent={setContent}
      loadContent={loadContent}
      parent={parent}
    />
  );
};

/**
 * Reduxを接続するコンテナコンポーネント
 * @function EditContainer
 */
const EditContainer = connect(
  (state, props) => ({
    sourceProps: props,
    test: state.content.subrequests,
    // request: state.content.subrequests[props.block] || {},
    // content: state.content.subrequests[props.block]?.data,
    request: state.content.create,
    content: state.content.data,
    parent: state?.content?.subrequests?.[`parent-of-${props.id}`]?.data,
    parentId: props?.properties?.parent?.['@id'],
    contentObj: state?.content?.data,
    contentCreated: state?.content?.create,
  }),
  (dispatch, props) => ({
    setContent: (url, content) => {
      dispatch(createContent(url, content));
    },
    loadContent: (parentPathname) => {
      dispatch(
        getContent(
          getBaseUrl(parentPathname),
          props.versionId,
          `parent-of-${props.id}`,
        ),
      );
    },
  }),
)(EditMiddleContainer);

export default injectIntl(EditContainer);
