import React from 'react';
import { LanguageSelector } from '@plone/volto/components';
import './LanguageSelectorPC.css';

const LanguageSelectorPC = (props) => {
  return (
    <div className="LanguageSelectorPC">
      <LanguageSelector locale={props.locale} />
    </div>
  );
};

export default LanguageSelectorPC;
