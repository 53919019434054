import _ from 'lodash';
import { MAIL_FORM_FORM_IDS } from '../../constants/ActionTypes';

const initialState = {
  mailFormFormId: {},
};

/**
 * Site Main Info Reducer
 * @param {*} state
 * @param {*} action
 */
export default function mailFormFormIds(state = initialState, action = {}) {
  switch (action.type) {
    case MAIL_FORM_FORM_IDS:
      const formIdData = action?.formIdData;
      const contentUid = formIdData?.uid;
      const blockUid = formIdData?.block;
      const formId = formIdData?.formId;
      let mailFormFormId = state.mailFormFormId;
      if (!mailFormFormId[contentUid]) {
        mailFormFormId[contentUid] = {};
      }
      if (contentUid !== 'add') {
        mailFormFormId['add'] = {};
      }
      if (!mailFormFormId[contentUid][blockUid]) {
        mailFormFormId[contentUid][blockUid] = '';
      }
      mailFormFormId[contentUid][blockUid] = formId;
      return { ...state, mailFormFormId };
    // if (formIdData.uid) {
    // if (Object.keys(state.mailFormFormId).length) {
    // state.mailFormFormId[formIdData.uid][formIdData.block] =
    // formIdData.formId;
    // } else {
    // state.mailFormFormId = {
    // [formIdData.uid]: { [formIdData.block]: formIdData.formId },
    // };
    // }
    // return {
    // ...state,
    // mailFormFormId: state.mailFormFormId,
    // };
    // } else {
    // return {
    // ...state,
    // };
    // }
    default:
      return state;
  }
}
