/**
 * Search widget component.
 *
 * TODO: こちらはもう使わない想定
 *
 * @module components/theme/SearchWidget/SearchWidget
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input } from 'semantic-ui-react';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { Icon, SearchWidgetWordList } from '../../../../components/index';
import { StyleConfig } from '../../../../helpers/GlobalStyle/GlobalStyle';
import { connect } from 'react-redux';
import { getSearchBasic } from '../../../../actions';
import './SearchWidget.css';
import _ from 'lodash';
import qs from 'query-string';
import { searchContent, resetSearchContent } from '../../../../actions';
import { toSearchOptions } from '../Search/Search';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
});

/**
 * SearchWidget component class.
 * @class SearchWidget
 * @extends Component
 */
class SearchWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onChangeText = this.onChangeText.bind(this);
    this.onChangeSection = this.onChangeSection.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      text: '',
      section: false,
    };
  }

  componentDidMount() {
    this.setState({
      text: qs.parse(this.props.location.search)?.SearchableText ?? '',
    });
    this.props.getSearchBasic();
  }

  /**
   * On change text
   * @method onChangeText
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onChangeText(event, { value }) {
    this.setState({
      text: value ?? '',
    });
  }

  /**
   * On change section
   * @method onChangeSection
   * @param {object} event Event object.
   * @param {bool} checked Section checked.
   * @returns {undefined}
   */
  onChangeSection(event, { checked }) {
    this.setState({
      section: checked,
    });
  }

  /**
   * Submit handler
   * @method onSubmit
   * @param {event} event Event object.
   * @returns {undefined}
   */
  onSubmit(event) {
    const section = this.state.section ? `&path=${this.props.pathname}` : '';
    this.props.resetSearchContent();
    this.doSearch(
      this.state.text ?? '',
      this.props.subject,
      this.props.path,
      section,
    );
    event.preventDefault();
  }

  /**
   * Search based on the given searchableText, subject and path.
   * @method doSearch
   * @param {string} searchableText The searchable text string
   * @param {string} subject The subject (tag)
   * @param {string} path The path to restrict the search to
   * @returns {undefined}
   */
  doSearch = (searchableText, subject, path, section) => {
    this.props.history.push(
      `/search?SearchableText=${searchableText ?? ''}${section ?? ''}`,
    );
    this.props.searchContent(
      '',
      toSearchOptions(searchableText, subject, path),
    );
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div className="SearchWidget">
        <h2>{this.props.searchBasic?.title || ''}</h2>
        <div className="search-form">
          <Form action="/search" onSubmit={this.onSubmit}>
            <Form.Field className="searchbox">
              <Input
                aria-label={this.props.intl.formatMessage(messages.search)}
                onChange={this.onChangeText}
                name="SearchableText"
                value={this.state.text}
                transparent
                placeholder={this.props.searchBasic?.searchBoxPlaceholder || ''}
                title={this.props.intl.formatMessage(messages.search)}
                className="search"
              />
              <button
                aria-label={this.props.intl.formatMessage(messages.search)}
              >
                <Icon
                  icon="search"
                  size={StyleConfig.menuIconSize}
                  color={StyleConfig.iconColorLight}
                ></Icon>
              </button>
            </Form.Field>
          </Form>
        </div>

        <SearchWidgetWordList
          title={this.props.searchBasic?.searchWordsTitle || ''}
          data={this.props.searchBasic?.popularSearchWords}
          doSearch={(text) => {
            this.doSearch(text ?? '', this.props.subject, this.props.path);
          }}
        ></SearchWidgetWordList>
      </div>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state, props) => ({
      searchBasic: state?.searchBasic?.result?.data?.attributes,
      searchableText: qs.parse(props.location.search).SearchableText,
      subject: qs.parse(props.location.search).Subject,
      path: qs.parse(props.location.search).path,
    }),
    (dispatch, props) => {
      return {
        getSearchBasic: () => dispatch(getSearchBasic(props.locale)),
        searchContent: (url, options) => {
          dispatch(searchContent(url, options));
        },
        resetSearchContent: () => {
          dispatch(resetSearchContent());
        },
      };
    },
  ),
)(SearchWidget);
