import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Segment } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import Select from 'react-select';
import { TextWidget } from '@plone/volto/components';

import {
  Option,
  DropdownIndicator,
  selectTheme,
  customSelectStyles,
} from '@plone/volto/components/manage/Widgets/SelectStyling';

// コンテンツタイプ選択プルダウンのVocabulary
const options = [
  { value: '', label: '' },
  { value: 'ResOUResearch', label: 'Research type' },
  { value: 'ResOUStory', label: 'Story type' },
];

// const initQuery = () => [
// {
//   i: 'portal_type',
//   o: 'plone.app.querystring.operation.selection.any',
//   v: [],
// },
// {
//   i: 'path',
//   o: 'plone.app.querystring.operation.string.absolutePath',
//   v: '',
// },
// ];
const initQuery = { portal_type: [], path: '' };

const selectedStruct = () => ({
  type: {
    value: '',
    label: '',
  },
  path: '',
  number: 16,
  showmore: '',
});

const messages = defineMessages({
  absolutePath: {
    id: 'Absolute path',
    defaultMessage: 'Absolute path',
  },
  showSortingWidget: {
    id: 'Show sorting widget',
    defaultMessage: 'Show sorting widget',
  },
  showNumber: {
    id: 'Show number',
    defaultMessage: 'Show number',
  },
  initialNumber: {
    id: 'Initial number',
    defaultMessage: 'Initial number',
  },
  showMoreBehavior: {
    id: 'Behavior of show morecontent',
    defaultMessage: 'Behavior of show more content',
  },
  scroll: {
    id: 'Scroll',
    defaultMessage: 'Scroll',
  },
});

const ContentSidebar = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  intl,
}) => {
  const initSelectedType = (data.selected && data.selected.type) || '';
  const initSelectedPath = (data.selected && data.selected.path) || '';
  // const initShowSortingWidget = data.showSortingWidget || false;
  // const initShowNumber = data.showNumber || false;
  // const initInitialNumber = data.initialNumber || 16;
  const initShowMoreBehavior = data.showMoreBehavior || 'scroll';

  const [selectedType, setSelectedType] = useState(initSelectedType);
  const [selectedPath, setSelectedPath] = useState(initSelectedPath);
  // const [showSortingWidget, setShowSortingWidget] = useState(
  //   initShowSortingWidget,
  // );
  // const [showNumber, setShowNumber] = useState(initShowNumber);
  // const [initialNumber, setInitialNumber] = useState(initInitialNumber);
  const [showMoreBehavior, setShowMoreBehavior] = useState(
    initShowMoreBehavior,
  );

  // componentDidMount
  useEffect(() => {
    // Set init data
    if (!data.query) {
      onChangeBlock(
        block,
        {
          ...data,
          // query: initQuery(),
          query: initQuery,
          selected: selectedStruct(),
          block, // Viewで検索結果を取り出すのに必要だがデフォルトで引数にないためblockに追加している(ListingBlockの実装を参照)
        },
        [],
      );
    }
  });

  return (
    <Segment className="form sidebar-restricted-listing-data">
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Listing" defaultMessage="Listing" />
        </h2>
      </header>
      <Form.Field style={{ flex: '1 0 auto' }}>
        <Select
          id="select-listingblock-type"
          name="select-listingblock-type"
          className="react-select-container"
          classNamePrefix="react-select"
          required={true}
          options={options} // TODO: Intl化
          // label: intl.formatMessage(messages.NoSelection),
          styles={customSelectStyles}
          theme={selectTheme}
          components={{ DropdownIndicator, Option }}
          onChange={(value, action) => {
            // let query0 = data.query['portal_type'];
            // query0 = [value.value];
            const selected = data.selected;
            onChangeBlock(block, {
              ...data,
              // query: [query0, data.query['path']],
              query: {
                ...data.query,
                portal_type: [value.value],
              },
              selected: {
                ...selected,
                type: value,
              },
            });
            setSelectedType(value);
          }}
          value={selectedType || ''}
        />
      </Form.Field>
      <TextWidget
        id="path"
        title="Absolute Path"
        required={false}
        value={selectedPath}
        onChange={(name, value) => {
          const selected = data.selected;
          // let query1 = data.query['path'];
          // query1 = value;
          onChangeBlock(block, {
            ...data,
            query: {
              ...data.query,
              path: value,
            },
            selected: {
              ...selected,
              path: value,
            },
          });
          setSelectedPath(value);
        }}
      />
      {/* TODO: Int バリデータ付加 */}
      <TextWidget
        id="initial-number"
        title={intl.formatMessage(messages.initialNumber)}
        value={data.selected?.number}
        onChange={(name, value) => {
          const selected = data.selected;
          onChangeBlock(block, {
            ...data,
            b_size: Number(value),
            selected: {
              ...selected,
              number: value,
            },
          });
          // setInitialNumber(value);
        }}
      />
      {/* 将来の拡張用 */}
      <Form.Field style={{ flex: '1 0 auto' }}>
        <Select
          id="select-show-more-behavior"
          name="select-show-more-behavior"
          className="react-select-container"
          classNamePrefix="react-select"
          options={[
            {
              value: 'scroll',
              label: intl.formatMessage(messages.scroll),
            },
          ]}
          label={intl.formatMessage(messages.showMoreBehavior)}
          styles={customSelectStyles}
          theme={selectTheme}
          components={{ DropdownIndicator, Option }}
          onChange={(value, action) => {
            const selected = data.selected;
            onChangeBlock(block, {
              ...data,
              // showMoreBehavior: value.value,
              selected: {
                ...selected,
                showmore: value.value,
              },
            });
            setShowMoreBehavior(value.value);
          }}
          value={showMoreBehavior || 'scroll'}
        />
      </Form.Field>
    </Segment>
  );
};

ContentSidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default injectIntl(ContentSidebar);
