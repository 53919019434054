import React from 'react';
import _ from 'lodash';
import './SearchContent.css';
import { Link } from 'react-router-dom';
import { ExternalLink } from '../../../index';
import { flattenToAppURL } from '@plone/volto/helpers';

// TODO: 落ち着くまではデバッグモードを保持しておく
const isDebug = false;

const SearchContent = (props) => {
  return (
    <div className={isDebug ? 'SearchContent debug' : 'SearchContent'}>
      <section id="content-core" className="content-core">
        {_.map(props.items, (item) => {
          const url = item['@id'];
          const redirect = item?.redirect;
          return (
            <article className="item tileItem" key={item['@id']}>
              <Link
                to={{ pathname: redirect ? redirect : flattenToAppURL(url) }}
                target={redirect ? '_blank' : ''}
                className="summary url"
                title={item['@type']}
              >
                <div className="item-wrapper">
                  <div className="item-thumbnail">
                    {/* TODO: あとで画像を差し替え */}
                    <img src={item.image} alt="" />
                    {/* <img
                          src="/assets/images/feature_image_001.png"
                          alt=""
                        /> */}
                  </div>
                  <div className="item-content">
                    <h3 className="tileHeadline">{item.title}</h3>
                  </div>
                </div>
                {/* もともとSearchにあったコード */}
                {/* {item.description && (
                    <div className="tileBody">
                      <span className="description">{item.description}</span>
                    </div>
                  )} */}
                {/* <div className="tileFooter">
                    <Link to={item['@id']}>
                      <FormattedMessage
                        id="Read More…"
                        defaultMessage="Read More…"
                      />
                    </Link>
                  </div> */}
                <div className="visualClear" />
              </Link>
              <ExternalLink link={url} redirect={redirect} />
              <div className="announceDate">{item.date}</div>
            </article>
          );
        })}
      </section>
    </div>
  );
};

export default SearchContent;
