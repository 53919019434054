/**
 * View toc block.
 * @module components/manage/Blocks/ToC/View
 */

import { defineMessages } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { map, filter } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
// import AnchorLink from 'react-anchor-link-smooth-scroll';
import AnchorLinkForResearch from '../../../../../components/theme/Element/AnchorLink/AnchorLinkForResearch';
import './View.css';

import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';

defineMessages({
  title: {
    id: 'Table of Contents',
    defaultMessage: 'Table of Contents',
  },
});

/**
 * View toc block class.
 * @class View
 * @extends Component
 */
const View = ({ properties }) => {
  const blocksFieldname = getBlocksFieldname(properties);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(properties);

  return (
    <div className="TableContents">
      <h2>
        <FormattedMessage
          id="Table of Contents"
          defaultMessage="Table of Contents"
        />
      </h2>
      <ol>
        {map(
          filter(
            map(
              properties[blocksLayoutFieldname].items,
              (id) => properties[blocksFieldname][id],
            ),
            (block) =>
              (block['@type'] === 'text' &&
                block.text?.blocks[0].type.indexOf('header-') === 0) ||
              block['@type'] === 'header3' ||
              block['@type'] === 'header_text',
          ),
          (block) =>
            block['@type'] === 'text' ? (
              <li
                key={block.text.blocks[0].key}
                className={block.text.blocks[0].type}
              >
                <AnchorLinkForResearch href={`#${block.text.blocks[0].key}`}>
                  {block.text.blocks[0].text}
                </AnchorLinkForResearch>
              </li>
            ) : (
              <li key={block.key} className={`${block['@type']} header-three`}>
                <AnchorLinkForResearch href={`#${block.key}`}>
                  {block.header3 || block.title}
                </AnchorLinkForResearch>
              </li>
            ),
        )}
      </ol>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(View);
