import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeVisibleContentFull } from '../../../../actions/index';
import { Timer } from '../../../../helpers/index';

/**
 * 研究情報専用AnchorLink
 */
class AnchorLinkComponent extends Component {
  constructor(props) {
    super(props);
    this.smoothScroll = this.smoothScroll.bind(this);
  }

  componentDidMount() {
    require('smoothscroll-polyfill').polyfill();
  }

  async smoothScroll(e) {
    // 非同期のイベント取得に必要
    e.persist();
    e.preventDefault();

    if (!this.props.researchViewContentIsVisible) {
      this.props.changeVisibleContentFull(true);
      // DOMの生成を待つ必要あり
      await Timer.sleep(500);
    }

    // Scroll メイン処理
    let offset = () => 0;
    if (typeof this.props.offset !== 'undefined') {
      if (
        !!(
          this.props.offset &&
          this.props.offset.constructor &&
          this.props.offset.apply
        )
      ) {
        offset = this.props.offset;
      } else {
        offset = () => parseInt(this.props.offset);
      }
    }
    const id = e.target.getAttribute('href').slice(1);
    const $anchor = document.getElementById(id);
    const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset;
    window.scroll({
      top: offsetTop - offset(),
      behavior: 'smooth',
    });
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }
  render() {
    const { offset, ...rest } = this.props;
    return <a {...rest} onClick={this.smoothScroll} />;
  }
}

const AnchorLinkForResearch = connect(
  (state, props) => {
    return {
      // 研究情報の詳細表示非表示フラグ
      researchViewContentIsVisible: state?.researchView?.visible ?? false,
    };
  },
  (dispatch, props) => {
    return {
      changeVisibleContentFull: (visible) => {
        dispatch(changeVisibleContentFull(visible));
      },
    };
  },
)(AnchorLinkComponent);

export default AnchorLinkForResearch;
