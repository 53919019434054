/**
 * Edit list item component part of the List block.
 * @module components/manage/Blocks/List/ListItem
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Editor from 'draft-js-plugins-editor';
import { convertFromRaw, EditorState } from 'draft-js';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import { settings } from '~/config';
import {
  createToolbarPositionReviser,
  getToolbarDOM,
  toolbarReviseConfig,
} from '~/helpers';

/**
 * Edit list item text class.
 * @class ListItem
 * @extends Component
 */
class ListItem extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    block: PropTypes.string.isRequired,
    value: PropTypes.object,
    index: PropTypes.number,
    onSelectListItem: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    isListBlockSelected: PropTypes.bool,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    detached: false,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Cell
   */
  constructor(props) {
    super(props);

    if (!__SERVER__) {
      let editorState;
      editorState = EditorState.createWithContent(convertFromRaw(props.value));

      const inlineToolbarPlugin = createInlineToolbarPlugin({
        structure: settings.richTextEditorInlineToolbarButtons,
      });

      this.state = {
        editorState,
        inlineToolbarPlugin,
      };
    }

    this.onChange = this.onChange.bind(this);
  }

  /**
   * Component did mount lifecycle method
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (this.node) {
      const onFocus = this.node.editor._onFocus;
      this.node.editor._onFocus = (event) => {
        onFocus(event);
        this.props.onSelectListItem(this.props.index);
      };
    }
    this.toolbarReviser = createToolbarPositionReviser(this.props.block);
    this.toolBarDOM = getToolbarDOM(this.props.block);
    if (this.toolbarReviser && this.toolBarDOM) {
      this.toolbarReviser.observe(this.toolBarDOM, toolbarReviseConfig);
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.isListBlockSelected !== this.props.isListBlockSelected &&
      this.props.index === 0
    ) {
      this.node.focus();
      // this.setState({
      //   editorState: EditorState.moveFocusToEnd(this.state.editorState),
      // });
    }
  }

  /**
   * Component will unmount lifecycle method
   * @method componentWillUnmount
   * @returns {undefined}
   */
  componentWillUnmount() {
    if (this.toolbarReviser) {
      this.toolbarReviser.disconnect();
    }
  }

  /**
   * Change handler
   * @method onChange
   * @param {object} editorState Editor state.
   * @returns {undefined}
   */
  onChange(editorState) {
    this.setState({ editorState }, () => {
      this.props.onChange(this.props.index, editorState);
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    const { InlineToolbar } = this.state.inlineToolbarPlugin;
    return (
      <div>
        <Editor
          onChange={this.onChange}
          editorState={this.state.editorState}
          plugins={[
            this.state.inlineToolbarPlugin,
            ...settings.richTextEditorPlugins,
          ]}
          blockRenderMap={settings.extendedBlockRenderMap}
          blockStyleFn={settings.blockStyleFn}
          ref={(node) => {
            this.node = node;
          }}
          customStyleMap={settings.customStyleMap}
        />
        <InlineToolbar />
      </div>
    );
  }
}

export default ListItem;
