import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
// import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { useMediaQuery } from 'react-responsive';
import {
  MediaQueryConfig,
  StyleConfig,
} from '../../../../helpers/GlobalStyle/GlobalStyle';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { zeroSuppledDate } from '../../../theme/View/Common';
import { ExternalLink } from '../../../index';
import { Link } from 'react-router-dom';
import './ResearchContainer.css';

const getColor = (colors, token) => {
  var color = colors[token];
  return color ? color : StyleConfig.iconColorLightUniversity;
};
const getCategoryWord = (vocab, locale, token) => {
  return vocab[token]?.[locale] || token;
};

const ResearchContainer = ({
  content,
  isEditMode,
  category_color,
  category_vocabulary,
  locale,
}) => {
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  const colors = category_color;
  const token = content?.research_highlight?.token
    ? content.research_highlight.token
    : content.research_highlight;
  const subjects = content.Subject || content.subjects;
  const url = flattenToAppURL(content['@id'] ?? '');
  const redirect = content.link ?? '';
  return (
    <div className="ResearchContainer">
      <Link
        className="researchContainerHeader"
        to={{ pathname: redirect ? redirect : flattenToAppURL(url) }}
        target={redirect ? '_blank' : ''}
        condition={!isEditMode}
      >
        {content.title_image_sp ? (
          <img
            src={flattenToAppURL(
              content?.title_image_sp?.scales?.storyz_thumb?.download ?? '',
            )}
            alt={content.title}
          />
        ) : content.title_image_sp_size ? (
          <img
            src={flattenToAppURL(
              `${content.physical_path}/@@images/title_image_sp/storyz_thumb`,
            )}
            alt={content.title}
          />
        ) : (
          <img
            src={flattenToAppURL('/assets/images/nowprinting_580_280.jpg')}
            alt={content.title}
          />
        )}
      </Link>

      <div className="researchContainerBody">
        {!isEditMode && (
          <Link
            to={{ pathname: redirect ? redirect : flattenToAppURL(url) }}
            target={redirect ? '_blank' : ''}
          >
            <h3 className="title">{content.title}</h3>
          </Link>
        )}
        <div className="information" colors={category_color}>
          <span className="announcemenDdate">
            {zeroSuppledDate(locale, content.announcement_date)}
          </span>
          {token && (
            <span className="researchHighlight">
              <span
                className="circle"
                style={{ color: getColor(colors, token) }}
              >
                ●
              </span>
              {getCategoryWord(category_vocabulary, locale, token)}
            </span>
          )}
        </div>
        <hr
          className="hr"
          style={{
            color: getColor(colors, token),
            backgroundColor: getColor(colors, token),
          }}
        />
        <p className="subjects">
          {subjects &&
            subjects.map((subject, index) => (
              <Link
                to={flattenToAppURL(`/search-tag?Subject=${subject}`)}
                key={index}
                className={`ui label ${isSP ? 'sp' : 'pc'}`}
              >
                {subject}
              </Link>
            ))}
        </p>
        <ExternalLink link={url} redirect={redirect} />
      </div>
    </div>
  );
};

ResearchContainer.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired, // TODO: 詳細追記
  isEditMode: PropTypes.bool,
};

const ResearchContainerWrapper = connect((state, props) => {
  return {
    locale: state?.content?.data?.language?.token ?? 'ja',
    category_color:
      state?.siteMainInfo?.result?.data?.attributes?.common?.category_colors,
    category_vocabulary:
      state?.siteMainInfo?.result?.data?.attributes?.common?.research_highlight,
  };
})(ResearchContainer);

export default injectIntl(ResearchContainerWrapper, {});
