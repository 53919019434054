/**
 * The generic slider component
 * 汎用スライダーコンポーネント
 * @module components/theme/Site/Slider/GenericList
 */
import React, { Component } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose';
import _ from 'lodash';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

import {
  MediaQueryConfig,
  StyleConfig,
} from '../../../../helpers/GlobalStyle/GlobalStyle';
import { ExternalLink, Icon } from '../../../index';
import { Link } from 'react-router-dom';
import { ExtraSectionHeader } from '../../../index';
import './GenericList.css';

/**
 * スライダー : 次矢印
 */
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className="arrowWrapperNext">
      <button className="arrowButton" onClick={onClick}>
        <Icon icon="angle right" size={StyleConfig.carouselIconSize} />
      </button>
    </div>
  );
};

/**
 * スライダー : 前矢印
 * @param {*} props
 */
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className="arrowWrapperPrev">
      <button className="arrowButton" onClick={onClick}>
        <Icon icon="angle left" size={StyleConfig.carouselIconSize} />
      </button>
    </div>
  );
};

/**
 * Item of the slider component
 * スライダーアイテム
 * @param {Object} props component properties
 * @returns Markup of the component
 */
const CarouselItem = (props) => {
  const isPCAndTAB = useMediaQuery({
    query: MediaQueryConfig.PC_AND_TABLET,
  });
  const url = props.item.url;
  const redirect = props.item.redirect;
  return (
    <Link
      className="carouselItemWrapper"
      to={{ pathname: redirect ? redirect : flattenToAppURL(url) }}
      target={redirect ? '_blank' : ''}
    >
      <img
        className="img"
        alt=""
        src={flattenToAppURL(props.item?.image ?? '')}
      />
      {isPCAndTAB ? <h3 className="title">{props.item.title}</h3> : null}
      {!isPCAndTAB ? (
        <h3>
          <LinesEllipsisLoose
            text={props.item.title}
            maxLine="5"
            ellipsis="..."
            lineHeight="20"
            className="customLinesEllipsis"
          />
        </h3>
      ) : null}
      <ExternalLink link={url} redirect={redirect} />
    </Link>
  );
};

/**
 * Generic list slider component itself.
 * 標準のスライダー
 * @class Edit
 * @extends Component
 */
class DefaultSlider extends Component {
  constructor(props) {
    super(props);
    this.apiPath = flattenToAppURL(this.props.baseApiUrl ?? '');
    this.state = {
      locale: '',
    };
  }

  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    /** 表示用データ
     * TODO: あとで正確な型を決めてもいい
     */
    title: PropTypes.string,
    description: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    token: PropTypes.string,
  };
  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    title: '',
    description: '',
    data: [],
    token: null,
  };
  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    // this.props.loadContent(this.props.locale);
    this.props.loadContent(this.apiPath);
  }
  /**
   * Component did update
   * @method componentDidUpdate
   * @returns {undefined}
   */
  componentDidUpdate() {
    if (this.props.locale !== this.state.locale) {
      this.setState({ locale: this.props.locale });
      // this.props.loadContent(this.props.locale);
      this.props.loadContent(this.apiPath);
    }
  }

  render() {
    const settings = {
      accessibility: true,
      dots: false,
      // infinite: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      // centerMode: true,
      centerMode: false,
      arrow: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      className: this.props.token
        ? 'customSlider customSliderLogined'
        : 'customSlider',
    };
    // サイズが1のときにおかしな挙動になるため
    const data = this.props.data;
    //   _.size(this.props.data) === 1
    //     ? [this.props.data[0], this.props.data[0]]
    //     : this.props.data;
    return (
      <>
        {data.length ? (
          <div className="GenericList DefaultSlider">
            <ExtraSectionHeader
              title={this.props.title}
              description={this.props.description}
            ></ExtraSectionHeader>
            <Slider {...settings}>
              {_.map(data, (item) => {
                return <CarouselItem item={item} key={item.title} />;
              })}
            </Slider>
          </div>
        ) : null}
      </>
    );
  }
}

export default DefaultSlider;
