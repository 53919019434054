import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  changeCustomDimmer,
  displayTermContent,
} from '../../../../actions/index';
import './CustomDimmer.css';

/**
 * CustomDimmer
 */
const CustomDimmer = ({ visible, closeDimmer }) => (
  <div
    id="term-content-dimmer"
    className={
      visible
        ? 'CustomDimmer term-content-dimmer visible'
        : 'CustomDimmer term-content-dimmer'
    }
    onClick={() => {
      closeDimmer();
    }}
    onKeyDown={() => {
      closeDimmer();
    }}
    role="button"
    tabIndex={0}
  ></div>
);

CustomDimmer.propTypes = {
  visible: PropTypes.bool,
  closeDimmer: PropTypes.func,
};

/**
 * コンテナ
 */
const CustomDimmerContainer = connect(
  (state, props) => {
    return {
      visible: state.customDimmer?.visible,
    };
  },
  (dispatch, props) => {
    return {
      closeDimmer: () => {
        dispatch(displayTermContent(false));
        dispatch(changeCustomDimmer(false));
      },
    };
  },
)(CustomDimmer);

export default CustomDimmerContainer;
