/**
 * Research view component.
 * @module components/theme/View/ResearchView
 */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { map } from 'lodash';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';
import { blocks } from '~/config';
import redraft from 'redraft';
import { settings } from '~/config';
import { useMediaQuery } from 'react-responsive';
import {
  MediaQueryConfig,
  StyleConfig,
} from '../../../helpers/GlobalStyle/GlobalStyle';
import { connect } from 'react-redux';
import _ from 'lodash';
import './ResearchView.css';
import { BodyClass } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  ArticleInquiryButton,
  TeacherCommentButton,
} from '../../../components/index';
import {
  ItemListRelatedtoDepartment,
  ItemListRelatedtoTeacher,
} from '../../../components/index';
import { BrowsingHistorySaver, Icon } from '../../../components/index';

import { Tags } from '@plone/volto/components';
import {
  updateNowContent,
  changeCustomDimmer,
  displayTermContent,
  changeVisibleContentFull,
} from '../../../actions/index';
import { HelmetTitle, SNSShare, zeroSuppledDate } from './Common';

// ------------------------------- Term関連設定

const TERM_SOURCE_MARGIN_TOP = 20;
const TERM_CONTENT_CORRECTED_TOP = 42;
// const TERM_CONTENT_CORRECTED_LEFT = 100;
// const TERM_ID_DIMMER = 'term-content-dimmer';
// const TERM_ID_CONTENT = 'term-content';
// const TERM_VISIBLE_CLASS = 'visible';
// const TERM_CONTENT_TITLE = 'term-content-title';
// const TERM_CONTENT_DESCRIPTION = 'term-content-description';

/**
 * ハッシュのうち#を除去
 */
function stripHash(str) {
  if (str) {
    return str.replace(/\#/g, '');
  }
  return str;
}

/**
 * ブロックエリアを3分割する
 * var blocks = ['a', 'b', 'c']
 * getEntry(blocks, 4, 2)
 */
function getEntry(blocks, startCount, endCount) {
  const blockCount = blocks?.length ?? 0;

  // 先頭からのブロック群を取得
  const firstBlocks = _.slice(blocks, 0, startCount);
  // 途中のブロック群を取得
  const centerBlocks = _.slice(blocks, startCount, blockCount - endCount);
  // 最後のブロック群を取得
  const finalBlocks = _.slice(
    blocks,
    // blockCount - (blockCount - startCount > 0 ? endCount : 0),
    blockCount - (blockCount - firstBlocks.length - centerBlocks.length),
    blockCount,
  );
  return {
    firstBlocks,
    centerBlocks,
    finalBlocks,
  };
}

// -------------------------------

const getColor = (colors, token) => {
  return colors[token];
};

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  readMore: {
    id: 'Read More',
    defaultMessage: 'More...',
  },
  close: {
    id: 'Close',
    defaultMessage: 'Close...',
  },
  technicalGrossary: {
    id: 'Technical Glossary',
    defaultMessage: 'Technical Glossary',
  },
  glossary: {
    id: 'Glossary',
    defaultMessage: 'Glossary',
  },
});

/**
 * 詳細を読むボタン
 * @param {*} visible
 * @param {*} visibleContent
 * @param {*} onClickButton
 */
const DisplayMoreButton = ({
  visible,
  visibleContent,
  onClickButton,
  intl,
}) => {
  return (
    <div className="DisplayMoreButton">
      {visible ? (
        visibleContent ? (
          <button onClick={onClickButton}>
            <Icon
              icon="angle up"
              size={StyleConfig.menuIconSize}
              color={StyleConfig.iconColorLightUniversity}
            ></Icon>{' '}
            {intl.formatMessage(messages.close)}
          </button>
        ) : (
          <button onClick={onClickButton}>
            <Icon
              icon="angle down"
              size={StyleConfig.menuIconSize}
              color={StyleConfig.iconColorLightUniversity}
            ></Icon>{' '}
            {intl.formatMessage(messages.readMore)}
          </button>
        )
      ) : null}
    </div>
  );
};

const canDisplayCenterContent = (isSP, visibleContent) => {
  if (isSP) {
    return visibleContent ? 'center-blocks' : 'center-blocks visually-hidden';
  } else {
    return 'center-blocks';
  }
};

const ResearchDefaultBody = injectIntl(
  ({
    content,
    intl,
    research_block_count,
    isSP,
    visibleContent,
    setVisibleContent,
  }) => {
    // 先頭からの表示ブロック数
    const FIRST_BLOCK_COUNT = research_block_count.first ?? 0;
    // 最後からの表示ブロック数
    const FINAL_BLOCK_COUNT = research_block_count.final ?? 0;

    const blocksFieldname = getBlocksFieldname(content);
    const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

    // ブロックの配列を取得
    const displayBlocks = content[blocksLayoutFieldname]?.items || [];
    // ブロック数
    const displayBlockCount =
      content[blocksLayoutFieldname]?.items?.length ?? 0;
    const {
      // 先頭からのブロック群を取得
      firstBlocks,
      // 途中のブロック群を取得
      centerBlocks,
      // 最後のブロック群を取得
      finalBlocks,
    } = getEntry(displayBlocks, FIRST_BLOCK_COUNT, FINAL_BLOCK_COUNT);

    // const [visibleContent, setVisibleContent] = useState(false);
    const [centerContentClass, setCenterContentClass] = useState(
      'center-blocks',
    );

    /**
     * 副作用まわりの処理
     */
    useEffect(() => {
      setCenterContentClass(canDisplayCenterContent(isSP, visibleContent));
    }, [isSP, visibleContent, centerContentClass]);

    return (
      <div>
        {/* 先頭からのブロック群 */}
        {map(firstBlocks, (block) => {
          const blockType =
            blocks.blocksConfig[content[blocksFieldname]?.[block]?.['@type']];
          const isTitle = blockType?.['id'] === 'title';
          if (isTitle) {
            return null;
          }
          const Block = blockType?.['view'] || null;
          return Block !== null ? (
            <Block
              key={block}
              id={block}
              properties={content}
              data={content[blocksFieldname][block]}
            />
          ) : (
            <div key={block}>
              {intl.formatMessage(messages.unknownBlock, {
                block: content[blocksFieldname]?.[block]?.['@type'],
              })}
            </div>
          );
        })}

        {/* 途中のブロック群 */}
        <div className={centerContentClass}>
          {map(centerBlocks, (block) => {
            const blockType =
              blocks.blocksConfig[content[blocksFieldname]?.[block]?.['@type']];
            const isTitle = blockType?.['id'] === 'title';
            if (isTitle) {
              return null;
            }
            const Block = blockType?.['view'] || null;
            return Block !== null ? (
              <Block
                key={block}
                id={block}
                properties={content}
                data={content[blocksFieldname][block]}
              />
            ) : (
              <div key={block}>
                {intl.formatMessage(messages.unknownBlock, {
                  block: content[blocksFieldname]?.[block]?.['@type'],
                })}
              </div>
            );
          })}
        </div>

        {/* 開閉ボタン */}
        <DisplayMoreButton
          visible={displayBlockCount > FIRST_BLOCK_COUNT + FINAL_BLOCK_COUNT}
          visibleContent={visibleContent}
          onClickButton={(e) => {
            setVisibleContent(!visibleContent);
          }}
          intl={intl}
        ></DisplayMoreButton>

        {/* 最後のブロック群 */}
        {map(finalBlocks, (block) => {
          const blockType =
            blocks.blocksConfig[content[blocksFieldname]?.[block]?.['@type']];
          const isTitle = blockType?.['id'] === 'title';
          if (isTitle) {
            return null;
          }
          const Block = blockType?.['view'] || null;
          return Block !== null ? (
            <Block
              key={block}
              id={block}
              properties={content}
              data={content[blocksFieldname][block]}
            />
          ) : (
            <div key={block}>
              {intl.formatMessage(messages.unknownBlock, {
                block: content[blocksFieldname]?.[block]?.['@type'],
              })}
            </div>
          );
        })}
      </div>
    );
  },
);

/**
 * Research view component class.
 * @function ResearchView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const ResearchView = ({
  content,
  intl,
  category_color,
  // 先生のコメントをみるボタンを開く関数
  openTeacherComment,
  visibleTeacherComment,
  updateNowContent,
  displayTermContent,
  updateTerms,
  research_block_count,
  locale,
  ...props
}) => {
  const words = useRef(null);

  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });

  function addPopup(termEventHandler) {
    const a_list = document.querySelectorAll('.researchBody a');
    const filtered_a_list = _.filter(a_list, (a) => {
      return a.hash.startsWith('#term-');
    });
    // Termだけにイベントを設定
    _.map(filtered_a_list, (item) => {
      const id = stripHash(item.hash);
      // const target = document.getElementById(id);
      item.addEventListener('click', termEventHandler, false);
    });
    return () => {
      _.map(filtered_a_list, (item) => {
        item.removeEventListener('click', termEventHandler, false);
      });
    };
  }

  /**
   * 用語がクリックされたら実行されるハンドラ
   * @param {func} updateNowContent
   */
  function termEventHandler(e) {
    // 用語表示ウインドウの取得
    const term_content = document.getElementById('term-content');
    // 縦からの距離全体版
    const yOffset = window.pageYOffset + e.target.getBoundingClientRect().top;
    term_content.style.top = `${yOffset + TERM_CONTENT_CORRECTED_TOP}px`;
    if (window.innerWidth < 1140) {
      term_content.style.left = '50%';
      term_content.style.marginRight = '-50%';
      term_content.style.transform = 'translate(-50%, 0)';
    } else {
      const xOffset = e.target.getBoundingClientRect().left;
      term_content.style.left = `${xOffset}px`;
      term_content.style.marginRight = '0';
      term_content.style.transform = 'none';
    }

    // }
    const now_id = stripHash(e.target.hash);
    const term_source_title = document.getElementById(now_id)?.innerText;
    const term_source_description = document.getElementById(
      `${now_id}-description`,
    )?.innerText;
    e.preventDefault(false);
    updateNowContent(now_id, term_source_title, term_source_description);
    displayTermContent();
  }

  function resetTerm() {
    const a_list = document.querySelectorAll('.researchBody a');
    const filtered_a_list = _.filter(a_list, (a) => {
      return a.hash.startsWith('#term-');
    });

    setTimeout(() => {
      _.map(filtered_a_list, (item) => {
        const id = stripHash(item.hash);
        const target = document.getElementById(id);
        // console.log({
        //   item: item.offsetTop,
        //   target: target.offsetTop,
        // });

        if (target) {
          target.style.marginTop = `${TERM_SOURCE_MARGIN_TOP}px`;
          const addMarginTop = item.offsetTop - target.offsetTop;
          if (addMarginTop > 0) {
            target.style.marginTop = `${addMarginTop}px`;
          }
          // if (isSP) {
          //   target.style.marginTop = `0`;
          // }
        }
      });
    }, 1000);
  }

  useEffect(() => {
    const returnFunc = addPopup(termEventHandler);

    resetTerm();
    //
    window.addEventListener('resize', resetTerm, false);
    return () => {
      // アンマウント時に実行される
      returnFunc();
      window.removeEventListener('resize', resetTerm, false);
    };
  }, []);

  const colors = category_color;
  const token = content?.research_highlight?.token;
  const hasIndexTerm = () => {
    const index_term_items = content?.term_index?.items;
    // console.log(index_term_items);
    // console.log(index_term_items.length);
    // console.log(index_term_items[0].term);
    // console.log(!(index_term_items.length === 1 && !index_term_items[0].term));
    return (
      index_term_items >= 2 ||
      !(index_term_items.length === 1 && !index_term_items[0].term)
    );
  };

  // ArticleInquiryButtonに渡すProperties
  const url = content?.['@id'] || '';
  const researcher = content?.teacher_name || '';
  let affiliationList = [];
  content?.departments
    ? _.forEach(content.departments, (item) => affiliationList.push(item.title))
    : [];
  const affiliation = affiliationList.join(' ');

  return (
    <div className="ResearchView">
      <HelmetTitle pageTitle={content.title} />
      <BodyClass className="view-research-view-leaf" />
      <div className="researchHeader">
        <div className="researchHeaderInner">
          <img
            className="titleImage"
            alt={content.title}
            src={flattenToAppURL(
              content?.title_image_sp
                ? content?.title_image_sp?.download
                : '/assets/images/nowprinting_580_280.jpg',
            )}
          />
          <div className="researchHeaderBody">
            <h2 className="title">{content.title}</h2>
            {content.subtitle && (
              <h3 className="subtitle">{content.subtitle}</h3>
            )}
            <div
              className="information"
              colors={category_color}
              token={content?.research_highlight?.token}
            >
              <span className="announcementDate">
                {zeroSuppledDate(locale, content.announcement_date)}
              </span>
              {content.research_highlight && content.research_highlight.title && (
                <span className="researchHighlight">
                  <span
                    className="circle"
                    style={{ color: getColor(colors, token) }}
                  >
                    ●
                  </span>
                  {content.research_highlight.title}
                </span>
              )}
            </div>
            {(content?.departments ||
              content?.teacher_position ||
              content?.teacher_name) && (
              <div className="teacherInfo">
                {content?.teacher_division1 && (
                  <span>{content?.teacher_division1}</span>
                )}
                {content?.teacher_position && (
                  <span>{content?.teacher_position}</span>
                )}
                {content?.teacher_name && <span>{content?.teacher_name}</span>}
              </div>
            )}
          </div>
          <div className="visualClear" />
        </div>
      </div>

      <div className="researchBody">
        <div ref={words} className="researchBodyInner">
          <ResearchDefaultBody
            content={content}
            research_block_count={research_block_count}
            isSP={isSP}
            visibleContent={props.researchViewContentIsVisible}
            setVisibleContent={props.changeVisibleContentFull}
            intl={intl}
          />
        </div>
        <div className="researchBodySub">
          {isSP ? null : (
            <TeacherCommentButton
              setVisibleRightBar={() => {
                openTeacherComment();
              }}
              visibleRightBar={visibleTeacherComment}
            ></TeacherCommentButton>
          )}
          {hasIndexTerm() ? (
            <>
              <h3 id="TechnicalGlossary" className="Header3 isPC">
                {intl.formatMessage(messages.technicalGrossary)}
              </h3>
              <dl>
                {map(content?.term_index?.items, (item, index) => {
                  return (
                    <div key={item.key} className="term-wrapper">
                      <dt id={`term-${item.key}`}>{item.term}</dt>
                      <dd id={`term-${item.key}-description`}>
                        {redraft(
                          item.description,
                          settings.ToHTMLRenderers,
                          settings.ToHTMLOptions,
                        )}
                      </dd>
                    </div>
                  );
                })}
              </dl>
            </>
          ) : null}
        </div>
      </div>
      <div className="researchBelowActions">
        <div className="researchAdditionalInfo">
          {content.subjects && content.subjects.length > 0 && (
            <Tags tags={content.subjects} />
          )}
          <ArticleInquiryButton
            url={url}
            researcher={researcher}
            affiliation={affiliation}
          />
          <SNSShare content={content} />
        </div>
        {isSP ? null : (
          <div className="positionBottom">
            <TeacherCommentButton
              setVisibleRightBar={() => {
                openTeacherComment();
              }}
              visibleRightBar={visibleTeacherComment}
            ></TeacherCommentButton>
          </div>
        )}
      </div>
      <ItemListRelatedtoTeacher />
      <ItemListRelatedtoDepartment />
      <BrowsingHistorySaver />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ResearchView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.object,
        image_caption: PropTypes.string,
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
  // 先生のコメントをみるボタンを開く関数
  openTeacherComment: PropTypes.func,
  visibleTeacherComment: PropTypes.bool,
  research_block_count: PropTypes.shape({
    first: PropTypes.number,
    final: PropTypes.number,
  }),
};

const ResearchViewContainer = connect(
  (state, props) => {
    return {
      locale: state?.content?.data?.language?.token ?? 'ja',
      category_color:
        state?.siteMainInfo?.result?.data?.attributes?.common?.category_colors,
      research_block_count: {
        first:
          state?.siteMainInfo?.result?.data?.attributes?.common
            ?.research_block_count?.first ?? 0,
        final:
          state?.siteMainInfo?.result?.data?.attributes?.common
            ?.research_block_count?.final ?? 0,
      },
      // 研究情報の詳細表示非表示フラグ
      researchViewContentIsVisible: state?.researchView?.visible ?? false,
    };
  },
  (dispatch, props) => {
    return {
      updateNowContent: (now_id, title, description) =>
        dispatch(updateNowContent(now_id, title, description)),
      displayTermContent: () => {
        dispatch(displayTermContent(true));
        dispatch(changeCustomDimmer(true));
      },
      changeVisibleContentFull: (visible) => {
        dispatch(changeVisibleContentFull(visible));
      },
    };
  },
)(ResearchView);

export default injectIntl(ResearchViewContainer, {});
