import { concat, map } from 'lodash';
import { settings } from '~/config';
import { GET_RESEARCH_LIST_ON_TOP } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  items: [],
  total: 0,
  loaded: false,
  loading: false,
  batching: {},
  subrequests: {},
};

/**
 * querystringsearch reducer for research articles on language top page view.
 * @function querystring
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function researchListOnTop(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_RESEARCH_LIST_ON_TOP}_PENDING`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  items: [],
                  total: 0,
                  batching: {},
                }),
                error: null,
                loaded: false,
                loading: true,
              },
            },
          }
        : {
            ...state,
            error: null,
            loading: true,
            loaded: false,
          };
    case `${GET_RESEARCH_LIST_ON_TOP}_SUCCESS`:
      const currentItems = action.currentItems || [];
      const result = map(action.result.items, (item) => ({
        ...item,
        '@id': item['@id'].replace(settings.apiPath, ''),
      }));
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: null,
                items: concat(currentItems, result),
                total: action.result.items_total,
                loaded: true,
                loading: false,
                batching: { ...action.result.batching },
              },
            },
          }
        : {
            ...state,
            error: null,
            items: concat(currentItems, result),
            total: action.result.items_total,
            loaded: true,
            loading: false,
            batching: { ...action.result.batching },
          };
    case `${GET_RESEARCH_LIST_ON_TOP}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: action.error,
                items: [],
                total: 0,
                loading: false,
                loaded: false,
                batching: {},
              },
            },
          }
        : {
            ...state,
            error: action.error,
            items: [],
            total: 0,
            loading: false,
            loaded: false,
            batching: {},
          };
    // case RESET_QUERYSTRING_RESULTS:
    //   return action.subrequest
    //     ? {
    //         ...state,
    //         subrequests: omit(state.subrequests, [action.subrequest]),
    //       }
    //     : {
    //         ...state,
    //         error: null,
    //         items: [],
    //         total: 0,
    //         loading: false,
    //         loaded: false,
    //         batching: {},
    //       };
    default:
      return state;
  }
}
