/**
 * SearchTagView view component.
 * @module components/theme/View/OuFolderView
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import _ from 'lodash';
import qs from 'query-string';
import { Container, Button } from 'semantic-ui-react';
import { searchTag } from '../../../actions';
import { HelmetTitle, HorizontalRule } from './Common';
import { Waypoint } from 'react-waypoint';
import { Loader } from '../../../helpers';
import { SearchContent } from '../../../components/';
import { InfiniteScroll } from '../../../components/index';
import { resetSearchTag } from '../../../actions/searchTag/searchTag';

/**
 * OuFolderView view component class.
 * @function SearchTagView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

export const toSearchOptions = (
  // searchableText,
  subject,
  // path,
  b_start,
  // locale,
) => {
  return {
    // ...(searchableText && { SearchableText: searchableText }),
    ...(subject && { Subject: subject }),
    // ...(path && { path: path }),
    ...(!isNaN(b_start) && { b_start: b_start }),
    // ...(locale && { lang: locale }),
  };
};

//const SearchTagView = ({ content }) => { // content or props.content
/**
 *
 * @param {*} items
 * @param {*} subject
 * @param {*} b_start
 * @param {*} b_size
 * @param {*} has_next
 * @param {*} locale
 */
const SearchTagView = (props) => {
  // console.log(
  //   props.items,
  //   props.subject,
  //   props.b_start,
  //   props.b_size,
  //   props.has_next,
  // );
  // const doSearch = (searchableText, subject, path, b_start, locale = 'ja') => {
  const doSearch = (subject, b_start) => {
    props.searchContent('', toSearchOptions(subject, b_start));
  };
  const myRef = React.createRef();
  const [items, setItems] = useState(0);
  useEffect(() => {
    if (props.items !== items) {
      setItems(props.items);
    }
  }, [props.items, items, setItems]);

  return (
    <Container id="page-search" className="Search">
      {/* {console.log(props.has_next)} */}
      <Helmet title="Search Tag" />
      <div className="container">
        <article id="content">
          <div class="SearchWidgetForSearch">
            {props.locale === 'ja' ? (
              <h2>
                <b>{props.subject}</b> に関する研究情報の一覧です
              </h2>
            ) : (
              <h2>
                Below is a list of articles relating to <b>{props.subject}</b>
              </h2>
            )}
          </div>
          {props.subject && (
            <>
              <SearchContent items={items}></SearchContent>
              <InfiniteScroll
                ref={myRef}
                onInit={() => {
                  props.resetContent();
                  doSearch(
                    // props.searchableText,
                    props.subject,
                    // props.path,
                    props.b_start,
                  );
                }}
                onEnter={() => {
                  doSearch(
                    // props.searchableText,
                    props.subject,
                    // props.path,
                    props.b_start + props.b_size,
                  );
                }}
                next_b_start={props.b_start + props.b_size}
                b_size={props.b_size}
                total={props.total}
                has_next={props.has_next}
              ></InfiniteScroll>
            </>
          )}
          {/*<Waypoint
              onEnter={({ previousPosition, currentPosition, event }) => {
                doSearch(
                  props.searchableText,
                  props.subject,
                  props.path,
                  props.b_start + props.b_size,
                );
              }}
              onLeave={e => {
                console.log({ onLeave: e });
              }}
              onPositionChange={e => {
                console.log({ onPositionChange: e });
              }}
              fireOnRapidScroll={true}
            ></Waypoint>

            <div className="loading-button">
              <Button
                onClick={e => {
                  doSearch(
                    props.searchableText,
                    props.subject,
                    props.path,
                    props.b_start + props.b_size,
                  );
                }}
              >
                <Loader visible={props.has_next}></Loader> More...
              </Button>
            </div>*/}
        </article>
      </div>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SearchTagView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        review_state: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }).isRequired,
};

const SearchTagViewContainer = connect(
  (state, props) => {
    return {
      // test: props,
      items: state.searchTag.items,
      // searchableText: qs.parse(props.location.search).SearchableText,
      subject: qs.parse(props.location.search).Subject,
      // path: qs.parse(props.location.search).path,
      b_start: state.searchTag.b_start,
      b_size: state.searchTag.b_size,
      has_next: state.searchTag.has_next,
      locale: state?.content?.data?.language?.token ?? 'ja',
    };
  },
  (dispatch, props) => ({
    searchContent: (url, options) => {
      dispatch(searchTag(url, options));
    },
    resetContent: () => {
      dispatch(resetSearchTag());
    },
  }),
)(SearchTagView);

export { SearchTagViewContainer };

export default injectIntl(SearchTagViewContainer);
