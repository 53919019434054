import _ from 'lodash';
import {
  GET_ITEM_LIST_RELATEDTO_DEPARTMENT,
  GET_ITEM_LIST_RELATEDTO_DEPARTMENT_CLEAR,
} from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  result: {},
  contentList: [],
};

/**
 * Site Main Info Reducer
 * @param {*} state
 * @param {*} action
 */
export default function researchListRelatedtoDepartment(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case `${GET_ITEM_LIST_RELATEDTO_DEPARTMENT}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case GET_ITEM_LIST_RELATEDTO_DEPARTMENT_CLEAR:
      return {
        ...state,
        // error: null,
        b_start: 0,
        b_size: 0,
        contentList: [],
        // loaded: true,
        // loading: false,
      };
    case `${GET_ITEM_LIST_RELATEDTO_DEPARTMENT}_SUCCESS`:
      let contentList;
      if (action.bStart === 0) {
        contentList = action?.result?.data?.attributes?.data;
      } else {
        contentList = _.concat(
          state.contentList ?? [],
          action?.result?.data?.attributes?.data,
        );
      }
      return {
        ...state,
        error: null,
        result: action?.result,
        contentList: contentList,
        loaded: true,
        loading: false,
      };
    case `${GET_ITEM_LIST_RELATEDTO_DEPARTMENT}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}
