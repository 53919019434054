import React from 'react';
import _ from 'lodash';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { blocks } from '~/config';
import { Icon as IconComponent } from '@plone/volto/components';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
} from '@plone/volto/helpers';
import moment from 'moment';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { Helmet } from '@plone/volto/helpers';
import { connect, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { MediaQueryConfig } from '../../../helpers/GlobalStyle/GlobalStyle';
import { flattenToAppURL } from '@plone/volto/helpers';
import xSVG from '~/icons/circle_x.svg';
import './Common.css';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

/**
 * Research folder header component class.
 * @function ResearchFolderHeader
 * @param {Object} title String.
 * @returns {string} Markup of the component.
 */

const DefaultBody = injectIntl(({ content, intl }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  return (
    <div>
      {map(content[blocksLayoutFieldname].items, (block) => {
        const blockType =
          blocks.blocksConfig[content[blocksFieldname]?.[block]?.['@type']];
        const isTitle = blockType?.['id'] === 'title';
        if (isTitle) {
          return null;
        }
        const Block = blockType?.['view'] || null;
        return Block !== null ? (
          <Block
            key={block}
            id={block}
            properties={content}
            data={content[blocksFieldname][block]}
          />
        ) : (
          <div key={block}>
            {intl.formatMessage(messages.unknownBlock, {
              block: content[blocksFieldname]?.[block]?.['@type'],
            })}
          </div>
        );
      })}
    </div>
  );
});

const FolderTitle = ({ title, title_sp1, title_sp2 }) => {
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  return (
    <h2 className="title">
      {isSP ? (
        title_sp1 && title_sp2 ? (
          <>
            {title_sp1}
            <br />
            {title_sp2}
          </>
        ) : (
          title && title
        )
      ) : (
        title && title
      )}
    </h2>
  );
};

const FolderLeadSentence = ({
  lead_sentence,
  lead_sentence_sp1,
  lead_sentence_sp2,
}) => {
  const isSP = useMediaQuery({
    query: MediaQueryConfig.SP_AND_TABLET,
  });
  return (
    <h3 className="subtitle">
      {isSP ? (
        lead_sentence_sp1 && lead_sentence_sp2 ? (
          <>
            {lead_sentence_sp1}
            <br />
            {lead_sentence_sp2}
          </>
        ) : (
          lead_sentence && lead_sentence
        )
      ) : (
        lead_sentence && lead_sentence
      )}
    </h3>
  );
};

const HelmetTitle = ({ pageTitle }) => {
  const [data, layout, locale, opening_img_pc, siteTitle] = useSelector(
    (state) => {
      const locale = state?.content?.data?.language?.token ?? 'ja';
      const siteMainInfoAttr = state?.siteMainInfo?.result?.data?.attributes;
      return [
        state?.content?.data,
        state?.content?.data?.layout,
        locale,
        siteMainInfoAttr?.locale[locale]?.header?.opening_img_pc,
        siteMainInfoAttr?.locale[locale]?.assets?.site_title,
      ];
    },
  );
  const RESOU_URL = 'https://resou.osaka-u.ac.jp';
  const OG_IMAGE_TYPES = [
    'ResearchView',
    'StoryView',
    'FeatureView',
    'FeatureFolderView',
  ];
  const title = `${pageTitle} - ${siteTitle}`;
  const desc = data?.meta_description ?? data?.description;
  const ogUrl = `${RESOU_URL}${flattenToAppURL(_.get(data, '@id'))}`;
  const ogLocale = locale === 'en' ? 'en_US' : 'ja_JP';
  function og_image() {
    if (OG_IMAGE_TYPES.includes(layout)) {
      return `${RESOU_URL}${flattenToAppURL(
        data?.title_image_sp?.download ||
          '/assets/images/nowprinting_580_280.jpg',
      )}`;
    } else {
      return `${RESOU_URL}${flattenToAppURL(opening_img_pc?.src)}`;
    }
  }
  const ogType = layout === 'LanguageRootFolderView' ? 'website' : 'article';

  return (
    <Helmet
      title={title}
      meta={[
        { name: 'description', content: desc },
        { name: 'google', content: 'notranslate' },
        { property: 'og:title', content: pageTitle },
        { property: 'og:description', content: desc },
        { property: 'og:url', content: ogUrl },
        { property: 'og:locale', content: ogLocale },
        { property: 'og:image', content: og_image() },
        { property: 'og:type', content: ogType },
        { property: 'og:site_name', content: siteTitle },
      ]}
    />
  );
};

const LabelFeatureComponent = ({ locale }) => {
  return (
    <Link to={`/${locale}/feature`}>
      <div className="LabelFeature">
        <FormattedMessage id="Label Feature" defaultMessage="feature" />
      </div>
    </Link>
  );
};

export const HorizontalRule = (props) => {
  if (props.className) {
    return <hr className={`HorizontalRule ${props.className}`} />;
  } else {
    return <hr className="HorizontalRule" />;
  }
};

const SNSShare = ({ content }) => {
  return (
    <div className="SNSShare">
      <FacebookShareButton
        url={'https://resou.osaka-u.ac.jp' + flattenToAppURL(content['@id'])}
      >
        <FacebookIcon size="45" round />
      </FacebookShareButton>
      <TwitterShareButton
        url={'https://resou.osaka-u.ac.jp' + flattenToAppURL(content['@id'])}
      >
        {/* <TwitterIcon size="45" round /> */}
        <IconComponent name={xSVG} size={45} />
      </TwitterShareButton>
    </div>
  );
};

const en_options = { year: 'numeric', month: 'short', day: 'numeric' };

const zeroSuppledDate = (locale, dt) => {
  if (dt != null) {
    if (locale === 'ja') {
      return moment(dt).format('YYYY-M-D');
    } else {
      var event = new Date(dt);
      return event.toLocaleDateString('en-US', en_options);
    }
  }
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */

/*ResouFolderHeader.propTypes = {
  title: PropTypes.string.isRequired,
};*/

const LabelFeature = connect(
  (state, props) => {
    return {
      locale: state?.content?.data?.language?.token ?? 'ja',
    };
  },
  (dispatch, props) => {
    return {};
  },
)(LabelFeatureComponent);

//export default injectIntl(HelmetTitleContainer, {});

export {
  DefaultBody,
  FolderTitle,
  FolderLeadSentence,
  HelmetTitle,
  LabelFeature,
  SNSShare,
  zeroSuppledDate,
};
