/**
 * @module components/theme/Forbidden/Forbidden
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BodyClass } from '@plone/volto/helpers';
import { HorizontalRule } from '../../../../components/theme/View/Common';

/**
 * forbidden function.
 * @function Forbidden
 * @returns {string} Markup of the forbidden page.
 */
const Forbidden = () => (
  <div className="view-wrapper">
    <BodyClass className="section-error" />
    <div className="error-header">
      <HorizontalRule className="hr" />
      <h2>
        アクセス不可
        {/* <FormattedMessage id="Forbidden" defaultMessage="Forbidden" /> */}
      </h2>
      <h2>Forbidden</h2>
    </div>

    <div className="message">
      <p className="description">
        申し訳ありません。このリソースにはアクセスできません。
        {/* <FormattedMessage
          id="We apologize for the inconvenience, but you don't have permissions on this resource."
          defaultMessage="We apologize for the inconvenience, but you don't have permissions on this resource."
        /> */}
      </p>
      <p>%nbsp;</p>
      <p className="description">
        We apologize for the inconvenience, but you don't have permissions on
        this resource.
      </p>
    </div>
  </div>
);

export default Forbidden;
