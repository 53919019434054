/**
 * Inquiry button about articles
 * @nk24 2022/01/18
 * @module components/theme/Element/InquiryArticleButton/InquiryArticleButton
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { StyleConfig } from '@package/helpers/GlobalStyle/GlobalStyle';
import { Icon } from '@package/components';
import './ArticleInquiryButton.css';

const messages = defineMessages({
  inquiriesAboutThisResearch: {
    id: 'Inquiries about this research',
    defaultMessage: 'Inquiries about this research',
  },
});

const ArticleInquiryButton = ({
  intl,
  linkPath,
  url,
  researcher,
  affiliation,
}) => {
  const locale = intl.locale === 'ja' ? 'ja' : 'en';
  const linkUrl = flattenToAppURL(`/${locale}${linkPath}`);
  const contentUrl = flattenToAppURL(url);
  const params = new URLSearchParams();
  params.append('contentUrl', contentUrl);
  params.append('researcher', researcher);
  params.append('affiliation', affiliation);
  const inquiryPath = `${linkUrl}?${params}`;
  return linkPath ? (
    <div className="ArticleInquiryButton">
      <Link to={inquiryPath}>
        <button type="button">
          <Icon
            icon="mail outline"
            size={StyleConfig.menuIconSizeSmall}
            color={StyleConfig.iconColorDark}
          ></Icon>
          {intl.formatMessage(messages.inquiriesAboutThisResearch)}
        </button>
      </Link>
    </div>
  ) : null;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ArticleInquiryButton.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string,
  }),
  linkPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default injectIntl(
  connect((state) => {
    return {
      linkPath:
        state.siteMainInfo?.result?.data?.attributes?.common
          ?.article_inquiry_form_path,
    };
  })(ArticleInquiryButton),
);
