/**
 * Slider component showing Storyz articles
 * 最新のStoryzを一覧表示するスライダー
 * @module components/theme/Site/Slider/StoryzList
 */

import React, { Component } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose';
import _ from 'lodash';
import { defineMessages, injectIntl } from 'react-intl';
import {
  MediaQueryConfig,
  StyleConfig,
} from '../../../../helpers/GlobalStyle/GlobalStyle';
import { connect } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import { storyzList } from '../../../../actions/storyzList/storyzList';
import { ExternalLink, Icon } from '../../../index';
import { Link } from 'react-router-dom';
import './StoryzList.css';

/**
 * スライダー : 次矢印
 */
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className="arrowWrapperNext">
      <button className="arrowButton" onClick={onClick}>
        <Icon icon="angle right" size={StyleConfig.carouselIconSize} />
      </button>
    </div>
  );
};

/**
 * スライダー : 前矢印
 * @param {*} props
 */
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className="arrowWrapperPrev">
      <button className="arrowButton" onClick={onClick}>
        <Icon icon="angle left" size={StyleConfig.carouselIconSize} />
      </button>
    </div>
  );
};

/**
 * 短い区切り
 * @param {*} props
 */
const Divider = (props) => {
  return (
    <div className="divider">
      <hr />
    </div>
  );
};

/**
 * セクション区切り
 * @param {*} props
 */
const SliderDivider = (props) => {
  return (
    <div className="sliderDivider">
      <hr />
    </div>
  );
};

/**
 * SliderLead Component
 * @function SliderLead セクションのリード文言
 * @param {objects} props リード文言
 */
const SliderLead = (props) => {
  const isPC = useMediaQuery({
    query: MediaQueryConfig.PC,
  });
  return isPC ? (
    <p className="sliderDescription">{props.leadPc}</p>
  ) : (
    <p className="sliderDescription">
      {props.leadSp1}
      <br />
      {props.leadSp2}
    </p>
  );
};

/**
 * スライダーアイテム
 * @param {}} props
 */
const CarouselItem = (props) => {
  const redirect = props.item.redirect;
  const url = props.item.url;
  return (
    <Link
      className="carouselItemWrapper"
      to={{ pathname: flattenToAppURL(redirect ? redirect : url) }}
      target={redirect ? '_blank' : ''}
    >
      <img
        className="img"
        alt=""
        src={flattenToAppURL(props.item?.image ?? '')}
      />
      <h3 className="title">
        <LinesEllipsisLoose
          text={props.item.title}
          maxLine="3"
          ellipsis="..."
          lineHeight="24"
        />
      </h3>
      <LinesEllipsisLoose
        text={props.item.position ? props.item.position : ''}
        maxLine="2"
        ellipsis="..."
        lineHeight="20"
        className="position"
      />
      <Divider />
      <LinesEllipsisLoose
        text={props.item.description}
        maxLine="3"
        ellipsis="..."
        lineHeight="20"
        className="customLinesEllipsisLoose"
      />
      <ExternalLink link={url} redirect={redirect} />
    </Link>
  );
};

/**
 * 標準のスライダー
 */
class DefaultSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: '',
    };
  }

  static propTypes = {
    /** 表示用データ
     * TODO: あとで正確な型を決めてもいい
     */
    title: PropTypes.string,
    description: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    token: PropTypes.string,
  };

  static defaultProps = {
    title: '',
    description: '',
    data: [],
    token: null,
  };

  componentDidMount() {
    this.props.loadContent(this.props.locale);
  }

  componentDidUpdate() {
    if (this.props.locale !== this.state.locale) {
      this.setState({ locale: this.props.locale });
      this.props.loadContent(this.props.locale);
    }
  }

  render() {
    const settings = {
      accessibility: true,
      dots: false,
      infinite: true,
      // infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      // centerMode: true,
      centerMode: false,
      arrow: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      className: this.props.token
        ? 'customSlider customSliderLogined'
        : 'customSlider',
    };
    // (infinite: trueのとき用指定) サイズが1のときにおかしな挙動になるため
    // const data =
    //   _.size(this.props.data) === 1
    //     ? [this.props.data[0], this.props.data[0]]
    //     : this.props.data;
    const data = this.props.data;
    return (
      <>
        {data.length ? (
          <div className="StoryZListSlider DefaultSlider">
            <SliderDivider></SliderDivider>
            <h2 className="sliderTitle">{this.props.title}</h2>
            <SliderLead
              leadPc={this.props.leadPc}
              leadSp1={this.props.leadSp1}
              leadSp2={this.props.leadSp2}
            ></SliderLead>
            <Slider {...settings}>
              {_.map(data, (item) => {
                return <CarouselItem item={item} key={item.title} />;
              })}
            </Slider>
          </div>
        ) : null}
      </>
    );
  }
}

/**
 * StoryZの一覧を出力するコンポーネント
 */
const StoryzListContainer = connect(
  (state, props) => ({
    title: state.storyzList?.result?.data?.attributes?.title,
    description: state.storyzList?.result?.data?.attributes?.description,
    leadPc: state.storyzList?.result?.data?.attributes?.lead_sentence,
    leadSp1: state.storyzList?.result?.data?.attributes?.lead_sentence_sp1,
    leadSp2: state.storyzList?.result?.data?.attributes?.lead_sentence_sp2,
    data: state.storyzList?.result?.data?.attributes?.data,
    token: state.userSession?.token,
    locale: state?.content?.data?.language?.token ?? 'ja',
  }),
  (dispatch, props) => ({
    loadContent: (locale) => {
      dispatch(storyzList(locale));
    },
  }),
)(DefaultSlider);

export default injectIntl(StoryzListContainer, {});
