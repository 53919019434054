/**
 * Edit header3 block.
 * @module components/manage/Blocks/Header3/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { doesNodeContainClick } from 'semantic-ui-react/dist/commonjs/lib';
import { defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';
import { Editor, DefaultDraftBlockRenderMap, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { isEqual } from 'lodash';

const getId = () => Math.floor(Math.random() * Math.pow(2, 24)).toString(32);

/**
 * Placefolderの文言
 */
const messages = defineMessages({
  header3: {
    id: 'Type the header text…',
    defaultMessage: 'Type the header text…',
  },
});

/**
 * Edit画面のstyle
 */
const blockRenderMap = Map({
  unstyled: {
    element: 'h3',
  },
});

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

/**
 * Edit header3 block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    detached: PropTypes.bool,
    index: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    onAddBlock: PropTypes.func.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onMutateBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    detached: false,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   */
  constructor(props) {
    super(props);
    if (!__SERVER__) {
      let editorState;
      if (props.data && props.data.header3) {
        const contentState = stateFromHTML(props.data.header3);
        editorState = EditorState.createWithContent(contentState);
      } else {
        editorState = EditorState.createEmpty();
      }
      this.state = {
        editorState,
        addNewBlockOpened: false,
      };
    }
    this.onChange = this.onChange.bind(this);
  }

  /**
   * Component will receive props
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.data.key) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        key: getId(),
      });
    }
    if (this.props.selected) {
      this.node.focus();
    }
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.selected && nextProps.selected) {
      this.node.focus();
      this.setState({
        editorState: EditorState.moveFocusToEnd(this.state.editorState),
      });
    }
  }

  /**
   * Component will receive props
   * @method componentWillUnmount
   * @returns {undefined}
   */
  componentWillUnmount() {
    if (this.props.selected) {
      this.node.focus();
    }
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  /**
   * Event handler method.
   * @method onChange
   * @param {Object} editorState Editor state.
   * @returns {undefined}
   */
  onChange(editorState) {
    if (
      !isEqual(
        editorState.getCurrentContent().getPlainText(),
        this.state.editorState.getCurrentContent().getPlainText(),
      )
    ) {
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        header3: editorState.getCurrentContent().getPlainText(),
      });
    }
    this.setState({ editorState });
  }

  toggleAddNewBlock = () =>
    this.setState((state) => ({ addNewBlockOpened: !state.addNewBlockOpened }));

  /**
   * Event handler method.
   * @method handleClickOutside
   * @param {Object} e event object.
   * @returns {undefined}
   */
  handleClickOutside = (e) => {
    if (
      this.props.blockNode.current &&
      doesNodeContainClick(this.props.blockNode.current, e)
    )
      return;
    this.setState(() => ({
      addNewBlockOpened: false,
    }));
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div></div>;
    }
    return (
      <div className={cx('block header3', { selected: this.props.selected })}>
        <Editor
          onChange={this.onChange}
          editorState={this.state.editorState}
          blockRenderMap={extendedBlockRenderMap}
          handleReturn={() => {
            this.props.onSelectBlock(
              this.props.onAddBlock('text', this.props.index + 1),
            );
            return 'handled';
          }}
          handleKeyCommand={(command, editorState) => {
            if (
              command === 'backspace' &&
              editorState.getCurrentContent().getPlainText().length === 0
            ) {
              this.props.onDeleteBlock(this.props.block, true);
            }
          }}
          placeholder={this.props.intl.formatMessage(messages.header3)}
          blockStyleFn={() => 'documentHeader3'}
          onUpArrow={() => {
            const selectionState = this.state.editorState.getSelection();
            const { editorState } = this.state;
            if (
              editorState.getCurrentContent().getBlockMap().first().getKey() ===
              selectionState.getFocusKey()
            ) {
              this.props.onFocusPreviousBlock(this.props.block, this.node);
            }
          }}
          onDownArrow={() => {
            const selectionState = this.state.editorState.getSelection();
            const { editorState } = this.state;
            if (
              editorState.getCurrentContent().getBlockMap().last().getKey() ===
              selectionState.getFocusKey()
            ) {
              this.props.onFocusNextBlock(this.props.block, this.node);
            }
          }}
          ref={(node) => {
            this.node = node;
          }}
        />
      </div>
    );
  }
}

export default injectIntl(Edit);
