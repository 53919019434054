/**
 * Add your config changes here.
 * @module config
 * @example
 * export const settings = {
 *   ...defaultSettings,
 *   port: 4300,
 *   listBlockTypes: {
 *     ...defaultSettings.listBlockTypes,
 *     'my-list-item',
 *   }
 * }
 */

import { defineMessages } from 'react-intl';
import {
  settings as defaultSettings,
  views as defaultViews,
  widgets as defaultWidgets,
  blocks as defaultBlocks,
} from '@plone/volto/config';
import React from 'react';
import {
  customBlocks,
  updateDefaultBlocksConfig,
  LanguageRootFolderView,
  MailFormView,
  ResearchFolderView,
  ResearchView,
  StoryFolderView,
  StoryView,
  FeatureMainFolderView,
  FeatureFolderView,
  FeatureView,
  TagListView,
  FolderBlockView,
  PageView,
  DateWidget,
  ListWidget,
  TermIndexWidget,
  MultilingualSelectWidget,
  MultilingualArrayWidget,
} from './components';
import { WysiwygWidget } from '@plone/volto/components';
import createInlineStyleButton from 'draft-js-buttons/lib/utils/createInlineStyleButton';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import superindexSVG from '@plone/volto/icons/superindex.svg';
import subindexSVG from '@plone/volto/icons/subindex.svg';

import { nonContentRoutes } from './config/NonContentRoutes';

defineMessages({
  mailform: {
    id: 'mailform',
    defaultMessage: 'Mail Form',
  },
});

const SuperIndexButton = createInlineStyleButton({
  style: 'SUPERSCRIPT',
  children: <Icon name={superindexSVG} size="24px" />,
});

const SubIndexButton = createInlineStyleButton({
  style: 'SUBSCRIPT',
  children: <Icon name={subindexSVG} size="24px" />,
});

export const settings = {
  ...defaultSettings,
  isMultilingual: true,
  supportedLanguages: ['ja', 'en'],
  defaultLanguage: 'ja',
  customStyleMap: {
    ...defaultSettings.customStyleMap,
    SUBSCRIPT: { fontSize: '0.6em', verticalAlign: 'sub' },
    SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' },
  },
  ToHTMLRenderers: {
    ...defaultSettings.ToHTMLRenderers,
    inline: {
      ...defaultSettings.ToHTMLRenderers.inline,
      SUBSCRIPT: (children, { key }) => <sub key={key}>{children}</sub>,
      SUPERSCRIPT: (children, { key }) => <sup key={key}>{children}</sup>,
    },
  },
  richTextEditorInlineToolbarButtons: [
    SuperIndexButton,
    SubIndexButton,
    ...defaultSettings.richTextEditorInlineToolbarButtons,
  ],
  nonContentRoutes,
};

export const views = {
  ...defaultViews,
  layoutViews: {
    ...defaultViews.layoutViews,
    // folder_listing: LanguageRootFolderView,
    LanguageRootFolderView: LanguageRootFolderView,
    ResearchFolderView: ResearchFolderView,
    ResearchView: ResearchView,
    StoryFolderView: StoryFolderView,
    StoryView: StoryView,
    FeatureMainFolderView: FeatureMainFolderView,
    FeatureFolderView: FeatureFolderView,
    FeatureView: FeatureView,
    MailFormView: MailFormView,
    TagListView: TagListView,
    FolderBlockView: FolderBlockView,
    PageView: PageView,
  },
};

export const widgets = {
  ...defaultWidgets,
  id: {
    ...defaultWidgets.id,
    related_teachers: ListWidget,
    inquiry_mailto: ListWidget,
    term_index: TermIndexWidget,
    research_highlight: MultilingualSelectWidget,
    departments: MultilingualArrayWidget,
    // term_index: WysiwygWidget,
  },
  widget: {
    ...defaultWidgets.widget,
    date: DateWidget,
  },
  type: {
    ...defaultWidgets.type,
    date: DateWidget,
  },
};

const customRequiredBlocks = ['title', 'description'];

export const blocks = {
  ...defaultBlocks,
  blocksConfig: {
    ...defaultBlocks.blocksConfig,
    ...updateDefaultBlocksConfig,
    ...customBlocks,
  },
  requiredBlocks: customRequiredBlocks,
  groupBlocksOrder: [
    ...defaultBlocks.groupBlocksOrder,
    { id: 'mailform', title: 'Mail form' },
  ],
};

/**
 * アプリ独自の設定
 */
export const appSettings = {
  maxFileUpload: 2097152,
};

// ------------------------------- version 6 追加分

export const addonRoutes = [];
export const addonReducers = {};
