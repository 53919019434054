import { CHANGE_CUSTOM_DIMMER } from '../../constants/ActionTypes';

/**
 * CustomDimmer の表示非表示アクション
 * @param {boolean} visible
 */
export function changeCustomDimmer(visible) {
  return {
    type: CHANGE_CUSTOM_DIMMER,
    visible: visible,
  };
}
