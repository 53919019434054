/**
 * SiteImageに関する設定
 */
export const SiteImageSetting = {
  // アスペクト比 1921 x 550 = 1: 0.286
  ASPECT_FOR_HEIGHT: 0.286,
};

/**
 * Cookieに関する設定
 */
export const CookieSetting = {
  SITE_IMAGE_VISIBLE: {
    name: 'Resou.SiteImage.visible',
    option: {
      path: '/',
      expires: new Date('2031/04/01'),
    },
    on: '1',
    off: '0',
  },
  VIEW_HISTORIES: {
    name: 'Resou.View.Histories',
    option: {
      path: '/',
      expires: new Date('2031/04/01'),
    },
  },
};

/**
 * Localeのデフォルト値
 */
export const LocaleSetting = {
  DEFAULT_LANG: 'ja',
};

/**
 * MicrosoftClarity設定
 */
export const MicrosoftClaritySetting = {
  MICROSOFT_CLARITY_ID: '9tkdcw8qso'
}