import { GET_PAST_FEATURE_LIST } from '../../constants/ActionTypes';

export function pastFeatureList(apiPath, bStart = 0) {
  const safeApiPath = apiPath || 'ja';
  return {
    type: GET_PAST_FEATURE_LIST,
    request: {
      op: 'get',
      path: `/${safeApiPath}/feature/@feature-folder-list-on-item?b_start=${bStart}`,
    },
    bStart: bStart,
  };
}
